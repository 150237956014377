@use "sass:map";

#footer {
  @import "footer/subscription";
  @import "footer/advantages";
  @import "footer/logo";
  @import "footer/social";
  @import "footer/menu";
  @import "footer/copyright";

  border-top: 1px solid $gray-200;
  background: none;

  //desktop
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 0;
  }

  .footer-bottom {
    background: $gray-200;
    margin-top: 30px;

    .footer-menu-wrapper {
      padding: 45px 0;
    }
  }
}