@use "sass:map";

@keyframes spin {
  to { transform: rotate(1turn); }
}

.progress-spinner {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  overflow: hidden;
  animation: spin 1s infinite steps(8);

  button &, .content_layout_checkout .checkout-columns .content-column .actions-wrp .btn & {
    width: 16px;
    height: 16px;

    &:before,
    &:after,
    & > div:before,
    & > div:after {
      left: 7px; /* (container width - part width)/2  */
      width: 2px;
      height: 3px;
      box-shadow: 0 13px #eee; /* container height - part height */
      transform-origin: 50% 8px; /* container height / 2 */
    }
  }
}

.progress-spinner:before,
.progress-spinner:after,
.progress-spinner > div:before,
.progress-spinner > div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 21px; /* (container width - part width)/2  */
  width: 4px;
  height: 13px;
  border-radius: .2em;
  background: #eee;
  box-shadow: 0 33px #eee; /* container height - part height */
  transform-origin: 50% 23px; /* container height / 2 */
}

.progress-spinner:before {
  background: map.get($airguru-colors,'dark-gray');
}

.progress-spinner:after {
  transform: rotate(-45deg);
  background: map.get($airguru-colors,'gray');
}

.progress-spinner > div:before {
  transform: rotate(-90deg);
  background: map.get($airguru-colors,'light-gray');
}

.progress-spinner > div:after {
  transform: rotate(-135deg);
  background: map.get($airguru-colors,'lightest-gray');
}