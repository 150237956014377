@use "sass:map";

.fixed-overlay {
  z-index: 301;
  display: none;
  background-color: black;
  opacity: 0.5;
}

.main-nav-active {
  .fixed-overlay {
    display: block;
  }
}

nav {
  display: none;
}
#desktop_mega_menu, #pages_mega_menu {
  .submenu_list {
    .lbl {
      display: inline-block;
      margin-top: 4px;
      margin-right: 6px;
      padding: 1px 5px;
      //background: red;
      font-size: 12px;
      line-height: 14px;
      border-radius: 4px;

      &.lbl-top {
        color: #ffffff;
        background-color: map.get($airguru-colors, 'orange');
      }
    }
    .menu-l4-wrp {
      .menu-l4-title-wrp {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        //justify-content: space-between;

        > a {
          //@extend .py-1;
          padding: 4px 0px !important;
          background: transparent;
          @extend .color-black;

          &:hover {
            color: map.get($theme-colors, 'primary');
          }
        }
        .toggle-menu-l4 {
          &:before {
            content: "+";
            padding: 0px 8px;
            @extend .f-16;
            line-height: 27px !important;
          }
        }
      }
      .menu-l4 {
        display: none;

        ul {
          background: transparent;

          .menu_subitem {
            a {
              display: inline-block;
            }
          }
        }
      }
      &.open {
        .menu-l4-title-wrp {
          .toggle-menu-l4 {
            &:before {
              content: "-";
            }
          }
        }
        .menu-l4 {
          display: block;
        }
      }
    }
  }
}
nav, nav.nav-mobile {
  z-index: 501;
  width: 30vw;

  .mobile-menu-header {
    height: 56px;
  }

  #pages_mega_menu {
    #mega_menu_list {
      list-style: none;
      overflow-y: auto;
      margin-left: 0px;

       a:hover {
         text-decoration: none;
       }
      > li {
        > .title {
          padding: 14px 33px !important;
          border: none;
          display: flex;
          align-items: center;
          margin: 0 !important;
          line-height: 20px !important;
          text-transform: initial;

          > a {
            @extend .color-black;
          }
          &::after {
            display: none;
          }
        }
        > .list-dropdown {
          > .title {
            display: flex;
            align-items: center;
            padding: 14px 33px !important;
            width: 100%;
            border: none;

            a {
              @extend .color-black;
            }
            &::after {
              display: none;
            }
          }
          &.open {
            > .title {
              background-color: rgba(5, 139, 216, 0.1);

              > span, > a {
                color: map.get($theme-colors, 'primary') !important;
              }
              .expand-btn svg {
                fill: map.get($theme-colors, 'primary');
                transform: rotateZ(180deg);

                path {
                  fill: map.get($theme-colors, 'primary') !important;
                }
              }
            }
          }
        }
        &.mega_element {
          > .list-dropdown {}
        }

        > .title  > a > img, > .title > img {
          @include media-breakpoint-down(lg) {
            max-width: 20px;
          }
        }
      }

      .submenu_mega, .submenu_list {
        padding: 14px 33px;

        .submenu_groups {
          .menu-l2 {
            .title-l2-wrp {
              .title {

              }
            }
          }
        }
      }

    }

    .mobile-menu-footer {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      padding: 30px 20px 21px 32px;
      column-gap: 16px;
      background-color: rgba(5, 139, 216, 0.1);
    }
  }

  @include media-breakpoint-down(lg) {
    width: 82.6vw;
  }
}


#desktop_mega_menu {
  position: relative;
  list-style: none;

  > li {

    @include media-breakpoint-up(xl) {
      white-space: nowrap;
    }

    .title {
      @extend .p-0;
      @extend .m-0;

      &:after {
        @extend .d-none;
      }
    }

    > .title {
      @extend .h-100;

      > a {
        @extend .h-100;
        @extend .color-black;
        @extend .text-uppercase;
        @extend .f-16;
        @extend .fw-500;
        padding: 0px 15px;
        align-items: center;
        transition: all .3s;

        img {
          max-width: 18px;
        }
        .expand-btn {
          @extend .d-none;
        }
        &:hover {
          @extend .color-blue;
          text-decoration: none;
        }
      }
    }

    .list-dropdown {
      @extend .h-100;
      //position: initial !important;
      max-width: 100%;

      > .title {
        @extend .h-100;
        padding: 0px 15px !important;
        transition: all .3s;

        a {
          @extend .h-100;
          @extend .color-black;
          @extend .text-uppercase;
          @extend .f-16;
          @extend .fw-500;
          padding: 0px 0px;
          align-items: center;
          transition: all .3s;

          .expand-btn-wrp {
            @extend .d-none;
          }
          &:hover {
            @extend .color-blue;
            text-decoration: none;
          }
        }
        > span {
          @extend .h-100;
          align-items: center;

          span.flex-grow-0 {
            display: none;
          }
        }
      }
      &.hover {
        > .title {
          color: map.get($theme-colors, 'primary');
          background: #ffffff;
          box-shadow: 0px -12px 12px #0D275028;
          z-index: 1;
        }
      }
    }
    .submenu_list {
      //@extend .w-100;
      overflow: auto;
      left: initial;
      min-width: 100%;
      padding: 32px;
      background: #fff;
      box-shadow: 0px -4px 16px #0d275028;
      border-radius: 0px 0px 8px 8px;
      transition: all .3s;

      .submenu_groups {
        display: grid;
        grid-auto-flow: column;
        column-gap: 40px;

        > div {
          max-width: 195px;
        }
        .menu-l2 {
          margin-bottom: 30px !important;

          a {
            @extend .color-black;

            &:hover {
              text-decoration: none;
              color: map.get($theme-colors, 'primary') !important;
            }
          }
          .title-l2-wrp {
            display: block;
            margin-bottom: 15px;

            .title {}
          }
          .menu-l3 {
            > ul {
              margin: 0px;

              li {
                padding: 0px !important;

                > a {
                  display: inline-block;
                  padding: 2px 0px !important;
                }
              }
            }
          }
        }
        ul {
          @extend .border-0;
          @extend .rounded-0;
          @extend .w-100;

          li {
            @extend .py-2;
            @extend .pl-3;
            @extend .pr-2;

            white-space: normal;

            a {
              @extend .p-0;

              &:hover {
                background: none !important;
              }

              span {
                @extend .f-12;
                font-weight: normal !important;
              }
            }
          }
        }
        &.columns-count-1 { grid-template-columns: repeat(1, minmax(0, 1fr)); }
        &.columns-count-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
        &.columns-count-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
        &.columns-count-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
      }
      > ul {
        margin: 0px;
        width: 100%;
        border: none;

        > li {
          > a {
            color: map.get($airguru-colors, 'dark');

            &:hover {
              color: map.get($airguru-colors, 'blue');
              background-color: transparent;
              text-decoration: none;
            }
          }
        }
      }
    }
    &.mega_element {
      .submenu_list {
        border-radius: 0px 8px 8px 8px;
      }
    }
  }
}

#pages_footer_menu {
  ul {
    margin-left: 0px;
  }
}