
#footer_social {
  padding: 1em 0;
  text-align: left;

  .social-icons {
    display: inline-block;

    a {
      color: $black;
    }
  }
}