@use "sass:map";

.fake-selector {
  .selector-front {
    z-index: 10;
    .selector-tag {
      position: absolute;
      left: 15px;
      top: -11.5px;
    }
  }
  .selector-dropdown {
    z-index: 9;
  }
}