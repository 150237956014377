@use "sass:map";

.heroBannerPlaceholder {
  overflow: hidden;
  height: 600px;
  background: url('../../images/frontend/desktop-background-min.jpg') no-repeat center center;
  background-size: cover;
  border-radius: 8px;

  .blue-filter {
    background-color: rgba(5, 139, 216, 0.7);
  }
  h2 {
    position: relative;
    margin-top: 0px;
    padding-top: 30px;
    text-transform: initial;
  }
  @include media-breakpoint-up(lg) {
    height: 511px;

    h2 {
      padding-top: 140px;
    }
  }
}
#banners_hero {
  overflow: hidden;
  border-radius: 8px;
  background: transparent;

  .hero_slider {
    max-width: 100%;

    .item {
      .banner_img {
        height: 600px;

        .banner_picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .title_wrp {
        @include full_absolute_block();
        height: calc(600px - 177px);

        .container-fluid {
          padding-left: 16px;
          padding-right: 16px;
        }
        .content-wrp {
          display: grid;
          height: 100%;

          .banner_content {
            margin-top: 0px;
            padding: 20px 0px;
            width: 100%;

            .title {
              display: block;
              margin: 0px;
              font-size: 36px;
              font-weight: 600;
              line-height: 125%;
              color: #343a40;

              & + .description {
                margin-top: 16px;
              }
              & + .btn {
                margin-top: 24px;
              }
            }
            .description {
              margin-bottom: 0px;
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              color: #343a40;
            }
            .btn {
              margin-top: 24px;
              padding: 12px 16px;
              width: 100%;
            }
            &.align-self-top {
              align-self: start;
            }
            &.align-self-middle {
              align-self: center;
            }
            &.align-self-bottom {
              align-self: end;
            }
            &.color-theme-light {
              .title, .description {
                color: #ffffff;
              }
            }
          }
          .banner-countdown {
            justify-self: center;
            display: flex;
            flex-direction: row;
            z-index: 2;

            .expired {
              font-weight: 600;
              font-size: 30px;
              color: #fff;
              text-transform: uppercase;
            }
            > div {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 48px;
              width: 48px;
              color: #434A54;
              background-color: #ffffff;
              border-radius: 8px;

              .number {
                font-weight: bold;
                font-size: 22px;
                line-height: 27px;
              }
              .text {
                font-size: 11px;
                line-height: 14px;
              }
              &:not(:last-child) {
                margin-right: 16px;

                &:after {
                  content: ":";
                  position: absolute;
                  right: -12px;
                  top: 7px;
                  font-weight: bold;
                  font-size: 23px;
                  line-height: 31px;
                  color: #ffffff;
                }
              }
            }
          }
          &.wt-clock {
            grid-template-rows: minmax(0, 1fr) auto;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .owl-nav {
      top: 12px;
      right: 12px;
      left: initial;
      display: grid;
      grid-auto-flow: column;
      column-gap: 12px;
      width: initial;

      button {
        width: 40px;
        height: 40px;
        color: #ffffff;
        border-radius: 8px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-color: rgba(255, 255, 255, 0.20);
        transition: all .3s;

        span {
          display: none;
        }
        &.owl-prev {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_4923_8504" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4923_8504)"><path d="M12.7484 17.4471L5.81573 10.5273C5.73773 10.4493 5.68271 10.367 5.65067 10.2805C5.61861 10.1939 5.60258 10.1005 5.60258 10C5.60258 9.8996 5.61861 9.80612 5.65067 9.71959C5.68271 9.63305 5.73773 9.55079 5.81573 9.4728L12.7564 2.53209C12.9135 2.37504 13.1058 2.29651 13.3333 2.29651C13.5609 2.29651 13.7601 2.38198 13.9311 2.55293C14.0967 2.71852 14.1795 2.91349 14.1795 3.13784C14.1795 3.3622 14.0967 3.55718 13.9311 3.72278L7.65383 10L13.9391 16.2853C14.0961 16.4423 14.1747 16.6325 14.1747 16.8558C14.1747 17.0791 14.0892 17.2762 13.9183 17.4471C13.7527 17.6127 13.5577 17.6955 13.3333 17.6955C13.109 17.6955 12.914 17.6127 12.7484 17.4471Z" fill="white"/></g></svg>') !important;
        }
        &.owl-next {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.92648 16.4031C6.75327 16.2275 6.66667 16.0196 6.66667 15.7795C6.66667 15.5394 6.75327 15.3316 6.92648 15.1559L12.0015 10.0088L6.90916 4.84407C6.7475 4.68011 6.66667 4.47517 6.66667 4.22923C6.66667 3.98329 6.75327 3.77249 6.92648 3.59682C7.09969 3.42115 7.30466 3.33331 7.54138 3.33331C7.7781 3.33331 7.98306 3.42115 8.15627 3.59682L13.9761 9.51689C14.0454 9.58716 14.0945 9.66328 14.1234 9.74526C14.1522 9.82724 14.1667 9.91507 14.1667 10.0088C14.1667 10.1025 14.1522 10.1903 14.1234 10.2723C14.0945 10.3542 14.0454 10.4304 13.9761 10.5006L8.13895 16.4207C7.97729 16.5847 7.7781 16.6666 7.54138 16.6666C7.30466 16.6666 7.09969 16.5788 6.92648 16.4031Z" fill="white"/></svg>') !important;
        }
        &:hover, &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.60) !important;
        }
      }
      &.disabled {
        display: none;
      }
    }
    .owl-dots {
      bottom: 206px;

      .owl-dot {
        span {
          margin: 5px 2px;
          width: 8px;
          height: 8px;
          background-color: #ffffff;
          border-radius: 2px;
          opacity: 0.5;
          transition: all .3s;
        }
        &.active {
          span {
            width: 24px;
            opacity: 1;
          }
        }
        &:hover, &:focus {
          outline: none;

          span {
            opacity: 1;
          }
        }
      }
    }
    .carousel-inner-x:not(.owl-loaded) {
      display: block;

      .item {
        display: none;

        &.active {
          display: block;
        }
      }
    }
    &.has-bottom-content, &.has-clock {
      .item {
        .title_wrp {
          padding-bottom: 40px;
        }
      }
      .owl-dots {
        bottom: 176px;
      }
    }
    &:not(.owl-container) {
      .carousel-inner-x {
        width: 1000vw;

        .item {
          width: 100vw;
          float: left;
        }
      }
    }

    .carousel-inner-x {
      @extend .p-0;
    }
  }
  @include media-breakpoint-up(lg) {
    .hero_slider {
      .owl-nav {
        top: 48px;
        right: 44px;
      }
      .item {
        .banner_img {
          height: 511px;

          .banner_picture {}
        }
        .title_wrp {
          height: 415px;

          .content-wrp {
            .banner_content {
              .title {
                font-size: 42px;
                font-weight: 700;
                line-height: 125%;
              }
              .description {
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
              }
              .btn {
                width: initial;
              }
            }
            .banner-countdown {
              top: 27px;
              bottom: initial;
              left: initial;
              right: 13px;
              transform: translateX(0%);

              > div {
                height: 64px;
                width: 64px;

                .number {
                  font-size: 36px;
                  line-height: 39px;
                }
                .text {
                  font-size: 12px;
                  line-height: 16px;
                }
                &:not(:last-child) {
                  margin-right: 32px;

                  &:after {
                    right: -21px;
                    top: 8px;
                    font-size: 32px;
                    line-height: 39px;
                  }
                }
              }
            }
          }
        }
      }
      &.has-bottom-content, &.has-clock {
        .item {
          .title_wrp {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}