@use "sass:map";

#departments_listing {
  margin-bottom: 25px;

  .filters {
    ul {
      text-align: center;

      li {
        &:before {
          content: none;
        }

        font-size: 16px;
        color: $black;
        padding: 25px 20px 20px 0px;
        display: inline-block;
        position: relative;
      }
    }
  }

  .element {
    margin-top: 20px;

    span {
      display: block;
    }

    .name {
      font-size: 15px;
      color: theme-color();
      margin: 20px 0 5px;
    }

    .placeholder {
      width: 100%;
      display: block;
      @media (min-width: map.get($grid-breakpoints, "sm")) {
        height: 194px;
      }
    }
  }

  .elements_listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}