#type_travel_discounts {
  background-color: #F5F7FA;
}
#travel-discounts {
  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }

  .editor-type-text {
    font-size: 16px;
    line-height: 24px;
  }

  .page-title {
    margin-top: 40px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .travel-discounts-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 24px;

    .travel-discounts-item {
      display: flex;
      flex-direction: column;
      padding: 16px;
      background-color: #ffffff;
      border-radius: 8px;

      .photo-wrp {
        position: relative;
        margin-bottom: 24px;

        img {
          border-radius: 8px;
        }

        .destination-tag {
          position: absolute;
          top: 10px;
          left: 10px;
          display: flex;
          align-items: center;
          padding: 6px 12px;
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0.5px;
          background-color: #5ECC2E;
          border-radius: 8px;

          svg {
            margin-right: 5px;
          }
        }

        .timer {
          position: absolute;
          left: 10px;
          bottom: 10px;
          display: flex;
          align-items: center;
          padding: 5px 8px;
          font-size: 14px;
          line-height: 150%;
          color: #ffffff;
          border-radius: 8px;

          svg {
            margin-right: 4px;
          }

          .time-wrp {
            margin-left: 4px;

            .days {
              margin-right: 4px;
            }
          }

          &.days {
            background: rgba(0, 0, 0, 0.20);
            backdrop-filter: blur(25px);
          }

          &.countdown {
            background-color: #FF457E;
          }

          &.expired {
            background-color: #434A54;
          }
        }
      }

      .texts-wrp {
        .top-text-wrp {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #EEF2F6;

          .title {
            margin-top: 0px;
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
          }

          .offers-left {
            display: flex;
            align-items: center;
            color: #FF457E;

            svg {
              margin-right: 8px;
            }
          }
        }

        .bottom-text-wrp {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #EEF2F6;

          ul {
            display: grid;
            row-gap: 8px;
            margin: 0px;
            padding: 0px;
            list-style: none;

            li {
              display: flex;
              font-size: 12px;
              line-height: 150%;

              svg {
                flex-shrink: 0;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .price-actions-wrp {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .price-wrp {
          display: grid;
          grid-template-areas: "label label"
                               "price discount";
          grid-template-columns: auto minmax(0, 1fr);
          margin-bottom: 12px;

          .discount {
            grid-area: discount;

            .discount-bubble {
              display: inline-block;
              padding: 3px 12px;
              font-size: 16px;
              line-height: 150%;
              color: #ffffff;
              background-color: #FF457E;
              border-radius: 8px;
            }
          }
          .price-label {
            grid-area: label;
            font-size: 12px;
            line-height: 150%;
            color: #9EA7B5;
          }
          .price {
            grid-area: price;
            font-weight: 700;
            font-size: 24px;
            line-height: 125%;
            color: #434A54;
            white-space: nowrap;
          }
          &.wt-price {
            column-gap: 22px;
          }
        }
        .actions-wrp {
          display: flex;
          flex-direction: column;
          align-items: center;

          .show-offers {
            margin-bottom: 8px;
            min-width: auto;
            width: 100%;
          }
          .subscribe-offers {
            display: block;
            padding: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #434A54;
          }
        }
      }

      &.special:not(.expired) {
        background-color: #CCEDF9;

        .texts-wrp {
          .top-text-wrp, .bottom-text-wrp {
            border-color: #CED6E0;
          }
        }
        .price-actions-wrp {
          .actions-wrp {
            .show-offers {
              border-color: #FF457E;
              background-color: #FF457E;
            }
          }
        }
      }
      &.expired {
        .photo-wrp {
          img {
            opacity: 0.5;
          }
        }
        .texts-wrp {
          .top-text-wrp {
            opacity: 0.5;
          }
        }
        .price-actions-wrp {

        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .travel-discounts-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @include media-breakpoint-up(lg) {
    .container-fluid {
      padding-left: 16px;
      padding-right: 16px;
    }
    .page-title {
      margin-top: 83px;
      margin-bottom: 32px;
      font-size: 30px;
      line-height: 125%;
    }
    .travel-discounts-list {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .travel-discounts-item {
        display: grid;
        grid-template-areas: "photo text actions";
        grid-template-columns: minmax(330px, 330px) minmax(0, 1fr) minmax(236px, 236px);
        column-gap: 24px;
        padding: 24px;

        .photo-wrp {
          grid-area: photo;
          margin-bottom: 0px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .texts-wrp {
          grid-area: text;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0px 24px 0px 0px;
          border-right: 1px solid #EEF2F6;

          .top-text-wrp {
            border-bottom: none;

            .title {
              margin-bottom: 12px;
              font-size: 24px;
              line-height: 125%;
            }
            .offers-left {
              font-size: 16px;
              line-height: 150%;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
          .bottom-text-wrp {
            margin: 0px;
            padding: 0px;
            border-bottom: none;

            ul {
              grid-template-columns: repeat(2, minmax(0, 1fr));
              column-gap: 24px;
              row-gap: 12px;

              li {
                font-size: 14px;
                line-height: 150%;
              }
            }
          }
        }
        .price-actions-wrp {
          grid-area: actions;
          justify-content: space-between;

          .price-wrp {
            grid-template-areas: "discount"
                                 "label"
                                 "price";
            grid-template-columns: minmax(0, 1fr);
            text-align: right;

            .discount {
              margin-bottom: 12px;

              .discount-bubble {
                font-size: 20px;
              }
            }
            .price-label {
              font-size: 14px;
            }
            .price {
              font-size: 30px;

              span {
                font-size: 24px;
              }
            }
          }
        }

        &.special:not(.expired) {
          .texts-wrp {
            border-color: #CED6E0;
          }
        }
      }
    }
  }
}

#discountSubscribeModal {
  .modal-dialog {
    max-width: 427px;

    .modal-content {
      padding: 24px;
      border-radius: 6px;
      border: none;

      .modal-header {
        margin-bottom: 16px;
        padding: 0px 0px 16px 0px;
        border-color: #EEF2F6;

        span {
          margin: 0px;
          font-size: 20px;
          line-height: 150%;
        }
      }
      .modal-body {
        padding: 0px;

        input.form-control {
          height: 40px;
        }
      }
    }
  }
}

.travel-discount-banner {
  padding: 40px 24px;
  color: #ffffff;
  background-image: url('/images/frontend/offers-bg-mob.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;

  .text-wrp {
    font-size: 14px;
    line-height: 150%;

    h3 {
      margin-top: 0px;
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 150%;
    }
    p {
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 0px 0px 32px 0px;
      list-style: none;

      li {
        svg {
          margin-right: 5px;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
    .subscribe-offers {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 24px;
    background-image: url('/images/frontend/offers-bg.jpg');
    border-radius: 12px;

    .text-wrp {
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
      width: 100%;
      font-size: 16px;
      line-height: 150%;
      text-align: center;

      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 125%;
      }
      p {}
      ul {
        flex-direction: revert;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0px 0px 20px 0px;

        li {
          margin: 0px 12px;
        }
      }
      .subscribe-offers {
        width: initial;
      }
    }
  }
}