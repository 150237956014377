@use "sass:map";

// products/detailed.tpl
#products_detailed {

  .summary_wrp {
    float: right;
  }

  .add2cart_wrp {
    float: right;
    clear: right;

    #products_add2cart {
      .modifications {
        .modification_selector {
          @include clearfix();
          margin: 0 -5px;

          .modification {
            border: 1px solid $color-lines;
            margin: 5px;
            float: left;
            position: relative;
            cursor: pointer;
            width: 75px;
            height: 75px;

            &:hover::after, &.active::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 3px;
              background-color: theme-color();
            }

            span {
              display: inline-block;
              vertical-align: middle;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .items {
        .descriptive_filters:first-child {
          margin-top: -20px; // kompensuojam margin'a, jeigu nera title
        }
      }

      .modifications, .items, .quantity, .price_info {
        margin-bottom: 20px;
      }

      .price_info {
        .price {
          float: left;

          .current_price {
            font-size: 32px;

            &.no-quantity {
              font-size: 15px;
            }
          }

          &.has_discount .current_price {
            color: theme-color("danger");
          }

          .old_price {
            text-decoration: line-through;
          }
        }

        .add_to_wishlist {
          position: relative;

          float: right;
          font-size: 30px;
          line-height: 46px;

          .checked {
            top: 0;
            position: absolute;
            left: 0;
            transform: scale(0);
            transition: 0.5s;
          }

          &.active {
            .checked {
              transform: scale(1);
              transition: 0.5s;
            }
          }
        }

        #add2cart_button {
          float: right;
        }
      }
    }
  }

  .photos_wrp {
    float: left;

    #products_photos {
      @include clearfix();
      background-color: white;
      position: relative;
      padding: 15px;
      margin: 0;

      .main_photo {
        width: 100%;
        cursor: zoom-in;
        text-align: center;
        float: right;

        &.full_width {
          width: 100%;
        }

        picture {
          img {
            display: none;
          }
          &.active {
            img {
              display: inline-block;
            }
          }
        }
      }

      .thumbs {
        width: 0;
        position: relative;
        z-index: 2;
        overflow: visible;
        float: left;

        .thumbs_inner {
          width: 75px;
          margin-left: 1px;
          // mobile
          @media (max-width: map.get($grid-breakpoints, "sm")) {
            width: 50px;
          }
        }

        picture {
          img {
            width: 100%;
            cursor: pointer;
            margin-bottom: 5px;
            border: 1px solid $gray-300;
          }

          &:hover, &.active {
            img {
              border: 1px solid theme-color();
            }
          }
        }
      }

      .zoom {
        background: $white;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        display: none;
        overflow: hidden;
        cursor: zoom-out;
        z-index: $z-index-elements;

        @media (max-width: map.get($grid-breakpoints, "sm")) {
          position: fixed;
          bottom: 0;
          z-index: $z-index-dropdowns;
          left: 5%;
          top: 15%;
          height: 80%;
          width: 90%;
          border: 1px solid black;

          .close {
            font-size: 30px;
            opacity: 1;
          }
        }

        .wrapper {
          position: relative;

          img {
            position: absolute;
          }
        }
      }
    }
  }

  .product_detailed_description_wrp {
    padding: 20px 0;
  }

  .tags {
    left: 0;
    top: 10px;

    .tag {
      padding: 0 10px;
      text-align: left;
      margin-bottom: 10px;
      background: theme-color();
      color: $white;
      margin-right: 10px;
    }
  }
}