@use "sass:map";

.footer {
  padding-top: 32px;
  color: #ffffff;
  background-color: #012A41;

  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.footer-menu-columns-wrp {
  .inner-wrp { // separate menu and social
    display: grid;
    row-gap: 24px;
    grid-template-columns: minmax(0, 1fr);

    #pages_footer_menu {
      display: grid;
      row-gap: 28px;

      .footer-menu-column {
        .submenu_list ul, ul.submenu_list {
          list-style: none;
          display: grid;
          row-gap: 16px;

          > li {

          }
        }
      }
    }
    .footer-contacts-wrp {
      margin-bottom: 32px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      .contacts-wrp {
        .customer-support-wrp {
          display: grid;
          row-gap: 22px;
          margin-top: 25px;
          margin-bottom: 20px;

          .contact-wrp {
            display: grid;
            grid-template-columns: minmax(0, 24px) minmax(0, 1fr);
            column-gap: 12px;
          }
        }
        .socials-wrp {
          display: flex;
          justify-content: start;

          a {
            position: relative;
            display: grid;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            margin-bottom: 12px;
            width: 40px;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            transition: all .3s;

            svg {
              path {
                transition: all .3s;
              }
            }
            &:hover {
              background-color: rgba(255, 255, 255, 1);

              svg {
                path {
                  fill: #012A41;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      #pages_footer_menu {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 28px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 40px;

    .inner-wrp {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 32px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      #pages_footer_menu {
        grid-column: span 3;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 32px;

        .footer-menu-column {
          .submenu_list ul, ul.submenu_list {
            row-gap: 12px;
          }
        }
      }
      .footer-contacts-wrp {
        margin: 0px;
        padding: 0px;
        border-bottom: none;

        .contacts-wrp {
          .customer-support-wrp {
            margin-top: 34px;
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}

.footer-bottom {
  .footer-payments-wrp {
    display: grid;
    row-gap: 16px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    overflow: hidden;

    .title {
      margin: 0px;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
    .payments-wrp {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin: -16px;

      img {
        margin: 16px 18px;
      }
      &.single-image {
        margin: 0px;

        img {
          margin: 0px;
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .footer-payments-wrp {
      grid-template-columns: auto minmax(0, 1fr);
      column-gap: 32px;
      margin-bottom: 40px;
      padding-bottom: 40px;

      .payments-wrp {
        justify-content: end;
      }
    }
  }
}
.footer-copyright-wrp {
  padding-bottom: 40px;
}