@use "sass:map";

// products/filters.tpl
#filter {
  position: relative;

  &.is_shown {
    // all mobile (xs, sm)
    @media (max-width: map.get($grid-breakpoints, "md")) {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1001;
      background: $white;
      margin: 0;
      padding: 20px;
    }

    #filter_block {
      display: block;
      @media (max-width: map.get($grid-breakpoints, "md")) {
        position: absolute;
        top: 70px;
        bottom: 70px;
        left: 10px;
        right: 10px;
        margin: 0;
        border-width: 1px 0 0 0;
        overflow: scroll;
      }
    }

    // all mobile (xs, sm)
    @media (max-width: map.get($grid-breakpoints, "md")) {
      #filters_popup_header {
        display: block;
        .h1 {
          margin: 0;
        }

        .icon {
          font-size: 24px;
        }
      }

      #filters_popup_footer {
        display: block;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
      }
    }
  }

  .hidden-filter {
    display: none;
  }

  #filters_popup_header, #filters_popup_footer {
    display: none;
  }

  #filter_block {
    position: relative;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: block;
    }

    #filter_price {
      // mobile
      @media (max-width: map.get($grid-breakpoints, "md")) {
        > div {
          padding: 15px 30px;
          border-bottom: 1px solid $gray-300;
          background: $gray-100;
        }
      }

      #price_slider {
        margin-top: 15px;
        margin-left: 10px;
        margin-right: 10px;
        height: 2px;
        border: none;
        @include border-radius(2px);
        background: $gray-300;

        .ui-slider-range {
          top: 0;
        }

        .ui-slider-handle {
          background: theme-color() url('/public/images/frontend/slide_handle.png') center center no-repeat;
          border: none;
          width: 16px;
          height: 16px;
          margin-top: -3px;
          margin-left: -8px;

          @media (max-width: map.get($grid-breakpoints, "md")) {
            width: 30px;
            height: 30px;
            margin-top: -10px;
            margin-left: -15px;
            border-radius: 15px !important;
          }
        }

        .ui-widget-header {
          background: none;
          background-color: theme-color() !important;
        }

        .ui-corner-all {
          @include border-radius(2px);

        }
      }

      #price_slider_info {
        margin-top: 10px;

        @media (max-width: map.get($grid-breakpoints, "md")) {
          margin-top: 20px;
        }

        .val1 {
          float: left;
        }

        .val2 {
          float: right;
        }
      }
    }

    .filter-group {
      // all desktop (md, lg)
      @media (min-width: map.get($grid-breakpoints, "md")) {
        margin-bottom: 3em;
      }

      .title {
        text-transform: uppercase;

        // all desktop (md, lg)
        @media (min-width: map.get($grid-breakpoints, "md")) {
          border-bottom: none;
          padding-bottom: 0;
          margin: 0 0 0.75em;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .footer {
      a {
        border: none;
        border-radius: 0;
      }

      @media (min-width: map.get($grid-breakpoints, "md")) {
        border-top: 1px solid $color-lines;
      }
    }
  }

  a {
    color: $gray-800;
  }
}