@use "sass:map";

#filters-overlay {
  z-index: 501;
  overscroll-behavior: contain;
  display: none;

  &.active {
    display: block;
  }

  .filter-popup {
    height: 100%;
    overflow: auto;

    .filters-header {
      height: 56px;
    }

    .filter-footer {
      z-index: 2;
    }
  }
}

.filter-body {
  @include media-breakpoint-down(lg) {
    padding-bottom: 96px;
  }
  @include media-breakpoint-down(md) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 64px;
    margin-bottom: 30px;
  }

  .filter-toggle-wrapper {
    height: 48px;
    border: solid 1px map.get($airguru-colors, "light-gray");

    &.active {
      border-color: map.get($airguru-colors, "blue");

      .filter-icon {
        img {
          filter: invert(33%) sepia(99%) saturate(1140%) hue-rotate(177deg) brightness(101%) contrast(96%);
        }
      }
    }

    .filter-toggle {
      .custom-switch {
        padding-left: 40px;
        min-height: 24px;

        .custom-control-label {
          &::before {
            height: 24px;
            width: 40px;
            left: -40px;
            top: 0;
            border-radius: 12px;
            background: map.get($airguru-colors,'light-gray');
            border: 1px solid map.get($airguru-colors,'light-gray');
          }

          &::after {
            width: 20px;
            height: 20px;
            top: 2px;
            left: -38px;
            border-radius: 10px;
            background: map.get($airguru-colors,'white');
          }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          background: map.get($airguru-colors,'blue');
          border: 1px solid map.get($airguru-colors,'blue');
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(16px);
        }
      }
    }
  }

  .single-filter-wrapper {
    .filter-title {
      margin-bottom: 5px;
      @extend .f-12;
      @extend .fw-500;
      @extend .color-gray;
    }

    .filter-title-desktop {
      @extend .f-14;
      @extend .fw-500;
      @extend .color-black;
    }

    .filter-scroll-wrapper {
      .owl-stage {
        display:flex;
        flex-flow: row wrap;
        align-items: stretch;
        .owl-item {
          display:flex;

        }
      }
      .filter-item {
        @extend .p-3;
        cursor: pointer;
        width: 96px;
        border: solid 1px map.get($airguru-colors, "dark-gray");
        @include media-breakpoint-up(lg) {
          border: solid 1px map.get($airguru-colors, "white");
          height:40px;
          padding-top:0 !important;
          padding-bottom:0 !important;
          align-items:center;

          &.filter-dropdown-desktop {
            height:32px;
          }

          img {
            max-height: 24px;
          }
        }

        &.active {
          border: solid 1px map.get($airguru-colors, "blue");
          background-color: rgba(5, 139, 216, 0.1);

          .filter-item-icon {
            filter: invert(33%) sepia(99%) saturate(1140%) hue-rotate(177deg) brightness(101%) contrast(96%);
          }

          .filter-item-title {
            @extend .color-blue;
          }
        }

        .filter-item-icon {
          height: 32px;
        }

        .filter-item-title {
          @extend .f-12;
          @extend .fw-500;
          @extend .color-black;
          @include media-breakpoint-up(lg) {
            font-weight: 400 !important;
          }
        }

        &.filter-dropdown-desktop {
          background: rgba(5, 139, 216, 0.1);
          &.active {
            @extend .bg-blue;
            .filter-item-title {
              @extend .color-white;
            }
          }
        }
      }
    }

    .filter-pills-wrapper {
      .filter-item {
          cursor: pointer;
          background-color: rgba(5, 139, 216, 0.1);

          &.active {
            @extend .bg-blue;
            @extend .color-white;
          }
      }
    }

    .filter-checkbox-wrapper {
      .filter-item {
        cursor: pointer;
        //border: solid 1px map.get($airguru-colors, "light-gray");

        @include media-breakpoint-up(lg) {
          //border: solid 1px map.get($airguru-colors, "white");
          align-items:center;
        }

        &.active {
          //border: solid 1px map.get($airguru-colors, "blue");
          .filter-item-icon {
            filter: invert(33%) sepia(99%) saturate(1140%) hue-rotate(177deg) brightness(101%) contrast(96%);
          }
          .checkbox-input {
              .checkbox-control {
                @extend .bg-blue;
                border-color: map.get($airguru-colors, "blue");

                svg {
                  transform: scale(1);
                }
            }
          }
          .radio-input {
            .radio-control {
              @extend .bg-blue;
              border-color: map.get($airguru-colors, "blue");
              box-shadow: 0px 0px 0px 5px #fff inset;
            }
          }
        }

        .filter-item-icon {
          height: 24px;
        }

        .filter-item-title {
          @extend .color-black;
          @include media-breakpoint-up(lg) {
            font-size: 14px;
            text-transform: none !important;
            font-weight: 400 !important;
          }
        }

        .filter-item-checkbox {
          .checkbox-control {
            width: 24px;
            height: 24px;
            border: solid 1px map.get($airguru-colors, "gray-border");

            svg {
              transition: transform 0.1s ease-in 25ms;
              transform: scale(0);
              transform-origin: bottom left;
            }
          }

          .checkbox-input {
            input {
              opacity: 0;
              position: absolute;
            }
          }
        }
        .filter-item-radio {
          .radio-control {
            width: 24px;
            height: 24px;
            border: solid 1px #ccd1d9;
            border-radius: 12px;
          }
          .radio-input {
            input {
              opacity: 0;
              position: absolute;
            }
          }
        }
      }
    }

    .filter-categories-wrapper, .filter-dropdown-wrapper {
      &.active {
        .filter-categories-header, .filter-dropdown-header {
          @extend .border-blue;
          .expand-btn {
            transform: rotateZ(180deg);
          }
        }

        .dropdown-holder {
          min-height: 111px;
          display: block;
        }
      }

      .filter-categories-header, .filter-dropdown-header {
        @extend .bg-white;
        border-radius: 8px;
        border: solid 1px map.get($airguru-colors, "gray");
      }

      .dropdown-holder {
        display: none;

        .categories-list {
          .single-category {
            cursor: pointer;
            background-color: rgba(5, 139, 216, 0.1);

            @include media-breakpoint-up(lg) {
              height: 32px;
            }

            &.dismissible {
              display: none;
            }

            &.active {
              @extend .bg-blue;
              @extend .color-white;

              &.dismissible {
                display: inline-block;
              }
            }
          }
        }

        .other-categories-list {
          columns: 1;
          column-gap: 25px;
          column-fill: auto;

          .category-group {
            position: relative;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            &.list-collapse {
              .single-category:first-child {
                padding-right: 25px;
              }
              .list-open-trigger {
                position: absolute;
                top: 0px;
                right: -2px;
                z-index: 1;
                padding: 0px 6px;

                &:before {
                  content: "+";
                  font-size: 26px;
                  line-height: 18px;
                }
                &:after {
                  display: none;
                }
                &:hover {
                  text-decoration: none;
                }
              }
              &.open {
                .list-open-trigger {
                  &:before {
                    content: "—";
                    font-size: 20px;
                    font-weight: 500;
                  }
                }
              }
            }
          }
          .single-category {
            > span {
              position: relative;
              padding-left: 28px;

              &:before {
                content: "";
                position: absolute;
                left: 0px;
                display: block;
                width: 16px;
                height: 16px;
                border: 1px solid map.get($airguru-colors, "gray-border");
                border-radius: 4px;
              }
            }
            &.active > span:before {
              border-color: map.get($airguru-colors, "blue");
              background-color: map.get($airguru-colors, "blue");
            }
            &.active > span:after {
              content: "";
              position: absolute;
              top: 2px;
              left: 6px;
              height: 9px;
              width: 5px;
              border-right: 2px solid #ffffff;
              border-bottom: 2px solid #ffffff;
              transform: rotate(45deg);
            }
            &.active, &:hover {
              cursor: pointer;
              span {
                @extend .color-blue;
              }
            }
          }
        }

        .more-btn {
          svg {
            transform: rotateZ(-90deg);
          }
        }
      }

    }
    .hotel-select-wrp {
      display: block;
      position: relative;

      .hotel-filter-btn {
        position: relative;
        overflow: hidden;
        display: block;
        padding: 14px 16px;
        height: 52px;
        width: 100%;
        text-align: left;
        color: #aab2bd;
        white-space: nowrap;
        background: #fff;
        border: 1px solid map.get($airguru-colors, "gray-border");
        border-radius: 8px;
        span {
          position: absolute;
          top: 16px;
        }
      }
      .hotel-select {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: auto;
        background-color: transparent;
        z-index: 2;

        .search-wrp {
          input[name="hotel_search"] {
            display: block;
            padding: 14px 16px;
            width: 100%;
            text-align: left;
            background: #fff;
            border: 1px solid map.get($airguru-colors, "gray-border");
            border-radius: 8px;
          }
        }
        .results-wrp {
          margin-bottom: 90px;
          max-height: calc(100% - 56px - 118px);
          box-shadow: 2px 2px 7px -1px rgb(0 0 0 / 46%);
          background: #ffffff;
          border-radius: 8px;
          overflow: auto;

          #destinationsAccordionF {
            .card {
              border: none;

              .card-header {
                padding: 0px;
                border: none;

                span {
                  margin: 0px;
                }

                button {
                  position: relative;
                  display: flex;
                  align-items: center;
                  padding: 14px 16px 13px 16px;
                  width: 100%;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 21px;
                  text-transform: uppercase;
                  color: map.get($airguru-colors, "blue");
                  text-align: left;
                  background-color: rgba(5, 139, 216, 0.1);
                  border-radius: 0px;
                  border: none;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 22px;
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid;
                    border-bottom: 2px solid;
                    transform: translateY(-50%) rotate(45deg) translate3d(0, 0, 0);
                    transform-origin: top;
                  }
                  &.collapsed {
                    color: #434A54;
                    background-color: #FFFFFF;
                  }
                  &:hover {
                    text-decoration: none;
                    background-color: #FFFFFF;

                    &.collapsed {
                      color: map.get($airguru-colors, "blue");
                    }
                  }
                  &:focus {
                    text-decoration: none;
                  }
                  span.tag-bubble {
                    display: inline-block;
                    margin-left: 5px;
                    padding: 2px 5px;
                    font-size: 10px;
                    line-height: 13px;
                    color: #ffffff;
                    background-color: map.get($airguru-colors, "blue");
                    border-radius: 4px;
                    text-transform: none;
                  }
                }
              }

              .card-body {
                .category-group {
                  position: relative;
                  overflow: hidden;
                  page-break-inside: avoid;
                  -moz-column-break-inside: avoid;
                  break-inside: avoid;

                  &.list-collapse {
                    .list-open-trigger {
                      position: absolute;
                      top: 0px;
                      right: -5px;
                      z-index: 1;
                      padding: 0px 6px;
                      color: map.get($airguru-colors, "blue") !important;
                      border: none;

                      &:before {
                        content: "+";
                        font-size: 23px;
                        line-height: 24px;
                      }
                      &:after {
                        display: none;
                      }
                      &:hover, &:active {
                        text-decoration: none;
                      }
                    }
                    &.open {
                      .list-open-trigger {
                        &:before {
                          content: "—";
                          font-size: 18px;
                          font-weight: 500;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .single-category {
            display: grid;
            grid-template-columns: 24px minmax(0, 1fr);
            column-gap: 20px;
            cursor: pointer;

            .fake-checkbox {
              display: block;
              width: 24px;
              height: 24px;
              text-align: center;
              border: solid 1px map.get($airguru-colors, "gray-border");
              border-radius: 3px;

              svg {
                transition: transform 0.1s ease-in 25ms;
                transform: scale(0);
                transform-origin: bottom left;
              }
            }
            &.active {
              .fake-checkbox {
                @extend .bg-blue;
                border-color: map.get($airguru-colors, "blue");

                svg {
                  transform: scale(1);
                }
              }
            }
          }
          .no-result-wrp {
            padding: 20px;

            p {
              margin: 0px;
            }
          }
        }
        &.active_search {
          .results-wrp {
            .card-body {
              .category-group {
                > .title {
                  display: none;
                }
              }
              .submenu_list {
                .single-category {
                  padding-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .single-filter-wrapper {
      .hotel-select-wrp {
        .hotel-select {
          .results-wrp {
            max-height: 400px;

            #hotels-listing {
              .card-body {
                .category-group {
                  &.list-collapse {
                    .list-open-trigger {
                      right: -20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:not(:last-child) {
        > .container-fluid {

          &:after {
            content: "";
            display: block;
            margin-top: 24px;
            width: 100%;
            height: 1px;
            background-color: map.get($airguru-colors, "gray-border");
          }
        }
      }
    }
  }
}

.other-categories-overlay {
  z-index: 601;
  display: none;

  &.active {
    display: block;
  }

  .overlay-categories-body {
    padding-bottom: 126px;
    max-height: calc(100vh - 116px);
    overflow-y: auto;
    overscroll-behavior: contain;

    > .container-fluid {
      columns: 1;
      column-gap: 40px;
      column-fill: auto;

      .category-group {
        position: relative;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        &.list-collapse {
          .single-category:first-child {
            padding-right: 25px;
          }
          .list-open-trigger {
            position: absolute;
            top: 0px;
            right: -2px;
            z-index: 1;
            padding: 0px 6px;
            color: map.get($airguru-colors, "blue") !important;
            border: none;

            &:before {
              content: "+";
              font-size: 26px;
              line-height: 18px;
            }
            &:after {
              display: none;
            }
            &:hover {
              text-decoration: none;
            }
          }
          &.open {
            .list-open-trigger {
              &:before {
                content: "—";
                font-size: 20px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .single-category {
      //margin: 8px 0px;
      > span {
        position: relative;
        padding-left: 28px;

        &:before {
          content: "";
          position: absolute;
          left: 0px;
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid map.get($airguru-colors, "gray-border");
          border-radius: 4px;
        }
      }
      &.active > span:before {
        border-color: map.get($airguru-colors, "blue");
        background-color: map.get($airguru-colors, "blue");
      }
      &.active > span:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 6px;
        height: 9px;
        width: 5px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: rotate(45deg);
      }
      &.active {
        span {
          @extend .color-blue;
        }

        svg {
          margin-left: 12px;
          display: inline-block;

          .check-mark {
            fill: map.get($airguru-colors, "blue");
          }
        }
      }

      svg {
        display: none;
      }
    }
  }
}

#products_listing {
  #filter_summary {
    .filter-btn {
      @extend .p-2;
      @extend .rounded-sm;
      @extend .f-12;
      @extend .fw-500;
      @extend .color-blue;
      @extend .d-flex;
      @extend .float-left;
      @extend .border-blue;

      background-color: rgba(5, 139, 216, 0.1);

      &::before {
        display: none;
      }

      span {
        @extend .d-flex;
        @extend .ml-2;
        @extend .p-1;
        @extend .bg-blue;
        @extend .rounded;
      }

    }
  }

  .filters-summary-wrp {
    margin-bottom: 32px;
    border-bottom: 1px solid #E3E8EF;

    .filters-summary-header {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas: "filter sort"
                            "found found";
      column-gap: 12px;
      row-gap: 16px;
      margin-bottom: 16px;

      .offers-found-wrp {
        grid-area: found;
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        column-gap: 6px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #434A54;
      }
      #sort-container {
        grid-area: sort;

        .dropdown-toggle {
          position: relative;
          display: flex;
          align-items: center;
          padding: 6px 34px 6px 12px;
          min-height: 48px;
          //width: 175px;
          font-weight: 500;
          font-size: 16px;
          //font-size: 14px;
          //font-weight: 400;
          line-height: 150%;
          text-align: left;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 9px;
            display: block;
            margin: 0px;
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_4995_22907" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4995_22907)"><path d="M7.40002 15.0384L6.34619 13.9846L12 8.33081L17.6538 13.9846L16.6 15.0384L12 10.4384L7.40002 15.0384Z" fill="%23434A54"/></g></svg>');
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            transform-origin: top;
            transform: rotate(180deg) translateY(-50%);
            transition: all .3s;
          }
        }
        .dropdown-menu {
          @include slide_mob_dropdown_skeleton();
          border-radius: 0px;

          .top-wrp {
            max-height: 90%;
            height: auto;

            .scroll-part {
              padding: 16px;
              ul {
                list-style: none;
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                row-gap: 16px;
                margin: 0px;
                padding: 0px;

                li {
                  label {
                    margin: 0px;
                    padding: 9px 12px;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    text-align: left;
                    background-color: #FFFFFF;
                    border: 1px solid #ffffff;
                    border-radius: 6px;
                    transition: all .3s;

                    &:hover {
                      cursor: pointer;
                      color: #50AEE4;
                      border-color: #CED6E0;
                    }
                  }
                  input {
                    display: none;

                    &:checked + label {
                      color: #ffffff;
                      background-color: #55C4EA;
                      border-color: #55C4EA;
                    }
                  }
                }
              }
            }
          }
        }
        &.show {
          .dropdown-toggle {
            &:after {
              transform: rotate(0deg) translateY(-50%);
            }
          }
        }
      }
      .show-filters-mobile-wrp {
        grid-area: filter;

        #show-filters-mobile {
          min-width: auto;
          width: 100%;

          .counter {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            background-color: map.get($theme-colors, 'primary');;
            border-radius: 10px;
          }
        }
        #show-filters-mobile-sticky {
          position: fixed;
          z-index: 1;
          right: 20px;
          bottom: 30px;
          padding: 0px;
          min-width: auto;
          height: 48px;
          width: 48px;

          .counter {
            position: absolute;
            top: -8px;
            right: -8px;
            display: block;
            width: 23px;
            height: 23px;
            font-size: 14px;
            line-height: 21px;
            color: #434A54;
            background-color: #ffffff;
            border: 1px solid #CED6E0;
            border-radius: 12px;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .filters-summary-wrp {
      .filters-summary-header {
        .show-filters-mobile-wrp {
          #show-filters-mobile-sticky {
            bottom: 80px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .filters-summary-wrp {
      .filters-summary-header {
        #sort-container {
          .dropdown-menu {
            @include popup_mobile_movement();
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .filters-summary-wrp {
      border-bottom: none;

      .filters-summary-header {
        grid-template-columns: auto;
        grid-template-areas: "found sort";
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid #E3E8EF;

        #sort-container {
          .dropdown-toggle {
            width: 254px;
          }
          .dropdown-menu {
            position: absolute;
            top: calc(100% + 8px);
            width: 100%;
            background: transparent;

            .top-wrp {
              background: transparent;
              border-radius: 0px;

              .dropdown-header, .buttons-wrp {
                display: none !important;
              }
              .scroll-part {
                padding: 4px;
                background: #FFF;
                border-radius: 8px;
                border: 1px solid #CED6E0;
                box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);

                ul {
                  row-gap: 4px;
                }
              }
            }
          }
        }
      }
      .filter-summary-mob-header {
        display: none;
      }
      .filter-summary {
        &.summary_type-desktop {
          display: grid;
          grid-template-columns: minmax(0, 1fr) auto;
          column-gap: 20px;
          align-items: start;

          .selected-wrp {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;

            .filter-btn {
              display: flex;
              align-items: center;
              margin: 0px 12px 12px 0px;
              padding: 2px 9px 2px 6px;
              font-size: 12px;
              font-weight: 400;
              line-height: 150%;
              border: 1px solid #CED6E0;
              border-radius: 8px;
              transition: all .3s;

              span {
                svg {
                  margin-left: 10px;
                  width: 8px;
                }
              }
              &:hover {
                background: #DDF3FB;
                border-color: #DDF3FB;
              }
            }
          }
          .clean_all {
            padding: 4px 0px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #FF457E;

            svg {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}

#custom_filters_desktop {
  .desktop-filters {
    @include slide_mob_dropdown_skeleton();

    .top-wrp {
      .scroll-part {
        padding: 0px 16px;
        text-align: left;

        .filter-summary-mob-header {
          margin-bottom: 12px;
          padding-top: 25px;
          padding-bottom: 12px;
          border-bottom: 1px solid #EEF2F6;

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .filter-summary {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;

          .btn {
            display: flex;
            align-items: center;
            margin: 0px 12px 12px 0px;
            padding: 4px 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            border-radius: 8px;
            border: 1px solid #CED6E0;

            svg {
              margin-left: 10px;
              width: 11px;
            }

            &.clean_all {
              padding: 4px 0px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #FF457E;
              border: none;
            }
          }
        }
      }
    }
    #filter_price, .single-filter-wrapper {
      margin-bottom: 24px;

      .title {
        margin-bottom: $headings-margin-bottom;
        padding: 2px 20px 2px 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border: none;

        &:after {
          content: "";
          top: 0px;
          right: -3px;
          display: block;
          margin: 0px;
          width: 24px;
          height: 24px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_4995_22907" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4995_22907)"><path d="M7.40002 15.0384L6.34619 13.9846L12 8.33081L17.6538 13.9846L16.6 15.0384L12 10.4384L7.40002 15.0384Z" fill="%23434A54"/></g></svg>');
          background-repeat: no-repeat;
          background-position: center;
          transform-origin: center;
          transform: rotate(180deg);
          transition: all .3s;
        }
        & + * {
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid #EEF2F6;
        }
      }
      &.open span:after {
        transform: rotate(0deg);
      }
    }
    #filter_price {
      .bars {
        display: grid;
        grid-template-columns: repeat(11, minmax(0, 1fr));
        column-gap: 4px;
        align-items: end;

        .bar {
          background-color: #F5F7FA;
          border-radius: 2px 2px 0px 0px;
        }
      }
      #price_slider {
        height: 4px;
        background-color: #CED6E0;
        border: none;
        border-radius: 2px;

        .ui-widget-header {
          background: map.get($theme-colors, 'primary');
        }
        .ui-slider-handle {
          top: -4px;
          width: 12px;
          height: 12px;
          background: map.get($theme-colors, 'primary');
          border: none;
          border-radius: 3px;

          span {
            position: absolute;
            bottom: calc(100% + 8px);
            display: block;
            padding: 9px 12px;
            color: #707B8B;
            white-space: nowrap;
            background-color: #ffffff;
            border: 1px solid #F5F7FA;
            border-radius: 8px;
          }
          &:nth-child(1) span, &:nth-child(2) span {
            &[data-position="left"] { left: -8px; }
            &[data-position="center"] { left: 50%; transform: translateX(-50%); }
            &[data-position="right"] { right: -8px; }
          }
        }
      }
    }
    .single-filter-wrapper {
      .filter-checkbox-wrapper {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 10px;

        .filter-item {
          display: grid;
          grid-template-columns: auto minmax(0, 1fr);
          column-gap: 8px;
          margin: 0px;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;

          .filter-item-checkbox {
            .checkbox-input {
              .checkbox-control {
                width: 20px;
                height: 20px;
                background-color: #ffffff;
                border: solid 1px #CED6E0;
                border-radius: 4px;
                transition: all .3s;

                svg {
                  transform-origin: center;
                }
              }
            }
          }
          &.active {
            .filter-item-checkbox {
              .checkbox-input {
                .checkbox-control {
                  border-color: map.get($theme-colors, 'primary');
                }
              }
            }
          }
        }
        &.categories {
          .category-group {
            display: grid;
            grid-template-areas: "country toggle"
                                 "cities cities";
            grid-template-columns: minmax(0, 1fr) auto;
            column-gap: 10px;

            > label {
              grid-area: country;
            }
            > .list-open-trigger {
              grid-area: toggle;
              justify-content: space-between;
              margin: 0px;
              font-weight: 400;
              color: #434a54;

              p {
                font-size: 14px;
              }
              &:hover {
                text-decoration: none;
              }
            }
            > .submenu_list {
              grid-area: cities;
              margin-top: 0px;
              padding-top: 10px;
              padding-left: 24px;
              border-top: 0px;

              .filter-item {
                &:not(:last-child) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .desktop-filters {
      @include popup_mobile_movement();
    }
    &.opened {
      .desktop-filters {
        top: 0px !important;
        height: initial !important;
        z-index: 1000;

        & + .dropdown-bg {
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 301;
          background: #000000;
          opacity: 0.3;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .desktop-filters {
      position: initial;
      overflow: visible;
      background-color: transparent;

      .top-wrp {
        background-color: transparent;

        .scroll-part {
          overflow: visible;
          padding: 0px;
        }
        .buttons-wrp {
          display: none;
        }
      }
      #filter_price, .single-filter-wrapper {
        margin-bottom: 12px;
        padding: 12px 16px;
        background: #ffffff;
        border-radius: 8px;

        span {
          margin: 0px;
          &:after {}
          & + * {}
        }
        &.open span:after {}
      }
    }
  }
}

#filter-btn-holder {
  bottom: 32px;
  z-index: 2;

  #filters-btn {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    background-color: map.get($airguru-colors, "light_blue");

    .filters-count {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      font-size: 10px;
      line-height: 10px;
      color: #ffffff;
      background-color: map.get($airguru-colors, "red");
      border-radius: 8px;
    }
  }
}