@use "sass:map";

#top-categories {
  .top-category {
    .top-category-image {
      .price-holder {
        top: 16px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      .offers-holder {
        right: 16px;
        top: 80px;
      }
    }
  }
}