@use "sass:map";

#collections_icons_index_listing {
  align-self: start;
  position: relative;
  margin-top: calc(600px - 177px);

  > .container-fluid {
    padding: 0px;

    > .inner-wrp {
      padding-bottom: 32px;
      border-bottom: 1px solid #E3E8EF;
    }
  }
  .collections-index-tabs {
    .owl-container {
      .owl-stage {
        display: flex;
      }
    }
    .icons_slider {
      position: relative;

      .collection-tab {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 6px;
        padding: 10px;
        min-width: 126px;
        height: 100%;
        color: #ffffff;
        text-align: center;
        border-radius: 8px 8px 0px 0px;
        background: rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(25px);

        .icon-wrp {
          justify-self: center;
          width: 20px;
        }
        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
        &.search-tab {
          color: #434A54;
          background: #ffffff;
        }
        &:hover {
          text-decoration: none;
        }
      }
      .owl-nav {
        position: initial;

        button {
          position: absolute;
          top: 50%;
          width: 28px;
          height: 28px;
          color: #ffffff;
          border-radius: 8px;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 20px 20px !important;
          background-color: rgba(255, 255, 255, 0.20);
          transform: translateY(-50%);
          transition: all .3s;

          span {
            display: none;
          }
          &.owl-prev {
            left: 4px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_4923_8504" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4923_8504)"><path d="M12.7484 17.4471L5.81573 10.5273C5.73773 10.4493 5.68271 10.367 5.65067 10.2805C5.61861 10.1939 5.60258 10.1005 5.60258 10C5.60258 9.8996 5.61861 9.80612 5.65067 9.71959C5.68271 9.63305 5.73773 9.55079 5.81573 9.4728L12.7564 2.53209C12.9135 2.37504 13.1058 2.29651 13.3333 2.29651C13.5609 2.29651 13.7601 2.38198 13.9311 2.55293C14.0967 2.71852 14.1795 2.91349 14.1795 3.13784C14.1795 3.3622 14.0967 3.55718 13.9311 3.72278L7.65383 10L13.9391 16.2853C14.0961 16.4423 14.1747 16.6325 14.1747 16.8558C14.1747 17.0791 14.0892 17.2762 13.9183 17.4471C13.7527 17.6127 13.5577 17.6955 13.3333 17.6955C13.109 17.6955 12.914 17.6127 12.7484 17.4471Z" fill="white"/></g></svg>') !important;
          }
          &.owl-next {
            right: 4px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.92648 16.4031C6.75327 16.2275 6.66667 16.0196 6.66667 15.7795C6.66667 15.5394 6.75327 15.3316 6.92648 15.1559L12.0015 10.0088L6.90916 4.84407C6.7475 4.68011 6.66667 4.47517 6.66667 4.22923C6.66667 3.98329 6.75327 3.77249 6.92648 3.59682C7.09969 3.42115 7.30466 3.33331 7.54138 3.33331C7.7781 3.33331 7.98306 3.42115 8.15627 3.59682L13.9761 9.51689C14.0454 9.58716 14.0945 9.66328 14.1234 9.74526C14.1522 9.82724 14.1667 9.91507 14.1667 10.0088C14.1667 10.1025 14.1522 10.1903 14.1234 10.2723C14.0945 10.3542 14.0454 10.4304 13.9761 10.5006L8.13895 16.4207C7.97729 16.5847 7.7781 16.6666 7.54138 16.6666C7.30466 16.6666 7.09969 16.5788 6.92648 16.4031Z" fill="white"/></svg>') !important;
          }
          &:hover, &:focus {
            outline: none;
            background-color: rgba(255, 255, 255, 0.60) !important;
          }
        }
      }
    }
  }
  .search-form {
    border-radius: 0px 0px 8px 8px;
  }
  .detailed-search-link {
    align-self: start;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
    border: none;
    background: none;

    @include underline(#434A54, true);
    &:hover, &:focus {
      outline: none;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 415px;

    > .container-fluid {
      padding-right: 16px;
      padding-left: 16px;

      > .inner-wrp {
        padding-bottom: 36px;
      }
    }
    .collections-index-tabs {
      .icons_slider {
        .collection-tab {
          display: flex;
          padding: 10px 14px;

          .icon-wrp {
            margin-right: 12px;
          }
          .title {
            white-space: nowrap;
          }
        }
      }
    }
    .search-form {
      border-radius: 0px 8px 8px 8px;
    }
    .detailed-search-link {
      align-self: end;
    }
  }
}

#collections_index_listing, #top-collections, #top-categories {
  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
  .collection-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h2 {
      margin: 0px;
      font-size: 20px;
      font-weight: 600;
      line-height: 150%;
      color: #434A54;
    }
    .today_count {
      display: flex;
      margin-top: 12px;

      .new-icon {
        width: 16px;
        height: 16px;
        background: transparent;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M10.8619 3.21741C10.0846 2.39835 9.21 1.67756 8.2575 1.07116C8.17973 1.02444 8.09072 0.999756 8 0.999756C7.90928 0.999756 7.82027 1.02444 7.7425 1.07116C6.79 1.67756 5.91537 2.39835 5.13812 3.21741C3.41187 5.02991 2.5 7.02929 2.5 8.99991C2.5 10.4586 3.07946 11.8575 4.11091 12.889C5.14236 13.9204 6.54131 14.4999 8 14.4999C9.45869 14.4999 10.8576 13.9204 11.8891 12.889C12.9205 11.8575 13.5 10.4586 13.5 8.99991C13.5 7.02929 12.5881 5.02991 10.8619 3.21741ZM6 11.4999C6 9.77054 7.40812 8.54491 8 8.10616C8.5925 8.54366 10 9.77054 10 11.4999C10 12.0303 9.78929 12.5391 9.41421 12.9141C9.03914 13.2892 8.53043 13.4999 8 13.4999C7.46957 13.4999 6.96086 13.2892 6.58579 12.9141C6.21071 12.5391 6 12.0303 6 11.4999Z" fill="%23FF457E"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

#collections_index_listing {
  .btn.btn-lg {
    line-height: 30px;
  }
  .collection {
    .collection-header {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      column-gap: 30px;
      margin-bottom: 24px;

      .text-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
      }
    }
    .more-btn-wrp {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-up(lg) {
    .collection {
      .collection-header {
        .btn.btn-lg {
          min-width: 150px;
        }
      }
    }
  }
}
#top-collections {
  margin-bottom: 32px;

  .top-collections-listing {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;

    .top-collection {
      .top-collection-wrapper {
        padding-top: 120px / 169.5px * 100%;
      }
      &:first-child {
        grid-column: span 2;

        .top-collection-wrapper {
          padding-top: 240px / 355px * 100%;
        }
        span {
          font-size: map.get($airguru-font-sizes, 20) !important;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .top-collections-listing {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .top-collection {
        &:first-child {
          grid-row: span 2;
        }
      }
    }
  }
}
#top-categories {
  .top-categories-listing {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px;
  }

  @include media-breakpoint-up(sm) {
    .top-categories-listing {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
      row-gap: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    .top-categories-listing {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}