$grid-breakpoints: (
    xs: 0,
    xs2: 375px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$theme-colors: (
        "primary": #058bd8,
        "danger": #fa0f3d,
        "success": #5cb85c,
        "info": #5bc0de,
        "warning": #f0ad4e
);

$airguru-colors: (
        "white": #fff,
        "white_01": rgba(255, 255, 255, 0.1),
        "white_03": rgba(255, 255, 255, 0.3),
        "white_05": rgba(255, 255, 255, 0.5),
        "blue": #058bd8,
        "light_blue": #41b4e6,
        "light_blue2": #55C4EA,
        "blue_01": rgba(5, 139, 216, 0.1),
        "pink": #ff457e,
        "orange": #ff8f4a,
        "yellow": #ffdb45,
        "red": #ff5453,
        "gray": #aab2bd,
        "gray-border": #CCD1D9,
        "dark-gray": #707070,
        "light-gray": #e6e9ed,
        "lightest-gray": #f5f7fa,
        "black": #434a54,
        "black_05": rgba(67, 74, 84, 0.5),
        "green": #4F8A10
);

$airguru-font-sizes: (
        10: 10px,
        12: 12px,
        14: 14px,
        15: 15px,
        16: 16px,
        17: 17px,
        18: 18px,
        19: 19px,
        20: 20px,
        21: 21px,
        22: 22px,
        23: 23px,
        24: 24px,
        30: 30px,
        36: 36px,
        44: 44px
);

$container-fluid-width: 1140px;

$font-size-base: 0.875rem;
$line-height-base: 1.42857143;

$border-radius: 8px !default;

$grid-gutter-width: 32px;
$grid-gutter-width-mobile: 20px;

$spacer: 16px;

$spacers: (
        6: 6px,
        7: 7px,
        8: 8px,
        9: 9px,
        10: 10px,
        11: 11px,
        12: 12px,
        14: 14px,
        15: 15px,
        16: 16px,
        17: 17px,
        18: 18px,
        19: 19px,
        20: 20px,
        22: 22px,
        24: 24px,
        25: 25px,
        26: 26px,
        28: 28px,
        30: 30px,
        32: 32px,
        33: 33px,
        34: 34px,
        35: 35px,
        36: 36px,
        38: 38px,
        40: 40px,
        42: 42px,
        44: 44px,
        48: 48px,
        50: 50px,
        54: 54px,
        56: 56px,
        60: 60px,
        64: 64px,
        66: 66px,
        70: 70px,
        72: 72px,
        75: 75px,
        80: 80px,
        96: 96px,
        120: 120px,
        128: 128px
);

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid !default;
