@use "sass:map";

#subscribers_subscribe {
  margin: 45px 0 15px 0;

  h4 {
    color: $black;
    font-size: 14px;
    letter-spacing: 1px;
    vertical-align: top;
    margin: 12px 0;

    //desktop
    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: inline-block;
      margin-right: 50px;
    }

    //mobile
    @media (max-width: map.get($grid-breakpoints, "md")) {
      text-align: center;
    }
  }

  #newsletter_form {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: inline-block;
      max-width: 320px;
      vertical-align: top;
    }

    .btn {
      padding: 7px 9px;
      color: $black;
      background-color: $white;
      border-color: $gray-300;
      border-left: none;

      .icon {
        font-size: 17px;
      }
    }
  }

  .form-control {
    height: 40px;
    font-size: 15px;
    color: $black;
    background: $white;
    border-color: $gray-300;
    border-right: none;
    box-shadow: none;
  }

  .alert {
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    color: $white;
  }

  .alert-danger {
    background: theme-color("danger");
  }

  .alert-success {
    background: theme-color("success");
  }
}