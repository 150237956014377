@use "sass:map";

#about-us-icons {
  padding-bottom: 50px;
  background-color: #ffffff;
  overflow: hidden;

  .inner-wrp {
    .block-title {
      margin-top: 0px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #434A54;
    }
    .swiper {
      overflow: visible;

      .about-us-list {
        .swiper-slide {
          height: auto;

          .element-wrp {
            display: grid;
            align-items: center;
            justify-content: center;
            padding: 30px 12px;
            height: 100%;

            a {}
            img {
              max-width: 48px;
              max-height: 46px;
              height: auto;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 5px;
    padding-bottom: 48px;

    .inner-wrp {
      display: grid;
      grid-template-columns: minmax(250px, 300px) minmax(0, 1fr);
      align-items: center;
      column-gap: 30px;

      .block-title {
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 24px;
        text-align: left;
      }
      .swiper {
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

#footer-advantages {
  padding-bottom: 42px;
  background-color: #ffffff;

  .advantages-inner-wrp {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 24px;
    margin-bottom: 30px;

    .single-advantage {
      .icon-wrp {
        margin-bottom: 16px;

        img {
          width: 32px;
        }
      }
      .title {
        display: block;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .desc {
        font-size: 14px;
        line-height: 21px;
        color: #707B8B;

        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .photo-wrp {
    padding-bottom: 38px;
    border-bottom: 1px solid #EEF2F6;

    img {
      border-radius: 12px;
    }
  }


  @include media-breakpoint-up(md) {
    .advantages-inner-wrp {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      margin-bottom: 40px;
      text-align: center;
    }
    .photo-wrp {
      padding-bottom: 62px;
    }
  }
}

.additional-warnings {
  .warning-wrapper {
    background-color: rgba(255, 143, 74, 0.1);

    .warning-title {
      &.active {
        border-bottom: solid 1px map.get($airguru-colors, "orange");
      }

      .close-btn {
        &[data-closed="0"] {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    .warning-content {

      ul {
        list-style: initial;
        margin-left: 16px;

        li {
          font-family: Roboto;
          font-size: 14px;
          line-height: 1.71;
          color: map.get($airguru-colors, "black");
        }
      }

      .read-more {
        text-decoration: underline;
      }
    }
  }
}