/*
 * Z-indexes
 */
$z-index-step: 100;
$z-index-elements: 1;
$z-index-nav: 4;
$z-index-header: 5;
$z-index-dropdowns: $z-index-elements + $z-index-step;
$z-index-secondary-navigation: $z-index-dropdowns + $z-index-step;
$z-index-header-footer: $z-index-secondary-navigation + $z-index-step;
$z-index-navigation: $z-index-header-footer + $z-index-step;
$z-index-full-screen: $z-index-navigation + $z-index-step;
$z-index-special-case: $z-index-full-screen + $z-index-step;
$z-index-modals: $z-index-special-case + $z-index-step;
$z-index-notifications: $z-index-modals + $z-index-step;