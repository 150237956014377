@use "sass:map";

body {
  &.fancybox-active {
    touch-action: pan-x pan-y;
  }
  &#type_index, &#type_all_news, &#type_news {
    background-color: #F5F7FA;
  }
  @include media-breakpoint-down(md) {
    &[class*='scroll-lock-mob-'] {
      overflow: hidden;
    }
  }
}

#content_layout {
  #content_wrapper {
    padding-top: 56px;

    @include media-breakpoint-up(lg) {
      padding-top: 97px;
    }
  }
  &.content_layout_products, &.layout_product_detailed {
    background-color: #F5F7FA;
  }
}

#type_index {
  #index-top-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    margin: 40px 12px 32px 12px;
    min-height: 600px;

    #banners_hero, .heroBannerPlaceholder {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 0;
    }
  }
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    #index-top-wrp {
      margin-left: auto;
      margin-right: auto;
      max-width: 1204px;
    }
  }
}

.icon-pencil {
  margin-right: 8px;
  height: 12px;
  width: 12px;
  display: inline-block;
  background: url("/images/frontend/icon-pencil.svg") no-repeat;
}

.text-right {
  text-align: right;
}

.pr-16 {
  padding-right: 16px;
}