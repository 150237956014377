@use "sass:map";

.radio-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-container span {
  border: 1px solid map.get($colors, 'black');
  @include border-radius(4px);
  position: relative;
  margin-right: 8px;
  padding: 8px 16px;
}
/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ span, .radio-container:hover input ~ span {
  border: 1px solid map.get($colors, 'blue');
  color: map.get($colors, 'blue');
}