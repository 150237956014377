@use "sass:map";

.page-editor-detailed-wrp {
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  @include media-breakpoint-up(lg) {
    .container-fluid {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}
.page-editor-template {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 23px;
  overflow: hidden;


  &.full-width {
    .container-fluid {
      max-width: 100%;
    }
  }
  &.airguru-bg, &.has-bg {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &.airguru-bg {
    color: $white;
    background-color: map.get($theme-colors, 'primary');
    background-image: url('../../images/frontend/bg-logo-w.png');
    background-position: calc(100% - 20px) calc(100% - 20px);
    background-repeat: no-repeat;
    background-size: 300px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 27px;

    &.airguru-bg, &.has-bg {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    &.airguru-bg {
      background-position: calc(100% - 48px) calc(100% - 39px);
      background-size: 590px;
    }
  }
}

.editor-type-content_menu {
  a {

  }
  .parent-listing {
    list-style: none;
    margin: 0px;

    > li {
      margin-bottom: 12px;

      > a {
        font-weight: 500;
      }
    }
  }
  .childs {
    margin-top: 4px;

    > li {
      margin-bottom: 4px;

      > a {

      }
    }
  }
}

.editor-type-banners {
  .banners-list-wrp {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;

    .banner-el {
      img {
        border-radius: 5px;
        width: 100%;
      }
      &.size-full {
        grid-column: span 6;
      }
      &.size-half {
        grid-column: span 6;
      }
      &.size-third {
        grid-column: span 6;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .banners-list-wrp {
      .banner-el {
        &.size-full {
          grid-column: span 6;
        }
        &.size-half {
          grid-column: span 3;
        }
        &.size-third {
          grid-column: span 2;
        }
      }
    }
  }
}
.editor-type-text {
  .container-fluid {
    p {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.editor-type-collection, .editor-type-category {
  .container-fluid {}
  .owl-stage {
    display: grid;
    grid-auto-flow: column;

    .owl-item {
      &:nth-child(3n+1) {
        .product_element_wrapper {
          .img-wrapper {
            .title-wrapper {
              background-color: map.get($airguru-colors, "pink");
            }
          }
        }
      }

      &:nth-child(3n+2) {
        .product_element_wrapper {
          .img-wrapper {
            .title-wrapper {
              background-color: map.get($airguru-colors, "blue");
            }
          }
        }
      }

      &:nth-child(3n+3) {
        .product_element_wrapper {
          .img-wrapper {
            .title-wrapper {
              background-color: map.get($airguru-colors, "orange");
            }
          }
        }
      }
    }
  }
  .owl-nav {
    color: $gray-800;

    button {
      @include owl_carousel_btns();
      border-radius: 22px !important;
      background-color: #ffffff !important;
    }
  }
  .product_element_wrapper {
    margin: 0px !important;
    padding: 0px !important;
    max-width: 100% !important;
    height: 100%;
  }
}
.editor-type-request_form {
  color: #6a6a6a;

  .row {
    margin-left: -25px !important;
    margin-right: -25px !important;

    [class^='col-'] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .main-label {
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #c8c8c8;
  }
  .main-label2 {
    margin: 20px 0 -20px;
  }
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: currentColor;
    vertical-align: initial;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .grecaptcha-badge {
    z-index: 9;
  }
  .btn.btn-primary {
    width: 100%;
    height: 56px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
  }
  .btn-link {
    width: initial;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #41b4e6;

    svg {
      width: 14px;
      height: 12px;
      margin-right: 16px;
    }
  }
  .nav {
    margin-top: auto;
    align-items: center;
    padding-top: 32px;
  }
  .radios {
    .radio-item {
      position: relative;
      margin-bottom: 16px;
      display: block;

      .check-label {
        display: block;
        cursor: pointer;
        position: relative;
        padding-left: 48px;
        line-height: 24px;

        &:before {
          content: "";
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          transition: all .2s ease-in;
          background: #fff;
          border: 1px solid #dbdbdb;
        }
        &:after {
          content: "";
          width: 16px;
          opacity: 0;
          margin: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 16px;
          transition: all .2s ease-in;
          border-radius: 50%;
          background: #41b4e6;
        }
      }
      input {
        position: absolute;
        left: 0;
        opacity: 0;
        top: 0;

        &:checked {
          + .check-label {
            &:before {
              border-color: #41b4e6;
            }
            &:after {
              opacity: 1;
            }
          }
          ~ textarea.form-control {
            display: block;
          }
        }
      }
      textarea {
        &.form-control {
          display: none;
          overflow: auto !important;
          margin-top: 10px;
          padding-bottom: 15px;
          min-height: 56px;
        }
      }
    }
  }
  .checkbox {
    display: block;
    margin-top: 40px;

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;

      &:checked {
        + .check-label {
          &:after {
            opacity: 1;
          }
          &:before {
            background: #41b4e6;
          }
        }
      }
    }
    .check-label {
      padding-left: 40px;
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      padding-right: 20px;
      line-height: 17px;
      position: relative;
      display: block;

      a {
        text-decoration: underline;
        color: #41B4E6;

        &:hover {
          text-decoration: none;
        }
      }
      &:before, &:after {
        content: "";
        width: 24px;
        height: 24px;
        transition: all .2s ease-in;
        border-radius: 4px;
        border: 1px solid #41b4e6;
        position: absolute;
        left: 0;
        top: 0;
      }
      &:after {
        border: 0;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='11'><path d='M4.951 8.537l7.3-8.2a1 1 0 011.505 1.317l-8 9a1 1 0 01-1.46.049l-4-4a1 1 0 011.411-1.41z' fill='white'/></svg>") no-repeat 50% 50%;
        opacity: 0;
      }
    }
  }
  .select-holder {
    min-height: 200px;

    .select2, select {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  .qnt-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;

    .qnt-select {
      display: flex;
      align-items: center;
      padding: 0 6px;

      button {
        width: 26px;
        height: 26px;
        padding: 0;
        margin: 0;
        transition: all .2s ease-in;
        cursor: pointer;
        border: 2px solid #41B4E6;
        color: #41B4E6;
        border-radius: 50%;
        background: 0 0;
        outline: none;

        svg {
          width: 26px;
          margin: -2px;
          max-width: none;
          max-height: none;
          height: 26px;
        }
        &[disabled] {
          border-color: rgba(65, 180, 230, 0.5);
          color: rgba(65, 180, 230, 0.5);
          pointer-events: none;
        }
        &:hover {
          background: #41B4E6;
          color: #fff;
        }
      }
      input {
        font-weight: normal;
        font-size: 20px;
        border: 0;
        background: none;
        text-align: center;
        color: #6a6a6a;
        height: 26px;
        padding: 0;
        width: 62px;
      }
    }
  }
  .select-inline {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;

    .radio-item {
      position: relative;
      margin: 0 16px 16px 0;
      width: calc(50% - 16px);

      svg {
        position: absolute;
        left: 0;
        width: 40px;
        height: 40px;
        transition: all .2s ease-in;
        pointer-events: none;
        bottom: 0;
        margin: auto 16px;
        fill: currentColor;
        top: 0;
      }
      .check-label {
        display: flex;
        align-items: center;
        height: 72px;
        border-radius: 8px;
        background: #fff;
        font-weight: normal;
        transition: all .2s ease-in;
        cursor: pointer;
        font-size: 14px;
        padding-left: 70px;
        text-align: left;
        color: #6a6a6a;
        border: 1px solid #888;
      }
      input {
        position: absolute;
        left: 0;
        opacity: 0;
        top: 0;

        &:checked {
          ~ svg {
            fill: #41b4e6;
          }
          + .check-label {
            border-color: #41b4e6;
            color: #41b4e6;
          }
        }
      }
    }
  }
  .form-group {
    position: relative;
    margin-bottom: 24px;

    svg.icon {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 20px 16px;
      z-index: 9;
      pointer-events: none;
      width: 16px;
      height: 16px;
    }
    &.with-icon {
      input, select, textarea {
        padding-left: 47px;
      }
    }
    &.v2 {
      margin-bottom: 16px;
    }
    .placeholder ~ .icon {
      fill: #c8c8c8;
    }
    &.date {
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px;
        height: 40px;
        margin: 10px 1px;
        background: #fff;
        z-index: 9;
        pointer-events: none;
      }
    }
    label {
      display: block;
      font-weight: normal;
      font-size: 14px;
      padding: 0 0 6px;
      text-align: left;
      color: #6a6a6a;
    }
  }
  input, select, textarea {
    &.form-control {
      width: 100%;
      height: 56px;
      border-radius: 8px;
      background: 0 0;
      border: 1px solid #888;
      font-weight: 400;
      padding: 0 16px;
      font-size: 16px;
      line-height: 20px;
      display: block;
      text-align: left;
      color: #6a6a6a;

      &[readonly] {
        background-color: inherit;
      }
      &[type="date"] {
        padding-top: 18px;
        padding-bottom: 18px;
        line-height: 16px;
      }
      &::-webkit-input-placeholder {
        color: #C8C8C8;
      }
      &::-moz-placeholder {
        color: #C8C8C8;
      }
      &.placeholder {
        color: #c8c8c8;
      }
      &.error {
        border-color: #f00;
      }
      &:focus {
        border-color: #058BD8;
      }
    }
  }
  textarea.form-control {
    padding-top: 18px;
  }

  .steps {
    text-align: center;
    background: rgba(65, 180, 230, 0.5);
    cursor: default;

    ol {
      display: flex;
      margin: 0px;

      li {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        background: rgba(65, 180, 230, 0);
        transition: all .2s ease-in;

        span {
          padding-left: 3px;
        }
        &.done {
          background: #41b4e6;
          //cursor: initial;
          cursor: pointer;
        }
        &.current {
          background: #41b4e6;

          &:before {
            content: "";
            position: absolute;
            bottom: -15px;
            left: 0;
            margin: auto;
            right: 0;
            width: 24px;
            height: 24px;
            background: #fff;
            transform: scaleY(0.7) rotate(45deg);
          }
        }
      }
    }
  }
  .qnt-size-select {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: 39px;
    margin-right: -10px;

    .form-control {
      max-width: 104px;
      margin-left: 16px;
      margin-bottom: 16px;
    }
  }
  .main-content {
    margin-bottom: 6px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: height .2s ease-in;

    .tab-content {
      display: flex;
      flex-direction: column;
      min-height: 441px;
      padding: 63px 32px 32px;
      transition: opacity .2s ease-in;
      opacity: 0;

      &.thanks {
        text-align: center;
        align-items: center;
        justify-content: center;
        min-height: 488px;

        .icon {
          margin: 0 auto 50px;
          width: 40px;
          height: 40px;
          fill: #058BD8;
        }
        .heading {
          padding: 14px 0 28px;
          font-weight: bold;
          font-size: 26px;
          text-align: center;
          text-transform: uppercase;
          color: #058bd8;
        }
        .text {
          margin: 0 auto 32px;
          max-width: 310px;
        }
      }
      &:not(.active) {
        position: absolute;
        left: -9999em;
        width: 100%;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  .loading .btn {
    pointer-events: none;
    opacity: .5;
  }

  @media screen and (max-width: 767px) {
    .col-12 {
      margin: 0 0 21px;
    }
    .select-holder {
      min-height: 0;
      padding-bottom: 30px;

      &[data-height="fourt"] {
        margin-bottom: 160px;
      }
      &[data-height="three"] {
        margin-bottom: 110px;
      }
      &[data-height="two"] {
        margin-bottom: 66px;
      }
    }
    .steps {
      margin: 0 -16px;

      .label {
        display: none;
      }
    }
    .main-content {
      box-shadow: none;
      
      .tab-content {
        padding: 31px 0;
        min-height: 0;

        &.thanks {
          min-height: 528px;
        }
      }
    }
    .nav {
      .btn-link {
        display: none;
      }
    }
  }
}

.editor-type-unipark {
  .container-fluid {
    max-width: 900px;
  }
  .form-control {
    height: 48px;
    font-size:16px;
    padding: 14px 16px;
  }
  .unipark-form {
    position: relative;

    .logo-wrp {
      max-width: 180px;
    }
    .time-row {
      margin-left: -8px;
      margin-right: -8px;

      .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .continue-btn {
      width: 100%;
    }
    .loader {
      position: absolute;
      top: 0px;
      left: 0px;
      display: grid;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
  .unipark-prices-wrp {
    .zone-modal {
      img {
        width: 100%;
        height: auto;
      }
    }
    .unipark-zones {
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
          display: grid;
          grid-template-areas: "title"
                               "price";
          grid-template-columns: minmax(0, 1fr);
          row-gap: 6px;
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #eef2f6;

          .title-wrp {
            grid-area: title;
            font-weight: 600;
            font-size: 16px;

            > span {
              cursor: pointer;
            }
          }
          .price-wrp {
            grid-area: price;

            .day-price {
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
            }
            .total-price {
              font-size: 12px;
              line-height: 16px;
              color: #aab2bd;
            }
            .price-text {
              display: none;
              text-align: center;
            }
            .price-btn {
              position: relative;
              overflow: hidden;
              display: grid;
              padding: 5px 42px 5px 8px;
              color: #434e5a;
              text-decoration: none;
              border: 1px solid #00c1fc;
              border-radius: 8px;

              &:after {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                display: block;
                height: 100%;
                width: 32px;
                background-image: url('data:image/svg+xml,<svg fill="%23ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="%23ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12.8414611,12 L10.1203717,8.82539569 C9.94066062,8.61573277 9.96494139,8.30008277 10.1746043,8.1203717 C10.3842672,7.94066062 10.6999172,7.96494139 10.8796283,8.17460431 L13.8796283,11.6746043 C14.0401239,11.8618492 14.0401239,12.1381508 13.8796283,12.3253957 L10.8796283,15.8253957 C10.6999172,16.0350586 10.3842672,16.0593394 10.1746043,15.8796283 C9.96494139,15.6999172 9.94066062,15.3842672 10.1203717,15.1746043 L12.8414611,12 Z"></path></g></svg>');
                background-repeat: no-repeat;
                background-position: center;
                background-color: #00c1fc;
              }
            }
          }
          .action-wrp {
            grid-area: action;
            display: none;

            .btn {
              min-width: 160px;
              padding: 7px 12px;
              font-weight: 500;
              color: #00c1fc;

              svg {
                margin-top: -3px;
              }
            }
          }
          &:first-child {
            padding-top: 15px;
            border-top: 1px solid #eef2f6;
          }
          &.cheapest {
            .price-wrp {
              .day-price, .total-price {
                color: #4f8a10;
              }
              .price-btn {
                border-color: #4f8a10;

                &:after {
                  background-color: #4f8a10;
                }
              }
            }
            .action-wrp {
              .btn {
                color: #4f8a10 !important;
                border-color: #4f8a10 !important;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xs2) {
    .unipark-prices-wrp {
      .unipark-zones {
        ul {
          li {
            grid-template-areas: "title price";
            grid-template-columns: minmax(0, 1fr) minmax(150px, auto);
            align-items: center;
            column-gap: 12px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .unipark-form {
      .continue-btn {
        width: auto;
      }
    }
    .unipark-prices-wrp {
      .unipark-zones {
        ul {
          li {
            grid-template-areas: "title price action";
            grid-template-columns: minmax(0, 1fr) minmax(200px, 200px) auto;
            column-gap: 20px;

            .price-wrp {
              .price-text {
                display: grid;
              }
              .price-btn {
                display: none;
              }
            }
            .action-wrp {
              display: block;
            }
          }
        }
      }
    }
  }
}

.editor-type-games {
  .container-fluid {
    max-width: 450px;
  }
  .form-control {
    height: 48px;
    font-size: 16px;
    padding: 14px 16px;
  }

  .game-form-wrp {
    .actions-wrp {
      .ended {
        margin: 0px;
        padding: 20px;
        text-align: center;
      }
    }
  }
}

.editor-type-partners_offers {
  .offers-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h2 {
      margin: 0px;
      font-size: 20px;
      font-weight: 600;
      line-height: 150%;
      color: #434A54;
    }
  }
  .offers-listing {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px;

    .offer-element {
      .offer-image {
        .price-holder {
          top: 16px;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
        .offers-holder {
          right: 16px;
          top: 80px;
        }
      }
      .offer-title {}
    }
  }

  @include media-breakpoint-up(sm) {
    .offers-listing {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
      row-gap: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    .offers-listing {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}

// only for request form START
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .select2-selection__clear {
      position: relative;
    }
  }
  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      position: absolute;
      top: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 6px;
      left: 0;
      right: 0;
      white-space: nowrap;

      .select2-search.select2-search--inline {
        position: absolute;
        left: 0;
        top: -62px;
        right: 0;
        width: 100%;
      }
    }
  }
  .select2-search--inline {
    float: left;

    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      padding: 0;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}
.select2-container--open {
  .select2-dropdown {
    left: 0;
  }
  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.select2-container--default {
  .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;

    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }
    .select2-selection__placeholder {
      color: #999;
    }
  }
  .select2-selection--multiple {
    height: 56px;
    border-radius: 8px;
    border: 1px solid #888;
    cursor: text;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      margin: auto;
      bottom: 0;
      right: 16px;
      width: 24px;
      height: 24px;
      pointer-events: none;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'><path d='M12 13.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414z' fill='%23888888'/></svg>") no-repeat right center;
    }
    .select2-selection__choice {
      min-height: 35px;
      border-radius: 17.5px;
      background: #fff;
      border: 1px solid #41b4e6;
      cursor: default;
      float: left;
      word-break: break-all;
      margin-right: 8px;
      margin-top: 10px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 5px 16px;
      font-weight: normal;
      font-size: 16px;
      white-space: normal;
      text-align: left;
      color: #41b4e6;
    }
    .select2-selection__choice__remove {
      cursor: pointer;
      display: inline-block;
      margin-left: 9px;
      float: right;
      min-width: 14px;
      position: relative;
      width: 14px;
      color: transparent;
      font-size: 0;
      height: 14px;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14'><path d='M7 0a7 7 0 107 7 6.994 6.994 0 00-7-7zm3.5 9.513l-.987.987L7 7.987 4.487 10.5 3.5 9.513 6.013 7 3.5 4.487l.987-.987L7 6.013 9.513 3.5l.987.987L7.987 7z' fill='%2341b4e6'/></svg>") no-repeat;

      &:before {
        content: "";
        margin: -10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      &:hover {
        color: #333;
      }
    }
  }
  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      min-width: 100%;
      outline: 0;
      max-width: 100%;
      padding: 0 15px;
      width: 100%;
      padding-right: 50px;
      height: 56px;
      box-shadow: none;
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      color: #41B4E6;
      -webkit-appearance: textfield;
    }
  }
  .select2-results {
    > .select2-results__options {
      max-height: 288px;
      border-radius: 0 0 6px 6px;
      overflow-y: auto;
    }
  }
  .select2-results__option {
    &[role=group] {
      padding: 0;
    }
    &[aria-disabled=true] {
      color: #999;
    }
    &[aria-selected=true] {
      background-color: #058bd8;
      color: #fff;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #058bd8;
    color: #fff;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    display: none;
    color: #6a6a6a;
    padding: 10px 16px;
  }
  &.select2-container--open {
    .select2-selection--multiple {
      border-color: #058bd8;

      &:before {
        content: "";
        transform: rotate(180deg);
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'><path d='M12 13.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414z' fill='%23058bd8'/></svg>") no-repeat right center;
      }
    }
  }
  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: #058bd8;
      outline: 0;
    }
  }
  &.select2-container--disabled {
    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }
    .select2-selection__choice__remove {
      display: none;
    }
  }
}
.select2-dropdown {
  width: 343px;
  background: #fff;
  border: 1px solid #058bd8;
  box-sizing: border-box;
  margin-top: -10px;
  padding-top: 10px;
  display: block;
  border-radius: 0 0 8px 8px;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;

  &:before {
    content: "";
    border: 1px solid #058bd8;
    border-radius: 0 0 8px 8px;
    position: absolute;
    margin: 0 -1px;
    top: 2px;
    border-top: 0;
    height: 8px;
    left: 0;
    right: 0;
  }
  .select2-dropdown--above {
    border-radius: 8px 8px 0 0;
    margin-bottom: -10px;
    padding-bottom: 10px;
    margin-top: 6px;
    padding-top: 0;

    &:before {
      content: "";
      top: auto;
      border: 1px solid #058bd8;
      border-radius: 8px 8px 0 0;
      border-bottom: 0;
      bottom: -2px;
    }
    .select2-results > .select2-results__options {
      border-radius: 6px 6px 0 0;
    }
  }
}
.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  &.select2-search--hide {
    display: none;
  }
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 10px 16px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}
.datepicker_form-container {
  background-color: #fff;
  direction: ltr;
  font-size: 12px;
  left: 0;
  line-height: 30px;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0; -ms-touch-action: none;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 210px;
  z-index: -1;

  &:after, &:before {
    content: " ";
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    border: 5px solid transparent;
  }
}
.datepicker_form-dropdown {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 3px 6px #ccc;
  box-shadow: 0 3px 6px #ccc;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 1;
}
.datepicker_form-inline {
  position:static
}
.datepicker_form-top-left, .datepicker_form-top-right{
  border-top-color: #39f;
}
.datepicker_form-top-left:after,.datepicker_form-top-left:before,.datepicker_form-top-right:after,.datepicker_form-top-right:before {
  border-top: 0;
  left: 10px;
  top: -5px;
}
.datepicker_form-top-left:before, .datepicker_form-top-right:before {
  border-bottom-color: #39f;
}
.datepicker_form-top-left:after,.datepicker_form-top-right:after {
  border-bottom-color: #fff;
  top: -4px;
}
.datepicker_form-bottom-left,.datepicker_form-bottom-right {
  border-bottom-color: #39f;

  &:after, &:before {
    border-bottom: 0;
    bottom: -5px;
    left: 10px;
  }
  &:before { border-top-color: #39f; }
  &:after{ border-top-color: #fff; bottom:-4px; }
}
.datepicker_form-bottom-right:after,.datepicker_form-bottom-right:before,.datepicker_form-top-right:after,.datepicker_form-top-right:before {
  left: auto;
  right: 10px;
}
.datepicker_form-panel > ul {
  margin: 0;
  padding: 0;
  width: 102%;

  &:after, &:before{ content:" "; display:table; }
  &:after{ clear: both; };

  > li {
    background-color: #fff;
    cursor: pointer;
    float: left;
    height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 30px;

    &:hover{ background-color: #e5f2ff; }
    &.muted, &.muted:hover { color: #999; }
    &.highlighted {
      background-color: #e5f2ff;

      &:hover{ background-color: #cce5ff; }
    }
    &.picked, &.picked:hover { color: #39f; }
    &.disabled, &.disabled:hover {
      background-color: #fff;
      color: #ccc;
      cursor: default;

      &.highlighted { background-color: #e5f2ff; }
    }
    &[data-view="month next"], &[data-view="month prev"],
    &[data-view="year next"], &[data-view="year prev"], &[data-view="years next"], &[data-view="years prev"],
    &[data-view=next] {
      font-size: 18px;
    }
    &[data-view="month current"], &[data-view="year current"], [data-view="years current"] {
      width: 150px;
    }
  }
  &[data-view=months], &[data-view=years] {
    > li {
      height: 52.5px;
      line-height: 52.5px;
      width: 52.5px;
    }
  }
  &[data-view=week], &[data-view=week] {
    > li:hover {
      background-color: #fff;
      cursor: default;
    }
  }
}
.datepicker_form-hide{ display: none; }
// only for request form END