@use "sass:map";

@mixin tripadvisor_bubbles() {
  @for $i from 0 through 5 {
    &[data-rating="#{$i}"] li:nth-child(-n+#{$i}) { background-color: #4CA771; }

    @for $i2 from 1 through 10 {
      &[data-rating="#{$i}"][data-partial="#{$i2 / 10}"] li:nth-child(#{$i +  1}) {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(76,167,113,1) $i2 * 10%, rgba(255,255,255,1) $i2 * 10%, rgba(255,255,255,1) $i2 * 10%);
      }
    }
  }
}

@mixin underline($color, $invert: false) {
  color: $color;
  text-decoration: none;
  background-image: linear-gradient($color, $color);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  transition: background-size .3s;

  @if $invert {
    background-size: 100% 1px;

    &:hover, &:focus {
      background-size: 0% 1px;
    }
  } @else {
    background-size: 0% 1px;

    &:hover, &:focus {
      background-size: 100% 1px;
    }
  }
}

@mixin owl_carousel_btns() {
  width: 43px;
  height: 43px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 22px;

  span {
    &:before {
      margin: 0px;
      padding: 10px 10px 10px 9px;
      width: auto;
      font-size: 16px;
    }
  }
  &.owl-prev, &.owl-next {
    &:hover, &:focus {
      background-color: #ffffff !important;
      outline: none;
    }
  }
  &.owl-prev {
    margin-left: -21px;
  }
  &.owl-next {
    margin-right: -21px;
  }
}

@mixin round_pagination() {
  border-top: none;

  .page-item {
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;
      width: 48px;
      height: 48px;
      font-size: 16px;
      color: #293039;
      border-radius: 24px !important;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    }
    &.active {
      .page-link {
        color: #ffffff;
      }
    }
    &:first-child, &:last-child { // prev/next
      .page-link {
        font-size: 26px;
      }
    }
    &.disabled {
      .page-link {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
  }
}

@mixin popup_mobile_movement() {
  position: fixed !important;
  top: 100% !important;
  left: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  background: none;
  transform: initial !important;
  transition: all .3s;

  .top-wrp {
    position: absolute;
    bottom: 0px;
  }
  &.show {
    top: 0px !important;
    height: initial !important;
    z-index: 1000;

    & + .dropdown-bg {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 301;
      background: #000000;
      opacity: 0.3;
    }
  }
}
@mixin popup_header() {
  margin: 0px;
  padding: 32px 16px 5px 16px;
  text-align: center;
  border-bottom: 1px solid #EEF2F6;

  .header-title {
    padding-right: 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }
  .dropdown-close {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 24px;
    height: 24px;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    opacity: 1;
  }
  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 50%;
    width: 60px;
    height: 3px;
    background: #EBEBEB;
    transform: translateX(-50%);
  }
}

@mixin slide_mob_dropdown_skeleton() {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  padding: 0px;
  text-align: center;
  color: #333333;
  background: #ffffff;
  border-radius: 8px;
  border: none !important;
  overflow: hidden;

  .top-wrp {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto minmax(0, 1fr) auto;
    padding: 0px !important;
    height: 90%;
    width: 100%;
    background: #ffffff;
    border-radius: 24px 24px 0px 0px;

    .dropdown-header {
      @include popup_header()
    }
    .scroll-part {
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: #E3E8EF;
      }
      &::-webkit-scrollbar {
        width: 3px;
        background-color: #E3E8EF;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: map.get($theme-colors, 'primary');
        border-radius: 8px;
      }
    }
    .buttons-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;
      padding: 16px 16px 40px 16px;
      border-top: 1px solid #EEF2F6;

      button {
        width: 100%;
      }
    }
  }
}
@mixin slide_mob_modal_skeleton_lg() {
  .modal-dialog {
    .modal-content {
      padding: 24px;
      border-color: #CED6E0;

      .modal-header {
        position: relative;
        padding: 0px 30px 24px 0px;

        .modal-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
        }
        .close {
          position: absolute;
          top: -3px;
          right: 1px;
          font-size: 30px;
          opacity: 1;

          span {
            font-family: "Times New Roman", sans-serif !important;
          }
        }
      }
      .modal-body {
        padding: 24px 0px;
        font-size: 16px;
        line-height: 24px;
      }
      .modal-footer {
        padding: 0px;
        border: none;
      }
    }
  }
}
@mixin slide_mob_modal_skeleton() {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: initial;
    bottom: -100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .modal-content {
      display: grid;
      grid-template-rows: auto minmax(0, 1fr) auto;
      height: auto;
      overflow-y: auto;
      border-radius: 24px 24px 0px 0px;
      border-color: #CED6E0;

      .modal-header {
        position: relative;
        justify-content: center;
        padding-top: 42px;
        border-bottom: 1px solid #EEF2F6;

        .modal-title {
          position: relative;
          display: block;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          text-align: center;

          &:before {
            content: "";
            position: absolute;
            top: -27px;
            left: 50%;
            width: 60px;
            height: 3px;
            background: #EBEBEB;
            transform: translateX(-50%);
          }
        }
        .close {
          position: absolute;
          top: 15px;
          right: 19px;
          font-size: 30px;
          opacity: 1;

          span {
            font-family: "Times New Roman", sans-serif !important;
          }
        }
      }
      .modal-body {
        overflow-y: auto;
      }
      .modal-footer {
        border-top: 1px solid #EEF2F6;
      }
    }
  }
  &.show {
    .modal-dialog {
      bottom: 0;
    }
  }
}
@mixin slide_mob_dropdown_skeleton_lg() {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  margin: 12px 0px 41px 0px;
  width: 608px;
  box-shadow: 2px 2px 7px -1px rgba(0,0,0,.46);
  border-radius: 8px;

  .top-wrp {
    .buttons-wrp {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      column-gap: 16px;
      margin: 0px 16px;
      padding: 16px 0px 16px 0px;

      .day_select_btn {
        order: 2;
      }
    }
  }
  &.show {
    display: block;
  }
  &.top {
    top: initial;
    bottom: 100%;
  }
}

@mixin passangers_popup_content() {
  .people-selectors {
    padding: 8px 16px;
    text-align: left;

    .people-selector, .child-fields {
      .selector {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 32px) minmax(0, 1fr) minmax(0, 32px);
        text-align: center;

        .minus, .plus {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          font-size: 14px;
          line-height: 23px;
          border: 1px solid #E1E1E1;
          border-radius: 8px;
        }
        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          font-size: 16px;
        }
      }
    }
    .people-selector {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: minmax(0, 1fr) minmax(0, 105px);
      column-gap: 15px;
      padding: 16px 0px;

      > .texts-wrp {
        display: grid;
        row-gap: 4px;

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
        }
        .desc {
          font-size: 14px;
          line-height: 150%;
          color: #9EA7B5;
        }
      }
      &:first-child {
        border-bottom: 1px solid #EEF2F6;
      }
    }
    .rooms-text {
      padding: 20px 0px;
      border-bottom: 1px solid #EEF2F6;
    }
    .child-fields {
      padding-bottom: 18px;

      .age-wrapper {
        display: grid;
        row-gap: 8px;
        margin-top: -4px;
        margin-bottom: 26px;
        padding: 0px 0px 24px 0px;
        border-bottom: 1px solid #EEF2F6;

        .age-input {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: minmax(0, 1fr) minmax(0, 105px);
          column-gap: 15px;

          > .texts-wrp {
            display: grid;
            row-gap: 4px;

            .title {
              font-size: 14px;
              line-height: 150%;
            }
            .desc {
              font-size: 12px;
              line-height: 150%;
              color: #9EA7B5;
            }
          }
          input {
            height: 50px;

            &:focus, &:active  {
              color: map.get($airguru-colors, "orange");
              border-color: map.get($airguru-colors, "orange");
              outline: none;
              box-shadow: none;
            }
            &.child-age {
              height: 32px;
              color: #000;
              border: none;
              outline: none;
              background: transparent;
              box-shadow: none;
              text-align: center;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &:focus, &:active  {
                color: #000;
                border: none;
                outline: none;
                background: transparent;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

@mixin list_checks_circle() {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 13px;
  list-style: none;
  margin-left: 0;
  margin-bottom: 24px;

  li {
    position: relative;
    padding-left: 26px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7029 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7029 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7164 6.18045C14.9609 5.04987 13.8872 4.16868 12.631 3.64833C11.3747 3.12798 9.99238 2.99183 8.65876 3.2571C7.32514 3.52237 6.10013 4.17716 5.13864 5.13864C4.17716 6.10013 3.52238 7.32513 3.2571 8.65875C2.99183 9.99237 3.12798 11.3747 3.64833 12.6309C4.16868 13.8872 5.04987 14.9609 6.18046 15.7164C7.31105 16.4718 8.64026 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z" fill="%23058BD8"/></svg>');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}