@use "sass:map";

header {
  @extend .box-shadow;

  .desktop-header-link {
    &:hover, &.active {
      @extend .bg-white_01;
      text-decoration: none;
    }
  }

  #languages_menu {
    .dropdown {
      &.show {
        .dropdown-toggle {
          @extend .bg-white;
          text-decoration: none;

          span {
            @extend .color-blue;
          }

          svg {
            transform: rotateZ(180deg);
            filter: invert(33%) sepia(99%) saturate(1140%) hue-rotate(177deg) brightness(101%) contrast(96%);
          }
        }

        ul.dropdown-menu {
          li {
            a {
              &:hover {
                @extend .bg-blue;
                text-decoration: none;

                span {
                  @extend .color-white;
                }
              }

              span {
                @extend .color-black;
              }
            }
          }
        }
      }

      &:not(.show) {
        a {
          &:hover {
            @extend .bg-white_03;
            text-decoration: none;
          }
        }
      }

      ul.dropdown-menu {
        left: 0 !important;
      }
    }
  }

  #logo-header {
    height: rem-calc(32);
    padding-top: rem-calc(7);

    img {
      width: rem-calc(72);
    }
  }

  #logo {
    height: rem-calc(62);

    img {
      width: 30px;
    }
    .custom-logo {
      display: block;
      position: relative;

      img {
        height: 31px;
        padding-left: 2px;
        width: initial;
      }
      h1 {
        position: absolute;
        bottom: 0px;
        left: 62px;
        margin: 0px;
        padding: 0px;
        font-weight: 400;
        font-size: 7px;
        line-height: 16px;
        font-style: italic;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }

  #quick_search {
    height: 40px;

    a {
      .icon {
        &::before {
          margin: 0;
        }
      }

      .title {
        text-transform: initial;
      }
    }
  }

  #mobileHeaderContacts {
    > div {
      &.work-hours {
        span {
          align-self: center;
          padding: 4px 8px;
          font-size: 12px;
          line-height: 12px;
          background-color: #7ED957;
          border-radius: 5px;
        }
      }
      &.need-help {
        a {
          display: block;
          width: 37px;
          height: 45px;
          margin: -10px 8px;

          svg {}
        }
      }
      a {
        width: 24px;
        height: 24px;

        svg {}
      }
    }
  }
  #desktopHeaderContacts {
    .work-hours {
      span {
        align-self: center;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 12px;
        background-color: #7ED957;
        border-radius: 5px;
      }
    }
  }

  #burger-icon {
    .menu-bar {
      @extend .rounded-sm;
      @extend .bg-white;
      margin: 2px 0;
      width: 18px;
    }

    span {
      position: absolute;
      width: 10px;
      height: 10px;
      line-height: 10px;
      text-align: center;
      border-radius: 10px;
      background: #ff5353;
      color: #fff;
      font-family: Roboto, serif;
      font-size: 6px;
      font-weight: 500;
      right: -3px;
      top: -3px;
    }
  }

  @include media-breakpoint-down(md) {
    height: 56px;
    box-shadow: none !important;

    .header-block {
      height: 100%;
    }
    #logo {
      a {
        display: flex;
        text-decoration: none;

        .logo-text-mob {
          margin-left: 11px;
          font-weight: 500;
          font-size: 27px;
          line-height: 32px;

          .first-part {
            color: #C6E9F9;
          }
          .second-part {
            color: #FFFFFF;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: fixed;
    z-index: $z-index-header-footer;
    height: auto;

    .header-block {
      background-color: map.get($airguru-colors, "white");
    }

    #logo {
      img {
        width: auto;
        height: 32px;
      }
      .custom-logo {
        img {
          height: 31px;
        }
        h1 {
          left: 58px;
          bottom: 1px;
          font-size: 12px;
          color: #434A54;
          letter-spacing: -0.7px;
        }
      }
    }
  }
}

.owl-nav.owl-out-nav {
  display: none;
}