@use "sass:map";

#copyright {
  padding: 30px 0px;
  @media (min-width: map.get($grid-breakpoints, "md")) {
    border-top-color: $gray-300;
  }

  a {
    color: $black;
  }

  .copyright {
    float: left;
    text-align: left;
  }

  .credits {
    float: right;
    text-align: right;
  }

  .copyright, .credits {
    width: 50%;
    padding: 5px 15px;
    color: $black;


    //mobile xs
    @media (max-width: map.get($grid-breakpoints, "sm")) {
      width: 100%;
      float: none;
      text-align: left;
    }
  }
}
