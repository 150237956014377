@use "sass:map";

.layout_gifts_detailed {
  background-color: #F5F7FA;
}

#gift-form {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  padding: 0px 2px;

  .form-group {
    margin-bottom: 22px;

    label {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .form-control-lg {
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
    }
    textarea {
      height: 96px;
      font-size: 16px;
      line-height: 24px;
      padding: 11px 16px;
    }
  }
  .page_title {
    margin-top: 28px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  .coupon-title {
    margin: 0px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
  }
  .block-title {
    display: block;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
  }
  .gifts-swiper {
    overflow: visible;

    .swiper-wrapper {
      .swiper-slide {
        width: 201px;
      }
    }
    .navigation-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      margin-top: 8px;

      .swiper-pagination {
        position: initial;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -2px;

        .swiper-pagination-bullet {
          margin: 0px 2px;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 2px;

          &.swiper-pagination-bullet-active {
            background-color: #058BD8;
          }
        }
      }
      .buttons-wrp {
        display: grid;
        grid-auto-flow: column;
        column-gap: 12px;

        > div {
          position: initial;
          margin: 0px;
          width: 32px;
          height: 32px;
          background-color: #ffffff;
          border: 1px solid #CED6E0;
          border-radius: 8px;

          &:after {
            display: none;
          }
        }
      }
    }
  }
  .gift-form-top {
    margin-bottom: 24px;

    #gifts-container {
      [id^='coupon-item-']:not([id^='coupon-item-remove']) {
        position: relative;
        overflow: hidden;
        margin-bottom: 24px;
        padding: 16px;
        background-color: #ffffff;
        border-radius: 8px;

        .coupon-header {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          column-gap: 20px;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #EEF2F6;

          .greeting-text-field-remove {
            font-size: 14px;
            line-height: 20px;
            color: #434A54;

            svg {
              margin-right: 10px;
            }
          }
          &.wt-remove {
            grid-template-columns: minmax(0, 1fr) auto;
          }
        }
        .gift-style-container {
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #EEF2F6;

          .gift-styles-wrp {
            .gift-style-el {
              label {
                display: grid;
                grid-template-rows: minmax(0, 1fr) auto;
                row-gap: 8px;
                padding: 15px;
                background-color: #ffffff;
                border: 1px solid #E3E8EF;
                border-radius: 8px;
                transition: all .3s;

                .upper-part {
                  position: relative;
                  display: grid;
                  row-gap: 12px;
                  margin-bottom: 0px;
                  cursor: pointer;

                  img {
                    border-radius: 4px;
                  }
                  .text-wrp {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) minmax(24px, 24px);
                    column-gap: 16px;

                    .title {
                      font-size: 16px;
                      line-height: 24px;
                      font-weight: 500;
                    }
                    .checkmark {
                      position: relative;
                      width: 24px;
                      height: 24px;
                      background-color: #ffffff;
                      border: 1px solid #CED6E0;
                      border-radius: 4px;
                      transition: all .3s;

                      &:after {
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 3px;
                        display: block;
                        width: 17px;
                        height: 8px;
                        border-left: 2px solid #ffffff;
                        border-bottom: 2px solid #ffffff;
                        opacity: 0;
                        transform: rotate(-45deg);
                        transition: all .3s;
                      }
                    }
                  }
                }
                .gift-style-preview {
                  display: grid;
                  grid-auto-flow: column;
                  grid-template-columns: auto minmax(0, 1fr);
                  column-gap: 6px;
                  font-size: 14px;
                  line-height: 20px;
                  color: #434A54;
                }
              }
              input[type="radio"] {
                display: none;

                &:checked ~ label {
                  background-color: #EEF2F6;
                  border-color: #058BD8;

                  .upper-part {
                    .text-wrp {
                      .checkmark {
                        background-color: #058BD8;
                        border-color: #058BD8;

                        &:after {
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .gift-nominals-container {
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #EEF2F6;

          .gift-nominals-wrp {
            display: grid;
            row-gap: 16px;

            .gift-nominal-el {
              label {
                display: grid;
                grid-template-columns: minmax(24px, 24px) minmax(0, 1fr);
                align-items: center;
                column-gap: 16px;
                margin: 0px;
                padding: 15px;
                background-color: #F5F7FA;
                border: 1px solid #E3E8EF;
                border-radius: 8px;

                .title {
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 500;
                }
                .checkmark {
                  position: relative;
                  display: block;
                  width: 24px;
                  height: 24px;
                  background-color: #ffffff;
                  border: 1px solid #E3E8EF;
                  border-radius: 12px;
                  transition: all .3s;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    opacity: 0;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: #058BD8;
                    border-radius: 6px;
                    transition: all .3s;
                  }
                }
              }
              input[type="radio"] {
                display: none;

                &:checked ~ label {
                  border-color: #058BD8;

                  .checkmark {
                    border-color: #058BD8;

                    &:after {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
          .custom_sum_wrapper {
            margin-top: 16px;

            .form-control {
              height: 48px;
              font-size: 16px;
              border-radius: 8px;
            }
          }
        }
        .gift-greeting-container {
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #EEF2F6;
        }
        .gift-type-container {
          .gift-types-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 16px;
            column-gap: 16px;

            .gift-type-el {
              label {
                display: grid;
                grid-template-areas: "checkmark title price"
                                     "checkmark description price"
                                     "checkmark preview price";
                grid-template-columns: 24px minmax(0, 1fr) auto;
                column-gap: 16px;
                margin: 0px;
                padding: 15px;
                background-color: #F5F7FA;
                border: 1px solid #E3E8EF;
                border-radius: 8px;

                .checkmark {
                  grid-area: checkmark;
                  position: relative;
                  display: block;
                  width: 24px;
                  height: 24px;
                  background-color: #ffffff;
                  border: 1px solid #E3E8EF;
                  border-radius: 12px;
                  transition: all .3s;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    opacity: 0;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: #058BD8;
                    border-radius: 6px;
                    transition: all .3s;
                  }
                }
                .title {
                  grid-area: title;
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 500;
                }
                .price {
                  grid-area: price;
                  font-size: 20px;
                  line-height: 25px;
                  font-weight: 700;
                  text-align: right;
                }
                .desc {
                  grid-area: description;
                  font-size: 14px;
                  line-height: 21px;
                  color: #707B8B;
                }
                .gift-type-preview {
                  grid-area: preview;
                  display: grid;
                  grid-template-columns: auto minmax(0, 1fr);
                  align-items: center;
                  column-gap: 6px;
                  margin-top: 7px;
                  font-size: 14px;
                  line-height: 20px;
                  color: #434A54;
                }
              }
              input[type="radio"] {
                display: none;

                &:checked ~ label {
                  border-color: #058BD8;

                  .checkmark {
                    border-color: #058BD8;

                    &:after {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .add-gift-wrp {
      .btn {
        width: 100%;

        span {
          margin-right: 12px;
          font-size: 26px;
          line-height: 18px;
          font-weight: 600;
          vertical-align: middle;
          font-family: "Times New Roman", sans-serif !important;
        }
      }
    }
  }
  .gift-form-bottom {
    .customer-info-container {
      padding: 16px;
      background-color: #ffffff;
      border-radius: 8px;
    }
    .offer-order-btn {
      margin-top: 24px;
      width: 100%;

      svg {
        margin-left: 14px;
      }
    }
  }



  .gift-form-bottom {
    .offer-order-btn {
      //width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {}
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {
    padding: 0px;

    .page_title {
      margin-bottom: 32px;
      font-size: 30px;
      line-height: 125%;
    }
    .block-title {
      margin-bottom: 24px;
    }
    .gifts-swiper {
      .navigation-wrp {
        margin-top: 16px;
      }
    }
    .gift-form-top {
      margin-bottom: 24px;

      #gifts-container {
        [id^='coupon-item-']:not([id^='coupon-item-remove']) {
          padding: 24px;

          .gift-style-container {
            margin-bottom: 32px;
            padding-bottom: 32px;
          }
          .gift-nominals-container {
            margin-bottom: 32px;
            padding-bottom: 32px;

            .gift-nominals-wrp {
              grid-template-columns: repeat(5, minmax(0, 1fr));
              column-gap: 16px;
            }
          }
          .gift-greeting-container {
            margin-bottom: 32px;
            padding-bottom: 32px;
          }
          .gift-type-container {
            .gift-types-wrp {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }
        }
      }
      .add-gift-wrp {
        .btn {
          min-width: 256px;
          width: auto;
        }
      }
    }
    .gift-form-bottom {
      .customer-info-container {
        padding: 24px;

        .customer-fields-wrp {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 16px;
        }
        #invoice-info-container {
          .inner-wrp {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 16px;
          }
        }
      }
      .offer-order-btn {
        margin-top: 32px;
        min-width: 196px;
        width: auto;
      }
    }
  }
}

#style-modal.fade, #gift-type-modal.fade {
  .modal-dialog {
  }
  .modal-content {
    .modal-body {
      img {
        border: 1px solid #E3E8EF;
        border-radius: 8px;
      }
      .title {
        display: block;
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    @include slide_mob_modal_skeleton();

    .modal-body {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      max-width: 490px;

      &.multiple {
        max-width: 952px;
      }
    }
    .modal-content {
      width: auto;
      margin-left: auto;
      margin-right: auto;

      .modal-header {
        margin: 0px 24px;
        padding: 24px 0px;

        .modal-title {
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
        }
        .close {
          font-size: 25px;
          font-weight: 400;
          opacity: 1;
        }
      }
      .modal-body {
        padding: 24px;

        .title {
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
        }
      }
      .modal-footer {
        display: none;
      }
    }
  }
}

#gift_cart_items_old {
  margin-bottom: 29px;

  .gc-items-header-wrp {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
  }
  .elements-wrp {
    display: grid;
    row-gap: 30px;
    margin-bottom: 10px;
    padding: 22px 18px 28px 18px;
    border: 1px solid map.get($airguru-colors, "gray-border");
    border-radius: 5px;

    .element {
      display: grid;
      grid-template-areas: "photo title close"
                           "photo price price"
                           "photo qty qty"
                           "photo sum sum";
      grid-template-columns: 80px auto 20px;
      column-gap: 17px;
      row-gap: 7px;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;

      .photo-wrp {
        grid-area: photo;
      }
      .name-wrp {
        grid-area: title;
        margin-bottom: 17px;
      }
      .remove-wrp {
        grid-area: close;

        .remove-gift {
          position: relative;
          display: block;
          width: 20px;
          height: 20px;

          &:before, &:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 24px;
            width: 1px;
            background-color: #333;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
      .price-wrp, .quantity-wrp, .sum-wrp {
        display: flex;
        justify-content: space-between;

        .value {
          font-weight: 400;
        }
      }
      .price-wrp {
        grid-area: price;
      }
      .quantity-wrp {
        grid-area: qty;
      }
      .sum-wrp {
        grid-area: sum;
      }
    }
  }
  .gc-items-footer-wrp {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: right;

    span {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .gc-items-header-wrp {
      grid-template-areas: "title price qty sum close";
      grid-template-columns: auto 75px 74px 66px 80px;
      column-gap: 15px;
      margin-bottom: 6px;

      span {
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }
      }
    }
    .elements-wrp {
      margin-bottom: 23px;
      padding: 9px 20px 9px 20px;

      .element {
        grid-template-areas: "photo title price qty sum close";
        grid-template-columns: 80px auto 65px 67px 74px 43px;
        column-gap: 24px;
        align-items: center;

        .name-wrp {
          margin-bottom: 0px;
          font-size: 16px;
          line-height: 23px;
        }
        .price-wrp, .quantity-wrp, .sum-wrp {
          font-size: 14px;
          line-height: 16px;
        }
        .remove-wrp {
          margin-left: 20px;

          &:before, &:after {
            left: 0px;
          }
        }
      }
    }
    .gc-items-footer-wrp {
      span {
        &:first-child {
          margin-right: 106px;
        }
      }
    }
  }
}
.gc-checkout {
  padding: 40px 0px 21px 0px;
  background-color: #F5F7FA;

  .gc-header {
    .go-back-gift {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #434A54;

      svg {
        margin-right: 6px;
      }
    }
    h1 {
      margin-top: 0px;
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .checkout-columns {
    .block-title {
      display: block;
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
    }
    .content-column {
      .confirm-top-wrp {
        padding: 16px;
        background-color: #ffffff;
        border-radius: 8px;

        .gift-voucher-container {
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid #EEF2F6;
        }
      }
    }
    .summary-column {
      .order-info-wrp {
        .elements-wrp {
          .element {
            display: grid;
            grid-template-columns: 64px minmax(0, 1fr);
            column-gap: 16px;
            align-items: center;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #EEF2F6;

            .photo-wrp {
              img {
                border-radius: 8px;
              }
            }
            .name-wrp {
              display: grid;
              align-items: center;
              font-size: 14px;
              line-height: 21px;

              .title {
                font-weight: 500;
              }
              .name {
                color: #707B8B;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .gc-header {
      .go-back-gift {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;

        svg {
          margin-right: 16px;
          margin-left: 4px;
        }
      }
      h1 {
        margin-bottom: 32px;
        font-size: 30px;
        line-height: 125%;
      }
    }
    .checkout-columns {
      .block-title {
        margin-bottom: 24px;
      }
      .content-column {
        .confirm-top-wrp {
          padding: 24px;

          .gift-voucher-container {
            margin-bottom: 24px;
            padding-bottom: 24px;

            .fields-group-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr) auto;
              column-gap: 16px;

              .btn {
                min-width: 152px;
              }
            }
          }
        }
      }
      .summary-column {
        .order-info-wrp {
          .block-title {
            margin-bottom: 16px;
          }
          .elements-wrp {
            .element {
              grid-template-columns: 80px minmax(0, 1fr);
            }
          }
        }
      }
    }
  }
}
.gifts-order-view {
  .container-fluid {
    max-width: 752px;
  }
  .block-title {
    display: block;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
  }
  .top-block {
    margin-bottom: 16px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;

    .order-confirmation-container {
      display: grid;
      justify-items: center;
      margin-bottom: 16px;
      padding: 24px;
      text-align: center;
      background-color: #DDF3FB;
      border-radius: 8px;

      .icon-wrp {

      }
      .title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        color: #434A54;
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
        color: #535D6C;

        span {
          color: #058BD8;
          text-decoration: underline;
        }
      }
    }
    .order-info-container {
      .elements-wrp {
        .element {
          display: grid;
          grid-template-columns: 64px minmax(0, 1fr);
          column-gap: 16px;
          align-items: center;
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid #EEF2F6;

          .photo-wrp {
            img {
              border-radius: 8px;
            }
          }
          .name-wrp {
            display: grid;
            align-items: center;
            font-size: 14px;
            line-height: 21px;

            .title {
              font-weight: 500;
            }
            .name {
              color: #707B8B;
            }
          }
          .alert {
            grid-column: span 2;
            margin-top: 8px;
            margin-bottom: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
            padding-bottom: 0px;
            border-bottom: none;
          }
        }
      }
    }
  }
  #prepayment_details {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .order-pay-container {
    margin-bottom: 16px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
  }

  @include media-breakpoint-up(lg) {
    .block-title {
      margin-bottom: 24px;
    }
    .top-block {
      margin-bottom: 24px;
      padding: 24px;

      .order-confirmation-container {
        margin-bottom: 24px;
        padding: 40px;

        .title {}
        .desc {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .order-info-container {
        .elements-wrp {
          .element {
            grid-template-columns: 80px minmax(0, 1fr) 280px;

            .alert {
              grid-column: span 1;
              margin-top: 0px;
            }
          }
        }
      }
    }
    #prepayment_details {
      padding: 24px;
    }
    .order-pay-container {
      margin-bottom: 24px;
      padding: 24px;
    }
  }
}