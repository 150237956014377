@use "sass:map";

.subscribers_container {
  background: url('../../images/frontend/header-background.svg') repeat-x bottom left;
  padding-bottom: 185px;
  padding-top: 64px;

    .input-group {
      input[type='text'] {
        height: 48px;
        padding: 0 14px 0 48px;
        background: url('../../images/frontend/mail-icon.svg') no-repeat 16px center;
      }
    }
}

#subscribe_subscribe_block {
  padding-top: 40px;
  padding-bottom: 64px;
  background-color: #ffffff;

  .outer-wrp {
    padding: 40px 24px;
    border-radius: 12px;
    background-color: #DDF3FB;

    .inner-wrp {
      margin-left: auto;
      margin-right: auto;
      max-width: 728px;

      .block-title {
        display: block;
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
      }
      input[type="text"] {
        padding-left: 54px;
        height: 48px;

        &[name="email"] {
          background: url('../../images/frontend/mail-icon-2.svg') no-repeat 16px center;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
      .form-check-label {
        margin-bottom: 18px;

        .rules-checkbox {
          .checkbox-control {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .outer-wrp {
      padding: 78px 40px 66px 40px;

      .inner-wrp {
        .block-title {
          margin-bottom: 36px;
        }
        form {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(252px, 252px);
          column-gap: 16px;
          grid-template-areas: "alert alert"
                               "input button"
                               "checkbox none";

          .alert {
            grid-area: alert;
          }
          .form-group {
            grid-area: input;
          }
          .form-check-label {
            grid-area: checkbox;
          }
          .btn[type="submit"] {
            grid-area: button;
          }
        }
      }
    }
  }
}
#subscribe_subscribe_footer {
  margin-bottom: 32px;
  padding-top: 32px;

  .outer-wrp {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    .inner-wrp {
      display: grid;
      row-gap: 40px;

      .block-title {
        margin: 0px;
        font-size: 24px;
        line-height: 30px;
      }
      input[type="text"] {
        padding-left: 54px;
        height: 48px;

        &[name="email"] {
          background: url('../../images/frontend/mail-icon-2.svg') no-repeat 16px center;
        }
      }
      .form-group {
        padding: 0px 10px;
      }
      .form-check-label {
        margin-bottom: 18px;
        padding: 0px 10px;

        .rules-checkbox {
          .checkbox-control {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 42px;
    padding-top: 68px;

    .outer-wrp {
      padding-bottom: 24px;

      .inner-wrp {
        grid-template-columns: minmax(0, 443px) minmax(0, 499px);
        justify-content: space-between;
        column-gap: 40px;

        .form-group {
          padding: 0px;
        }
        .form-check-label {
          padding: 0px;
        }
        form {
          display: grid;
          grid-template-columns: minmax(0, 1fr) auto;
          column-gap: 16px;
          grid-template-areas: "alert alert"
                               "input button"
                               "checkbox none";

          .alert {
            grid-area: alert;
          }
          .form-group {
            grid-area: input;
          }
          .form-check-label {
            grid-area: checkbox;
          }
          .btn[type="submit"] {
            grid-area: button;
            min-width: 140px;
          }
        }
      }
    }
  }
}