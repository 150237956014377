// ikonu kodai pagal fontello.css
$icon-ok: '\e800';
$icon-mail: '\e801';
$icon-cancel: '\e802';
$icon-facebook: '\e803';
$icon-filter: '\e804';
$icon-instagram: '\e805';
$icon-language: '\e806';
$icon-check: '\e807';
$icon-cart: '\e808';
$icon-search: '\e809';
$icon-plus: '\e80a';
$icon-login: '\e80b';
$icon-phone: '\e80c';
$icon-delete: '\e80d';
$icon-close: '\e80e';
$icon-location: '\e80f';
$icon-youtube: '\e810';
$icon-check-empty: '\e811';
$icon-cancel-circled: '\e812';
$icon-cancel-circled-outline: '\e813';
$icon-down: '\e909';
$icon-up: '\e90a';
$icon-right: '\e802';
$icon-left: '\e801';
$icon-spin: '\e901';
$icon-check: '\e902';
$icon-close: '\e903';