@use "sass:map";

#banners_small {
  .container-fluid {
    max-width: $container-fluid-width;
    padding: 0 20px;

    #banners-small-slider {
      padding: 0;

      div.banner {
        margin: 40px 0;
        padding: 0 20px;
        text-align: center;

        .banner-image {
          overflow: hidden;
        }

        .zoom {
          position: relative;
          padding: 0;
          overflow: hidden;

          img {
            transition: transform 0.5s ease;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }

        a {
          &, &:hover {
            text-decoration: none;
          }

          color: $black;
        }

        .banner-title {
          padding: 25px 5px 5px;
          background: $white;

          p {
            padding: 5px 0;
            margin: 0;
          }

          .title {
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 500;
          }

          .desc {
            font-size: 20px;
            text-transform: uppercase;
          }

          .btn {
            margin-top: 9px;
          }
        }

        &:hover .btn {
          background: $black;
          color: $white;
        }
      }

      &:not(.owl-carousel) div.banner {
        // sm+
        @media (min-width: map.get($grid-breakpoints, "sm")) {
          float: left;
          width: 33.33%;
        }
      }
    }
  }
}