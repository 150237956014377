//== Typography
$font-family-sans-serif: Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
//$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: 'Calibre', sans-serif;
$font-family-heading: 'Calibre', sans-serif;

$font-size-base: 14px;
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h4: ceil(($font-size-base * 1.1)); // ~16px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px