@use "sass:map";

#departure-filter-wrp {
  #departureFilterBtn {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 24px auto 24px;
    align-items: center;
    -moz-column-gap: 12px;
    column-gap: 12px;
    padding: 12px 15px;
    height: 48px;
    font-size: 14px;
    line-height: 23px;
    border-radius: 8px;
    cursor: pointer;

    span {
      text-align: left;
    }
    .chevron {
      justify-self: center;
    }
  }
  .departure-filter-content {
    #departureFilter {
      @include slide_mob_dropdown_skeleton();
      border-radius: 0px;

      > .top-wrp {
        > .dropdown-header {
          padding: 20px 16px 2px;
        }

        #durations-filter-wrp {
          position: relative;
          padding: 14px 0px;

          .swiper-button {
            opacity: 1;

            &:after {
              width: 28px;
              height: 28px;
              font-size: 11px;
              line-height: 28px;
              font-weight: 600;
              color: #434A54;
              background-color: #E3E8EF;
              border-radius: 8px;
            }
            &.swiper-button-prev {
              left: 4px;
            }
            &.swiper-button-next {
              right: 4px;
            }
            &.swiper-button-disabled {
              opacity: 0;
            }
          }
          .durations-select-wrp {
            position: relative;
            display: flex;
            flex-direction: row;
            margin: 0px;
            list-style: none;

            > li {
              input[type="radio"] {
                display: none;
              }
              > label {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 6px;
                padding: 6px 15px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                text-align: center;
                white-space: nowrap;
                border-radius: 5000px;
                border: 1px solid #CED6E0;
                background: #FFF;
                transition: all .3s;
              }
              input[type="radio"]:checked + label {
                color: #ffffff;
                background-color: #55C4EA;
                border-color: #55C4EA;
              }
            }

            &::-webkit-scrollbar {
              display: none;
            }
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: #EEF2F6;
          }
        }
        #manual-duration-wrp {
          #manual-duration-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 6px;
            padding: 6px 15px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            text-align: center;
            white-space: nowrap;
            border-radius: 5000px;
            border: 1px solid #CED6E0;
            background: #FFF;
            transition: all 0.3s;

            &:after {
              flex-shrink: 0;
              margin-left: 12px;
              width: 24px;
              height: 24px;
              background: transparent;
              background-image: url('data:image/svg+xml,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 7.11832L0.5 1.11835L1.61835 0L6.5 4.88165L11.3816 0L12.5 1.11835L6.5 7.11832Z" fill="%23434A54"/></svg>');
              background-repeat: no-repeat;
              background-position: center 8px;
              background-size: 12px;
              border: 1px solid #CED6E0;
              border-radius: 8px;
            }
            &.active {
              color: #ffffff;
              border-color: #55c4ea;
              background-color: #55c4ea;

              &:after {
                background-image: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 7.11832L0 1.11835L1.11835 0L6 4.88165L10.8816 0L12 1.11835L6 7.11832Z" fill="white"/></svg>');
              }
            }
            &[aria-expanded="true"] {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
          .dropdown-menu {
            padding: 8px;
            min-width: 253px;
            border-radius: 6px;

            .top-wrp {
              .manual-duration-selectors {
                overflow-y: auto;
                max-height: 169px;

                ul {
                  list-style: none;
                  display: grid;
                  row-gap: 4px;
                  margin: 0px 8px 0px 0px;
                  padding: 0px;

                  li {
                    .manual-duration-item-label {
                      position: relative;
                      display: block;
                      margin: 0px;

                      input {
                        position: absolute;
                        opacity: 0;

                        &:checked  ~ .check-label {
                          color: #ffffff;
                          background-color: map.get($airguru-colors, "light_blue2");
                        }
                      }
                      .check-label {
                        display: block;
                        padding: 9px 12px;
                        height: 40px;
                        font-size: 14px;
                        line-height: 21px;
                        color: #434A54;
                        text-decoration: none;
                        white-space: nowrap;
                        border: 1px solid #ffffff;
                        border-radius: 6px;
                        cursor: pointer;
                        transition: all .3s;

                        &:hover {
                          color: map.get($airguru-colors, "light_blue2");
                          border-color: #CED6E0;
                        }
                      }
                    }
                  }
                }
                &::-webkit-scrollbar-track {
                  border-radius: 8px;
                  background-color: #E3E8EF;
                }
                &::-webkit-scrollbar {
                  width: 3px;
                  background-color: #E3E8EF;
                  border-radius: 8px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: map.get($theme-colors, 'primary');
                  border-radius: 8px;
                }
              }
            }
          }
        }
        #departure-picker {
          padding: 24px 10px 20px 10px;
          max-width: 378px;
          margin-left: auto;
          margin-right: auto;

          .ui-widget.ui-widget-content {
            margin-left: auto;
            margin-right: auto;
            padding: 0px;
            max-width: 764px;
            box-shadow: none;

            .ui-datepicker-header {
              padding: 0px 0px 8px 0px;

              .ui-datepicker-prev, .ui-datepicker-next {
                margin-top: -10px;
                height: 40px;
                width: 40px;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
                border-radius: 20px;
              }
              .ui-datepicker-title {
                margin: 0 3.6em;
                font-weight: 600 !important;
                font-size: 20px !important;
                line-height: 150% !important;
              }
            }
            .ui-datepicker-calendar {
              thead {
                tr {
                  display: grid;
                  grid-auto-flow: column;
                  grid-template-columns: repeat(7, minmax(46px, 1fr));
                  column-gap: 4px;

                  th {
                    padding-bottom: 5px;

                    span {
                      font-weight: 400 !important;
                      font-size: 14px !important;
                      line-height: 150% !important;
                      color: map.get($airguru-colors, "primary") !important;
                    }
                  }
                }
              }
              tbody {
                tr {
                  display: grid;
                  grid-auto-flow: column;
                  grid-template-columns: repeat(7, minmax(46px, 1fr));
                  column-gap: 4px;

                  td {
                    margin: 2px 0px;
                    padding: 0px;

                    .ui-state-default {
                      padding-bottom: 10px;
                      height: 46px;
                      width: 46px;
                      font-weight: 600 !important;
                      font-size: 14px !important;
                      border: none;
                      border-radius: 4px;
                      transition: all .3s;

                      &.ui-state-active {
                        color: map.get($airguru-colors, "orange") !important;
                        border-color: map.get($airguru-colors, "orange") !important;
                        box-shadow: 0px 0px 0px 2px map.get($airguru-colors, "orange");
                        -webkit-box-shadow: 0px 0px 0px 2px map.get($airguru-colors, "orange");
                        -moz-box-shadow: 0px 0px 0px 2px map.get($airguru-colors, "orange");
                      }
                    }

                    &.active_range {
                      color: #170312;
                      background-color: #DDF3FB;
                      border-radius: 0px;

                      .ui-state-default {
                        background-color: #DDF3FB;
                      }
                    }

                    &.range_start {
                      color: #FFFFFF !important;
                      background-color: #55C4EA !important;
                      border-radius: 8px 0px 0px 8px;

                      .ui-state-default {
                        background-color: #55C4EA;
                      }
                    }
                    &.range_end {
                      color: #FFFFFF !important;
                      background-color: #55C4EA !important;
                      border-radius: 0px 8px 8px 0px;

                      .ui-state-default {
                        background-color: #55C4EA;
                      }
                    }
                    &.sold_out {
                      background-color: rgba(255, 143, 74, .25) !important;
                      &:after {
                        content: attr(title);
                        position: relative;
                        display: block;
                        height: 1.25em;
                        margin-top: -1.25em;
                        font-size: .7em;
                        text-align: center;
                        font-weight: 700;
                      }
                      .ui-state-default {
                        background-color: rgba(255, 143, 74, .25) !important;
                      }
                    }


                    &.ui-datepicker-current-day {
                      &:after {
                        color: map.get($airguru-colors, "orange") !important;
                      }
                    }
                    &.show-price {
                      position: relative;
                      cursor: pointer;

                      &:after {
                        content: attr(title);
                        position: relative;
                        display: block;
                        height: 1.25em;
                        margin-top: -1.25em;
                        font-size: .8em;
                        text-align: center;
                      }
                    }
                    &.cheapest {
                      &:before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 0px 3px 0px 0px;
                        background: linear-gradient(45deg, rgba(255,255,255,1) 50%, rgba(58,169,102,1) 50%);
                      }
                      &:after {
                        color: #3AA966;
                      }
                    }
                    &.loading:not(.ui-state-disabled) {
                      position: relative;

                      .ui-state-default {
                        //background-color: red !important;
                      }
                      &:after {
                        content: '';
                        position: absolute;
                        bottom: 4px;
                        left: 18px;
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        border: 1.5px solid map.get($airguru-colors, "orange");
                        border-top-color: map.get($airguru-colors, "blue");
                        animation: spin 2s linear infinite;
                      }
                    }
                    &.ui-datepicker-unselectable, &.ui-state-disabled {
                      .ui-state-default {
                        position: relative;
                        overflow: hidden;
                        padding: 2px;
                        font-weight: 400 !important;

                        &:before {
                          content: "";
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          width: 150%;
                          height: 1px;
                          background-color: #E3E8EF;
                          transform: rotate(45deg) translate(-50%, -50%);
                          transform-origin: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        > .buttons-wrp {
          display: grid;
          grid-template-columns: repeat(1, minmax(0, 1fr));
          row-gap: 12px;
          border-top: 1px solid #ced6e0;

          button {
            min-width: auto;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .departure-filter-content {
      #departureFilter {
        @include popup_mobile_movement();

        > .top-wrp {
          > .dropdown-header {
            margin: 0;
            padding: 43px 16px 15px;
            text-align: center;
            border-bottom: 1px solid #eef2f6;
          }
          #durations-filter-wrp {
            .durations-select-wrp {
              overflow-x: auto;
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            }
          }
          #manual-duration-wrp {
            #manual-duration-filter {
              .dropdown-menu {
                @include popup_mobile_movement();
                top: calc(100% + 100px) !important;
                padding: 0px;
                min-width: initial;
                border-radius: 0px;

                .top-wrp {
                  .manual-duration-selectors {
                    padding: 16px;
                    max-height: initial;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    #departureFilterBtn {
      padding: 7px 10px !important;
      height: 40px !important;
      min-width: 220px;
    }
    .departure-filter-content {
      #departureFilter {
        > .top-wrp {
          #durations-filter-wrp {
            padding: 24px 24px 16px 24px;

            .durations-select-wrp {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 0px;
              margin: 0px -8px;

              > li {
                > label {
                  padding: 12px 16px;
                  //min-height: 48px;
                }
              }
            }
            &:after {
              left: 24px;
              right: 24px;
            }
          }
          #departure-picker {
            padding: 24px 24px 24px 24px;
            max-width: initial;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .departure-filter-content {
      position: relative;

      #departureFilter {
        @include slide_mob_dropdown_skeleton_lg();
        overflow: initial;
        margin: 12px 0px 90px 0px;
        min-width: 800px;

        > .top-wrp {
          > .scroll-part {
            overflow-y: initial;
          }
          #durations-filter-wrp {
            .durations-select-wrp {
              #manual-duration-wrp {
                .dropdown-menu {
                  .top-wrp {
                    .buttons-wrp {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          > .buttons-wrp {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 16px;

            #calendar-submit {
              order: 2;
            }
            .calendar-special-btn-wrp {
              order: 1;
            }
          }
        }
        &.top {
          > .top-wrp {
            grid-template-rows: minmax(0, 1fr) auto;
            //max-height: 580px;
          }
        }
      }
    }
  }
}