@use "sass:map";
$break-md-min : 992px;
$break-sm-max : 991px;
$break-lg : 992px;

$top-nav : 56px;

.higher-z-index {
  z-index: 2;
}

#air_date_from {
  padding: 0.375rem 0.75rem;
  padding-left: 43px;
  height: 48px;
  width: 100%;
  font-size: 16px;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(5, 139, 216, 0.25)
  }

  @media (min-width: $break-md-min) {
    background: url('/public/images/frontend/calendar-icon.svg') no-repeat 16px 14px #ffffff;
  }
  @media (max-width: $break-sm-max) {
    cursor: pointer;
  }
}
@media (max-width: $break-sm-max) {
  #collections_icons_index_listing,
  #collections_listing_search {
    &.extra_z_index, &.extra_z_index2 {
      z-index: 9999 !important;

      .search-form {
        z-index: 9999 !important;
      }

      .search-wrp {
        margin: 10px;
        box-shadow: none;

        & > input {
          border: 1px solid #CCD1D9 !important;
          border-radius: 8px;
          margin-bottom: 10px;
        }
      }

      .results-wrp, .card, .card-body {
        box-shadow: none !important;
        border: none !important;
        border-radius: 0 !important;
      }
    }
  }

  .destination_continue, .date_continue {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 10px 13px 21px 10px;
    z-index: 999;
    height: auto;
    max-width: calc(100% - 20px);
  }
}
.no_z_index, .no_z_index2, .no_z_index3 {
  z-index: -1 !important;
}
.air_close {
  padding: 18px 5px 18px 10px;
  font-size: 35px;
  line-height: 20px;
}

.air_destination_title {
  padding-left: 10px;
  width: calc(100% - 26px - 10px);
  text-align: left;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  font-family: Roboto, serif;
  color: #434A54;
}

.datepicker_air_div {
  .datepicker_air { // main dropdown
    @include slide_mob_dropdown_skeleton();

    .top-wrp {
      .dropdown-header {
        padding: 20px 16px 2px;
      }
      .buttons-wrp {
        grid-template-columns: minmax(0, 1fr);
        padding: 16px 16px 12px 16px;

        #duration_air {
          margin-top: 0px;

          .inner-wrp {
            padding: 0px 20px;
            border-top: none;
          }
        }
        .buttons-inner-wrp {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 12px;

          button {
            min-width: auto;
          }
        }
      }
    }
  }

  .air-calendar-wrp {
    overflow: hidden;
    padding: 19px 20px 0px 20px;

    .calendar-headers {
      position: relative;
      margin-bottom: 21px;

      .btn-nav {
        position: absolute;
        top: 50%;
        display: block;
        width: 44px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center center;
        border: none !important;
        background-color: transparent !important;
        background-size: 20px;
        transform: translateY(-50%);

        &.btn-prev {
          left: 0px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_4907_5236" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4907_5236)"><path d="M15.2981 20.9366L6.97892 12.6328C6.88532 12.5392 6.81929 12.4405 6.78084 12.3366C6.74238 12.2328 6.72314 12.1206 6.72314 12.0001C6.72314 11.8796 6.74238 11.7674 6.78084 11.6636C6.81929 11.5597 6.88532 11.461 6.97892 11.3674L15.3077 3.03856C15.4962 2.85009 15.727 2.75586 16 2.75586C16.2731 2.75586 16.5122 2.85843 16.7173 3.06356C16.9161 3.26228 17.0154 3.49624 17.0154 3.76546C17.0154 4.03469 16.9161 4.26867 16.7173 4.46738L9.18464 12.0001L16.7269 19.5424C16.9154 19.7309 17.0096 19.9591 17.0096 20.227C17.0096 20.495 16.9071 20.7315 16.7019 20.9366C16.5032 21.1353 16.2693 21.2347 16 21.2347C15.7308 21.2347 15.4968 21.1353 15.2981 20.9366Z" fill="%23434A54"/></g></svg>');
        }
        &.btn-next {
          right: 0px;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8.31178 19.6838C8.10393 19.473 8 19.2235 8 18.9354C8 18.6473 8.10393 18.3979 8.31178 18.1871L14.4018 12.0105L8.29099 5.81291C8.097 5.61616 8 5.37022 8 5.0751C8 4.77997 8.10393 4.52701 8.31178 4.31621C8.51963 4.1054 8.76559 4 9.04965 4C9.33372 4 9.57968 4.1054 9.78753 4.31621L16.7714 11.4203C16.8545 11.5046 16.9134 11.596 16.948 11.6943C16.9827 11.7927 17 11.8981 17 12.0105C17 12.123 16.9827 12.2284 16.948 12.3267C16.9134 12.4251 16.8545 12.5165 16.7714 12.6008L9.76674 19.7049C9.57275 19.9016 9.33372 20 9.04965 20C8.76559 20 8.51963 19.8946 8.31178 19.6838Z" fill="%23434A54"/></svg>');
        }
        &:focus {
          outline: none;
          color: map.get($theme-colors, 'primary');
        }
      }
      .month-names-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);

        .month_select {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
        }
      }
    }
    .calendar-tables {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .calendar-table {
        margin: 0px 6px;

        tr {
          display: grid;
          grid-template-columns: repeat(7, minmax(38px, 1fr));
          margin-bottom: 4px;

          td {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #434A54;


            input {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 46px;
              width: 100%;
              border: none;
              border-radius: 8px;
              background: transparent;

              &.disabled,&.date-unavailable {
                color: #9EA7B5;
              }
              &.active_range {
                color: #170312;
                background-color: #DDF3FB;
                border-radius: 0px;
              }
              &.active {
                color: map.get($theme-colors, 'primary');
                background-color: #F5F7FA;
              }
              &.range_start {
                color: #FFFFFF !important;
                background-color: #55C4EA !important;
                border-radius: 8px 0px 0px 8px;
              }
              &.range_end {
                color: #FFFFFF !important;
                background-color: #55C4EA !important;
                border-radius: 0px 8px 8px 0px;
              }
            }
          }
          &[class^="row-"] {
          }
        }
        thead {
          tr {
            margin-bottom: 0px;

            .dt_head {
              height: 26px;
              font-size: 14px;
              font-weight: 500;
              line-height: 150%;
              color: map.get($theme-colors, 'primary');
            }
          }
        }
      }
    }
    .days-select-wrp {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 12px;
      margin-bottom: 16px;
      text-align: left;
      border-bottom: 1px solid #EEF2F6;

      > .form-group {
        label {
          display: block;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #434A54;
        }
        input.form-control {
          height: 40px;
          font-size: 14px;
          box-shadow: none;
          //pointer-events: none;
        }
      }
      .radio-pill {
        margin-right: 16px;
        margin-bottom: 8px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  #duration_air {
    margin-top: 12px;
    position: relative;

    > .alert {
      margin: 0px 16px 16px 16px;
    }
    .inner-wrp {
      display: flex;
      flex-direction: row;
      margin: 0px -20px;
      padding: 10px 14px;
      border-top: 1px solid #EEF2F6;
      overflow-x: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      .nights-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 6px;
        padding: 6px 15px;
        height: 40px;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
        white-space: nowrap;
        border-radius: 5000px;
        border: 1px solid #CED6E0;
        background: #FFF;
        transition: all .3s;

        &.active {
          color: #ffffff;
          border-color: #55c4ea;
          background-color: #55c4ea;
        }
        &.error {
          border-color: map.get($theme-colors, 'danger');
        }
      }
      #manual-duration-wrp {
        #manual-duration-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 6px;
          padding: 6px 15px;
          height: 40px;
          font-size: 13px;
          font-weight: 400;
          line-height: 120%;
          text-align: center;
          white-space: normal;
          border-radius: 5000px;
          border: 1px solid #CED6E0;
          background: #FFF;
          transition: all 0.3s;

          &:after {
            flex-shrink: 0;
            margin-left: 12px;
            width: 24px;
            height: 24px;
            background: transparent;
            background-image: url('data:image/svg+xml,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 7.11832L0.5 1.11835L1.61835 0L6.5 4.88165L11.3816 0L12.5 1.11835L6.5 7.11832Z" fill="%23434A54"/></svg>');
            background-repeat: no-repeat;
            background-position: center 8px;
            background-size: 12px;
            border: 1px solid #CED6E0;
            border-radius: 8px;
          }
          &.active {
            color: #ffffff;
            border-color: #55c4ea;
            background-color: #55c4ea;

            &:after {
              background-image: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 7.11832L0 1.11835L1.11835 0L6 4.88165L10.8816 0L12 1.11835L6 7.11832Z" fill="white"/></svg>');
            }
          }
          &[aria-expanded="true"] {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        .dropdown-menu {
          padding: 8px;
          min-width: 253px;
          border-radius: 6px;

          .top-wrp {
            .manual-duration-selectors {
              overflow-y: auto;
              max-height: 169px;

              ul {
                list-style: none;
                display: grid;
                row-gap: 4px;
                margin: 0px 8px 0px 0px;
                padding: 0px;

                li {
                  .manual-duration-item-label {
                    position: relative;
                    display: block;
                    margin: 0px;

                    input {
                      position: absolute;
                      opacity: 0;

                      &:checked  ~ .check-label {
                        color: #ffffff;
                        background-color: map.get($airguru-colors, "light_blue2");
                      }
                    }
                    .check-label {
                      display: block;
                      padding: 9px 12px;
                      height: 40px;
                      font-size: 14px;
                      line-height: 21px;
                      color: #434A54;
                      text-decoration: none;
                      white-space: nowrap;
                      border: 1px solid #ffffff;
                      border-radius: 6px;
                      cursor: pointer;
                      transition: all .3s;

                      &:hover {
                        color: map.get($airguru-colors, "light_blue2");
                        border-color: #CED6E0;
                      }
                    }
                  }
                }
              }
              &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: #E3E8EF;
              }
              &::-webkit-scrollbar {
                width: 3px;
                background-color: #E3E8EF;
                border-radius: 8px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: map.get($theme-colors, 'primary');
                border-radius: 8px;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .swiper-button {
      opacity: 1;

      &:after {
        width: 28px;
        height: 28px;
        font-size: 11px;
        line-height: 28px;
        font-weight: 600;
        color: #434A54;
        background-color: #E3E8EF;
        border-radius: 8px;
      }
      &.swiper-button-prev {
        left: -12px;
      }
      &.swiper-button-next {
        right: -12px;
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .datepicker_air { // main dropdown
      @include popup_mobile_movement();

      .top-wrp {
        .scroll-part {
          .air-calendar-wrp {
            //padding-top: 20px;

            .days-select-wrp {
              //padding: 10px 0px 2px 0px;
            }
          }
        }
        .buttons-wrp {
          #duration_air {
            .inner-wrp {
              padding: 0px 14px;

              #manual-duration-wrp {
                #manual-duration-filter {
                  .dropdown-menu {
                    @include popup_mobile_movement();
                    top: calc(100% + 100px) !important;
                    padding: 0px;
                    min-width: initial;
                    border-radius: 0px;

                    .top-wrp {
                      .manual-duration-selectors {
                        padding: 16px;
                        max-height: initial;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: relative;

    .datepicker_air { // main dropdown
      @include slide_mob_dropdown_skeleton_lg();
      overflow: initial;
      margin: 12px 0px 90px 0px;

      .top-wrp {
        .scroll-part {
          overflow-y: initial;
        }
        .buttons-wrp {
          //grid-template-columns: repeat(2, minmax(0, 1fr));

          //.day_select_btn {
          //  order: 2;
          //}
        }
      }
      &.top {
        .top-wrp {
          grid-template-rows: minmax(0, 1fr) auto;
          max-height: 580px;
        }
      }
    }

    .air-calendar-wrp {
      overflow: initial;
      padding-top: 20px;

      .calendar-headers {
        margin-bottom: 8px;

        .btn-nav {}
        .month-names-wrp {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 24px;

          .month_select {
            font-size: 16px;
            font-weight: 700;
            line-height: 125%;
          }
        }
      }
      .calendar-tables {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 24px;

        .calendar-table {
          tr {
            grid-template-columns: repeat(7, minmax(36px, 1fr));

            td {
              height: 36px;

              input {
                height: 36px;
              }
            }
          }
        }
      }
      .days-select-wrp {
        //margin-top: 10px;
        //padding: 16px 0px 8px 0px;
        max-width: 328px;
      }
    }
    #duration_air {
      margin-top: 16px;

      .inner-wrp {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 12px;
        row-gap: 12px;
        padding: 10px 20px 16px 20px;
        overflow: initial;

        .nights-btn {
          margin: 0px;

          &:last-child {
            margin-right: 0px;
          }
        }
        #manual-duration-wrp {
          position: relative;

          #manual-duration-btn {
            margin: 0px;
          }
          .buttons-wrp {
            display: none;
          }
        }
      }
    }
  }
}