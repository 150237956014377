$container-fluid-width: 1300px !default;

$mobile-menu-height: 50px !default;
$header-height: 85px !default;


$padding-large-horizontal: 20px !default;


$border-radius-base: 2px !default;

$nav-icon-size: 30px !default;