@use "sass:map";

#pay-reservation-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 12px;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_15016_6801" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_15016_6801)"><path d="M12 22.3845L4.5 16.7595V2.5H19.5V16.7595L12 22.3845ZM12 20.5L18 16.0095V4H6V16.0095L12 20.5ZM10.95 14.6538L16.2538 9.35L15.2 8.2655L10.95 12.5155L8.83075 10.3962L7.74625 11.45L10.95 14.6538Z" fill="%23434A54"/></g></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.content_layout_checkout {
  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;

    [class*="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .form-group-wrapper {
    padding: 16px;
    color: #434A54;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;

    h2 {}
  }
  .form-control {
    padding: 6px 15px;
    height: 48px;
    font-size: 16px;
    line-height: 24px;

    &[id^="birthdate-"] {
      background-color: #ffffff;
    }
  }
  .free-reservation-text-wrp {
    display: grid;
    grid-template-columns: 20px minmax(0, 1fr);
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    p {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 21px;
      color: #707B8B;
    }
  }
  .checkout-columns {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 24px;

    .content-column {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 16px;

      .desktop-actions-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 16px;
      }
      .actions-wrp {
        .btn {
          width: 100%;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
    .summary-column {}
  }
  .modal#edit-order-modal {
    max-height: 90%;
    height: auto;

    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 30px 16px 40px 16px;

          .texts-wrp {
            display: grid;
            justify-items: center;
            text-align: center;

            p {
              color: #535D6C ;
            }
          }
        }
        .modal-footer {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 16px;
          padding: 16px 16px 40px 16px;

          .btn {
            margin: 0px;
          }
        }
      }
    }
  }
  .modal#vip-service-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          justify-content: start;

          .close {
            position: absolute;
            top: 30px;
            right: 27px;
            width: 24px;
            height: 24px;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            opacity: 1;
          }
        }
        .modal-body {
          .desc {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 24px;
            color: #535D6C;
          }
          .features-list {}
          .customers-opinion {
            font-size: 12px;
            line-height: 18px;
            color: #058BD8;
          }
        }
        .modal-footer {
          padding: 14px 16px 46px 16px;

          .price-wrp {
            margin: 0px 0px 16px 0px;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #434A54;

            span {
              font-weight: 400;
              font-size: 16px;
              color: #707B8B;
            }
          }
          .actions-wrp {
            margin: 0px 0px 0px 0px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .checkout-columns {
      .content-column {
        .desktop-actions-wrp {
          display: none;
        }
      }
    }
    .modal[id^="register-baggage-"], .modal[id^="insurance-passengers-modal-"],
    .modal[id^="single-custom-upsell-"],
    .modal[id^="trip-cancel-insurance-modal"],
    .modal[id^="trip-cancel-insurance-popup-modal"],
    .modal#sitting-together-modal, .modal#vip-service-modal,
    .modal#edit-order-modal {
      top: initial;
      bottom: 0px;
      overflow: hidden;
      height: 90%;

      .modal-dialog {
        margin: 0px;
        height: 100%;
        max-width: initial;

        .modal-content {
          display: grid;
          grid-template-rows: auto minmax(0, 1fr) auto;
          height: 100%;
          border-radius: 24px 24px 0px 0px;
          border-color: #CED6E0;

          .modal-header {
            position: relative;
            justify-content: center;
            padding-top: 42px;
            border-bottom: 1px solid #EEF2F6;

            .modal-title {
              position: relative;
              display: block;
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
              text-align: center;

              &:before {
                content: "";
                position: absolute;
                top: -27px;
                left: 50%;
                width: 60px;
                height: 3px;
                background: #EBEBEB;
                transform: translateX(-50%);
              }
            }
            .close {
              position: absolute;
              top: 30px;
              right: 27px;
              width: 24px;
              height: 24px;
              background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
              background-repeat: no-repeat;
              background-position: center;
              border: none;
              opacity: 1;
            }
          }
          .modal-body {
            overflow-y: auto;
          }
          .modal-footer {
            border-top: 1px solid #EEF2F6;

            &.wt-total {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              padding: 20px 16px 40px 16px;

              .total-row {
                display: flex;
                align-items: end;
                margin: 0px -16px 16px -16px;
                padding: 0px 16px 18px 16px;
                font-size: 14px;
                line-height: 150%;
                border-bottom: 1px solid #EEF2F6;

                .price-wrp {
                  display: flex;
                  margin-left: 5px;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 120%;
                }
              }
            }
            .actions-wrp {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              column-gap: 12px;

              .btn {
                min-width: initial;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .modal#vip-service-modal {
      .modal-dialog {
        .modal-content {
          .modal-header {
            &:before {
              content: "";
              position: absolute;
              top: 15px;
              left: 50%;
              width: 60px;
              height: 3px;
              background: #EBEBEB;
              transform: translateX(-50%);
            }
          }
          .modal-body {
            padding-top: 22px;

            .desc {}
            .features-list {}
            .customers-opinion {}
          }
          .modal-footer {
            .price-wrp {
              span {}
            }
            .actions-wrp {}
          }
        }
      }
    }
    .modal[id^="trip-cancel-insurance-modal"],
    .modal[id^="trip-cancel-insurance-popup-modal"] {
      .modal-dialog {
        .modal-content {
          .modal-header {
            justify-content: start;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #cart_detailed, #additional-services, #cart_confirm, #cart_baggage_form {
      .container-fluid {
        max-width: 1132px;
      }
    }
    .form-group-wrapper {
      padding: 24px;
    }
    .free-reservation-text-wrp {
      justify-self: end;
    }
    .checkout-columns {
      align-items: start;
      grid-template-columns: minmax(0, 1fr) minmax(0, 356px);
      column-gap: 24px;

      .content-column {
        row-gap: 24px;

        .actions-wrp {
          display: grid;
          justify-content: end;
          justify-items: end;
          row-gap: 16px;

          .btn {
            min-width: 158px;
            width: auto;
          }

          &.pay-actions-wrp {
            grid-auto-flow: column;
            column-gap: 24px;
          }
        }
      }
    }
    .modal[id^="register-baggage-"], .modal[id^="insurance-passengers-modal-"],
    .modal[id^="single-custom-upsell-"],
    .modal[id^="trip-cancel-insurance-modal"],
    .modal[id^="trip-cancel-insurance-popup-modal"],
    .modal#sitting-together-modal, .modal#vip-service-modal,
    .modal#edit-order-modal {
      .modal-dialog {
        .modal-content {
          padding: 24px;

          .modal-header {
            padding: 0px 0px 24px 0px;

            .modal-title {
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
            }
            .close {
              position: absolute;
              top: 32px;
              right: 32px;
              padding: 12px 20px;
              width: 24px;
              height: 24px;
              background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
              background-repeat: no-repeat;
              background-position: center;
              border: none;
              opacity: 1;

            }
          }
          .modal-body {
            padding: 24px 0px;
          }
          .modal-footer {
            padding: 24px 0px 0px 0px;

            &.wt-total {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .total-row {
                display: grid;
                grid-auto-flow: column;
                align-items: end;
                column-gap: 6px;
                font-size: 16px;
                line-height: 24px;

                .price-wrp {
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 30px;
                }
              }
            }
            .actions-wrp {
              display: grid;
              grid-auto-flow: column;
              column-gap: 16px;
              margin: 0px;
            }
          }
        }
      }
    }
    .modal#edit-order-modal {
      .modal-dialog {
        max-width: 494px;

        .modal-content {
          .modal-body {
            //padding: 30px 16px 40px 16px;

            .texts-wrp {
              //display: grid;
              //justify-items: center;
              //text-align: center;

              p {
                //color: #535D6C;
              }
            }
          }
          .modal-footer {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 16px;
            //padding: 16px 16px 40px 16px;

            .btn {
              min-width: auto;

              &.btn-red {
                order: 2;
              }
            }
          }
        }
      }
    }
    .modal#vip-service-modal {
      .modal-dialog {
        max-width: 494px;

        .modal-content {
          border-radius: 8px;

          .modal-header {
            .close {
              top: 40px;
              right: 31px;
            }
          }
          .modal-footer.wt-total {
            display: grid;
            grid-template-columns: minmax(0, 1fr);

            .price-wrp {
              margin-bottom: 24px;
            }
            .actions-wrp {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              column-gap: 16px;

              .btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .modal[id^="trip-cancel-insurance-popup-modal"] {
      .modal-dialog {
        max-width: 494px;

        .modal-content {
          .modal-footer {
            .total-row {
              margin-bottom: 16px;
            }
            .actions-wrp {
              grid-template-columns: repeat(2, minmax(0, 1fr));
              width: 100%;

              .btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.bday-datepicker {
  border: 1px solid #CED6E0 !important;

  .ui-datepicker-header {
    margin: 0px 16px 10px 16px !important;

    .ui-datepicker-prev, .ui-datepicker-next {
      &.ui-state-hover {
        border: none !important;
      }
    }
    .ui-datepicker-title {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
  .ui-datepicker-select-year, .ui-datepicker-select-month {
    table {
      tr {
        td {
          padding: 8px;

          .ui-state-default {
            padding: 8px 12px 8px 12px;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            background-color: #ffff;
            border: 1px solid #CED6E0;
            border-radius: 8px;
            transition: all .3s;

            &.ui-state-active {
              color: #ffffff;
              background-color: #55C4EA;
              border-color: #55C4EA;
            }
          }
          &:not(.ui-state-disabled) {
            .ui-state-default {
              &.ui-state-active {
                &:hover {
                  background-color: #058BD8;
                  border-color: #058BD8;
                }
              }
              &:hover {
                background-color: #E3E8EF;
              }
            }
          }
        }
      }
    }
  }
  .ui-datepicker-calendar {
    thead {
      th {
        padding: 2px;
      }
    }
    .ui-state-default {
      margin: auto;
      background-color: #ffff;
      border: 1px solid #CED6E0;
      transition: all .3s;

      &.ui-state-active {
        color: #ffffff;
        background-color: #55C4EA;
        border-color: #55C4EA;
      }
    }
    &:not(.ui-state-disabled) {
      .ui-state-default {
        &.ui-state-active {
          &:hover {
            background-color: #058BD8;
            border-color: #058BD8;
          }
        }
        &:hover {
          background-color: #E3E8EF;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed !important;
    top: initial !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    z-index: 1041 !important;
    padding: 0px !important;
    width: 100% !important;
    border-radius: 24px 24px 0px 0px !important;

    .fake-modal-header {
      position: relative;
      justify-content: center;
      margin-bottom: 24px;
      padding: 42px 16px 16px 16px;
      border-bottom: 1px solid #EEF2F6;

      .modal-title {
        position: relative;
        display: block;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        text-align: center;

        &:before {
          content: "";
          position: absolute;
          top: -27px;
          left: 50%;
          width: 60px;
          height: 3px;
          background: #EBEBEB;
          transform: translateX(-50%);
        }
      }
      .close {
        position: absolute;
        top: 15px;
        right: 19px;
        font-size: 30px;
        opacity: 1;
        outline: none;
      }
    }
    .ui-datepicker-select-year, .ui-datepicker-select-month {
      padding: 0px 8px 32px 8px;
    }
    .ui-datepicker-calendar {
      margin: 0px 8px 32px 8px;
      width: calc(100% - 16px);
    }
  }
  @include media-breakpoint-up(lg) {
    margin: 8px 0px !important;
    padding: 16px 8px 8px 8px !important;
    border-radius: 8px !important;
    box-shadow: 0px 10px 20px 5px #1D243012 !important;

    .ui-datepicker-header {
      margin: 0px 0px 10px 0px !important;
    }
    .ui-datepicker-calendar {
       tbody {
         td {
           .ui-state-default {
             width: 36px;
             height: 36px;
             border: none;
           }
         }
       }
    }
  }
}
.booking-summary {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;

  .order-info-wrp {
    .tags-wrp {
      display: flex;
      justify-content: start;
      margin-bottom: 12px;

      .tag {
        padding: 4px 5px 3px 5px;
        font-size: 12px;
        border-radius: 6px;
      }
    }
    .product-info {
      display: grid;
      grid-template-columns: minmax(0, 64px) minmax(0, 1fr);
      column-gap: 16px;
      align-items: center;
      margin-bottom: 20px;

      .photo-wrp {
        img {
          border-radius: 8px;
        }
      }
      .details-wrp {
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        row-gap: 2px;
        font-size: 14px;
        line-height: 21px;

        .location-title {
          font-weight: 500;
        }
        .hotel-title {
          color: #707B8B;
        }
        .hotel-stars {
          display: grid;
          grid-auto-flow: column;
          justify-content: start;
          column-gap: 2px;
        }
      }
    }
    .travel-details {
      list-style: none;
      display: grid;
      grid-auto-flow: row;
      row-gap: 12px;
      margin: 0px 0px 16px 0px;
      padding: 0px 0px 16px 0px;
      border-bottom: 1px solid #EEF2F6;

      li {
        display: flex;
        justify-content: space-between;

        .name {
          color: #707B8B;
          text-align: left;
        }
        .value {
          text-align: right;
        }
      }
    }
    .edit-btn {
      padding: 0px;
      color: #434A54;
    }
  }
  .flight-info-wrp {
    .btn-toggle-flight {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
      align-items: center;
      column-gap: 20px;
      cursor: pointer;

      svg {
        transform: rotate(180deg);
      }
    }
    .fligths-data-wrp {
      display: none;
      padding-top: 16px;

      .flight-direction-wrp {
        .direction-title {
          display: block;
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .flight-data {
          .change-time-wrp {
            position: relative;
            display: block;
            margin: 10px 0px;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            background-color: #ffffff;

            span {
              position: relative;
              z-index: 2;
              display: block;
              padding: 2px 0px;
              background-color: #ffffff;
            }
            &:before {
              content: "";
              position: absolute;
              z-index: 1;
              top: -12px;
              left: 50%;
              display: block;
              width: 6px;
              height: calc(100% + 24px);
              background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 5'><text y='0.4em' fill='gray'>·</text></svg>");;
              background-repeat: repeat-y;
              background-position: 0px 4px;
              background-size: 6px;
              transform: translateX(-50%);
            }
          }
          .single-flight-wrp {
            position: relative;
            z-index: 1;
            padding: 12px;
            border: 1px solid #E3E8EF;
            border-radius: 8px;

            .airports {
              margin-bottom: 10px;
            }
            .flight-times-wrp {
              display: grid;
              row-gap: 5px;

              .flight-time {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
                column-gap: 16px;
                align-items: center;

                .name {
                  text-align: left;
                }
                .value {
                  text-align: right;
                }
              }
            }
          }
        }
        .flight-duration {
          display: grid;
          grid-auto-flow: column;
          row-gap: 16px;
          justify-content: space-between;
          margin-top: 8px;
          font-size: 12px;
          line-height: 18px;
          color: #707B8B;

          .name {}
          .value {}
        }
        &:not(:last-child) {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #EEF2F6;
        }
      }
    }
    &.open {
      .btn-toggle-flight {
        svg {
          transform: rotate(0deg);
        }
      }
      .fligths-data-wrp {
        display: block;
      }
    }
  }
  .trip-prices-wrp {
    .prices-wrp {
      display: grid;
      row-gap: 12px;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EEF2F6;

      .price-line {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 21px;

        .name {
          margin-right: 10px;
          color: #707B8B;
        }
        .value {
          text-align: right;
          color: #434A54;
        }
      }
    }
    .total-wrp {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;

      .name {
        margin-right: 10px;
      }
    }
    .split-payment-wrp {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;
      text-align: right;

      .text {
        margin-left: 8px;
      }
    }
  }
  .vouchers-wrp {
    .toggle-button {
      padding: 2px 0px;
      text-align: center;

      .expand-btn {
        display: inline-block;
        margin-left: 4px;
      }
    }
    .voucher-form-wrp {
      display: none;
      margin-top: 10px;

      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      input.discount-code {
        height: 48px;
      }
      .form-group {
        display: grid;
        row-gap: 12px;
      }
    }
    &.expanded {
      .toggle-button {
        .expand-btn {
          transform: rotate(180deg);
        }
      }
      .voucher-form-wrp {
        display: block;
      }
    }
  }
  .mobile-actions-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 16px;

    .btn {
      width: 100%;
    }
    .actions-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 16px;
    }
  }
  .airguru-advantages-wrp {
    padding: 16px;
    background-color: #EEF2F6;
    border-radius: 8px;
  }

  @include media-breakpoint-up(lg) {
    row-gap: 24px;

    .order-info-wrp {
      .product-info {
        grid-template-columns: minmax(0, 80px) minmax(0, 1fr);
        margin-bottom: 16px;
      }
      .travel-details {
        row-gap: 13px;
      }
    }
    .flight-info-wrp {
      .fligths-data-wrp {
        .flight-direction-wrp {
          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
      }
    }
    .vouchers-wrp {
      .voucher-form-wrp {
        input.discount-code {
          height: 40px;
        }
        .form-group {
          grid-template-columns: minmax(0, 1fr) minmax(94px, auto);
          column-gap: 12px;

          .btn {
            padding: 10px;
            height: 40px;
            min-width: auto;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .mobile-actions-wrp {
      display: none;
    }
    .airguru-advantages-wrp {
      padding: 24px;
    }
  }
}

.checkout-mobile {
  .booking-info-wrapper {
    .booking-info-header {
      top: -15px;
    }
  }
}

.go-back-link {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #434A54;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0px;
    left: 0px;

    svg {
      margin-right: 12px;
    }
  }
}
.checkout-steps {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 10px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;

  .step-item {
    position: relative;
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
    align-content: start;
    row-gap: 12px;
    text-align: center;
    text-decoration: none;
    cursor: initial;

    .step-indicator {
      position: relative;
      z-index: 1;
      display: grid;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #434A54;
      background-color: #ffffff;
      border: 1px solid #CED6E0;
      border-radius: 8px;
      transition: all .3s;
    }
    .step-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #707B8B;
    }
    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        top: 16px;
        left: 50%;
        display: block;
        width: 100%;
        height: 2px;
        background-color: #E3E8EF;
      }
    }
    &.active, &.completed {
      .step-indicator {
        color: #ffffff;
        background-color: #058BD8;
        border-color: #058BD8;
      }
      .step-text {
        color: #434A54;
      }
    }
    &.active {}
    &.completed {}
  }
  &[data-steps="3"] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 38px;

    .step-item {
      row-gap: 18px;

      .step-text {
        font-size: 14px;
      }
    }
  }
}

#cart_detailed {
  .passenger-info {
    .passenger-title {
      margin-top: 0px;
      margin-bottom: 14px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .radio-container {
      margin-bottom: 0px;

      span {
        margin-right: 10px;
        margin-bottom: 8px;
        padding: 9px 12px;
        font-size: 14px;
        color: #434A54;
        border-color: #CED6E0;
        border-radius: 20px;
        transition: all .3s;

        &:hover {
          border-color: #55C4EA;
        }
      }
      input:checked ~ span {
        color: #ffffff;
        background-color: #55C4EA;
        border-color: #55C4EA;
      }
    }

    & + .passenger-info {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid #EEF2F6;
    }
  }
  .price-include-wrp, .main-travel-terms-wrp {
    ul {
      @include list_checks_circle();
    }
  }



  .checkout-footer {
    .checkout-terms {
      line-height: 1.71 !important;
      a {
        @extend .color-gray;
        text-decoration: underline;
      }
    }
  }
  .insurance-container {
    .add-insurance {
      border: 1px solid map.get($airguru-colors,'red');
      background: transparent;
      color: map.get($airguru-colors,'red');
      &:hover{
        background: #FF53530D;
      }
      &:focus{
        outline: none;
      }
      &.added {
        background: map.get($airguru-colors,'red');
        color: map.get($airguru-colors,'white');
      }
    }
  }
}
#additional-services {
  .elements-list-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 16px;

    .item-wrp {
      position: relative;
      padding: 16px;
      border: 1px solid #E3E8EF;
      border-radius: 8px;

      .tag {
        position: absolute;
        top: 0px;
        right: 16px;
        padding: 3px 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border-radius: 6px;
        transform: translateY(-50%);

        &.tag-recommend {
          color: #ffffff;
          background-color: #FF8F4A;
        }
      }
      .checkmarks-list {
        @include list_checks_circle();
        row-gap: 14px;
        margin-bottom: 14px;
      }
      .bottom-part {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        column-gap: 16px;
        padding-top: 12px;
        border-top: 1px solid #EEF2F6;

        .price-wrp {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;

          span {
            font-weight: 400;
            font-size: 14px;
            color: #707B8B;
          }
        }
        .btn-wrp {
          .btn {
            min-width: 173px;
          }
        }
        .edit-wrp {
          a {
            text-decoration: none;
            color: #434A54;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .status-wrp {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  #insurance-container {
    .tag {
      padding: 3px 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      border-radius: 6px;

      &.tag-recommend {
        color: #ffffff;
        background-color: #FF8F4A;
      }
    }
    .passengers-insurance-list-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      align-items: start;
      row-gap: 12px;

      .passenger-insurance {
        padding: 12px;
        background-color: #F5F7FA;
        border: 1px solid #E3E8EF;
        border-radius: 8px;
        transition: all .3s;

        label {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 12px;

          .top-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
            align-items: center;
            column-gap: 12px;

            .insurance-info {
              display: grid;
              grid-template-columns: minmax(0, 1fr);

              .passenger {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
              }
              .price {
                font-size: 12px;
                line-height: 18px;
                color: #707B8B;
              }
            }
            .checkbox-input {
              .checkbox-control {
                display: grid;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: #fff;
                border: 1px solid #CED6E0;
                border-radius: 4px;
                transition: all .3s;
              }
              input {
                opacity: 0;
                position: absolute;

                &:checked + .checkbox-control {
                  background-color: #058BD8;
                  border-color: #058BD8;

                  svg {
                    transform: scale(1);
                  }
                }
              }
            }
          }
        }
        &.active {
          background-color: #EEF2F6;
          border-color: #058BD8;
        }
      }
    }
    .insurances-list-wrp {
      display: block;

      .swiper {
        position: initial;
        overflow: initial;

        .swiper-wrapper {
          position: initial;
          transform: initial;

          .swiper-slide {
            height: auto;
            transform: initial;
          }
        }
      }
      .insurance-item-wrp {
        flex-shrink: 0;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr) auto;
        height: 100%;

        .top-part {
          .risks-list {
            row-gap: 12px;
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 21px;

            li {
              color: #434A54;
            }
          }
        }
        .bottom-part {
          &.mode-normal {
            grid-template-columns: minmax(0, 1fr);
            row-gap: 8px;
          }
        }
      }
    }
    .modal[id^="insurance-passengers-modal-"] {}
  }
  #sitting_together-container {
    .sitting_together_passengers {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .sitting-together-item {
        padding: 12px;
        background-color: #F5F7FA;
        border: 1px solid #E3E8EF;
        border-radius: 8px;
        transition: all .3s;

        label {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 12px;

          .top-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
            align-items: center;
            column-gap: 12px;

            .sitter-info {
              display: grid;
              grid-template-columns: minmax(0, 1fr);

              .passenger {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
              }
              .price {
                font-size: 12px;
                line-height: 18px;
                color: #707B8B;
              }
            }
            .checkbox-input {
              .checkbox-control {
                display: grid;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: #fff;
                border: 1px solid #CED6E0;
                border-radius: 4px;
                transition: all .3s;
              }
              input {
                opacity: 0;
                position: absolute;

                &:checked + .checkbox-control {
                  background-color: #058BD8;
                  border-color: #058BD8;

                  svg {
                    transform: scale(1);
                  }
                }
              }
            }
          }
        }
        &.active {
          background-color: #EEF2F6;
          border-color: #058BD8;
        }
      }
    }
  }
  #vip_service-container {
    .vip-list-wrp {
      .vip-item-wrp {
        .top-part {
          .vip-features-table {
            --last-line-radius: 0px 0px 8px 8px;

            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            align-content: center;
            row-gap: 1px;
            column-gap: 1px;
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 18px;

            .feature-name {
              padding: 12px 12px;
              box-shadow: 0 0 0 1px #E3E8EF;

              &.first {
                border-radius: 8px 0px 0px 0px;
              }
              &.last {
                border-radius: var(--last-line-radius);
              }
            }
            .feature-data {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              row-gap: 1px;
              column-gap: 1px;
              white-space: nowrap;
              text-align: center;

              > div {
                display: grid;
                align-items: center;
                justify-content: center;
                padding: 12px 6px;
                box-shadow: 0 0 0 1px #E3E8EF;

                &.vip {
                  position: relative;
                  z-index: 1;
                  margin-top: -1px;
                  background-color: #f5f7fa;
                  box-shadow:
                          0px -1px 0px 0px #E3E8EF, /* top - THE RED SHADOW */
                          0px  1px  0px  0px #E3E8EF, /* bottom */
                          1px  0px  0px  0px  #058BD8, /* right */
                          -1px  0px  0px  0px  #058BD8;
                  transform: translateY(1px);
                }
              }
              &.heading {
                font-weight: 500;

                > div {
                  padding: 15px 12px;
                  border-radius: 8px 8px 0px 0px;

                  &.vip {
                    box-shadow:
                            0px -1px 0px 0px #058BD8, /* top - THE RED SHADOW */
                            0px  1px  0px  0px #E3E8EF, /* bottom */
                            1px  0px  0px  0px  #058BD8, /* right */
                            -1px  0px  0px  0px  #058BD8;
                  }
                }
              }
              &.last {
                > div {
                  border-radius: var(--last-line-radius);

                  &.vip {
                    box-shadow:
                            0px -1px 0px 0px #E3E8EF, /* top - THE RED SHADOW */
                            0px  1px  0px  0px #058BD8, /* bottom */
                            1px  0px  0px  0px  #058BD8, /* right */
                            -1px  0px  0px  0px  #058BD8;
                  }
                }
              }
            }
          }
          .customers-opinion {
            font-size: 12px;
            line-height: 18px;
            color: #058BD8;
          }
        }
        .bottom-part {
          padding-top: 16px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #insurance-container {
      .passengers-insurance-list-wrp {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
      }
      .insurances-list-wrp {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 16px;

        .insurance-item-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: minmax(0, 1fr) auto;
          align-items: start;

          .tag {
            right: 12px;
          }
          .top-part {
            .risks-list {
              row-gap: 12px;
              margin-bottom: 12px;
              font-size: 12px;
              line-height: 18px;

              li {
                padding-left: 22px;

                &:before {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
          .bottom-part {
            grid-template-columns: minmax(0, 1fr);
            row-gap: 7px;
            padding-top: 8px;

            .btn-wrp {
              .btn {
                width: 100%;
              }
            }
          }
        }
        &.selected {
          grid-template-columns: repeat(1, minmax(0, 1fr));

          .insurance-item-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
            align-items: start;
            column-gap: 16px;

            .top-part {
              .risks-list {
                margin-bottom: 0px;
              }
            }
            .bottom-part {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: space-between;
              justify-items: end;
              padding: 0px;
              height: 100%;
              border-top: none;

              .status-wrp {
                order: 1;
              }
              .edit-wrp {
                order: 2;
              }
            }
          }
        }
      }
    }
    #sitting_together-container {
      .sitting_together_passengers {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
      }
      .sittings-list-wrp {
        .sitting-item-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
          align-items: start;
          column-gap: 16px;

          .top-part {
            .features-list {
              margin-bottom: 0px;
            }
          }
          .bottom-part {
            grid-template-columns: minmax(0, 1fr);
            justify-items: end;
            padding: 0px;
            border-top: none;

            .price-wrp {
              margin-bottom: 12px;
              text-align: right;
            }
            .btn-wrp {
              .btn {
                width: auto;
                min-width: 130px;
              }
            }
          }
        }
        &.selected {
          .sitting-item-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
            align-items: start;
            column-gap: 16px;

            .bottom-part {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: space-between;
              justify-items: end;
              padding: 0px;
              height: 100%;
              border-top: none;

              .status-wrp {
                order: 1;
              }
              .edit-wrp {
                order: 2;
              }
            }
          }
        }
      }
    }
    #vip_service-container {
      .vip-list-wrp {
        .vip-item-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(130px, auto);
          align-items: start;
          column-gap: 16px;

          .top-part {
            .vip-features-table {
              grid-template-columns: repeat(2, minmax(0, 1fr));

              .feature-name {
                padding: 10px 12px;
              }
              .feature-data {
                > div {
                  padding: 10px 6px;
                }
              }
            }
            .customers-opinion {
              margin-bottom: 0px;
            }
          }
          .bottom-part {
            grid-template-columns: minmax(0, 1fr);
            justify-items: end;
            row-gap: 12px;
            padding-top: 0px;
            text-align: end;
            border: none;

            .btn-wrp {
              .btn {
                min-width: 130px;
              }
            }
            &.mode-edit {
              height: 100%;

              .edit-wrp {
                order: 2;
                align-self: end;
              }
              .status-wrp {
                order: 1;
                align-self: start;
              }
            }
          }
        }
      }
    }
    .custom-upsell-container {
      .sitting_together_passengers {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
      }
      .sittings-list-wrp {
        .sitting-item-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
          align-items: start;
          column-gap: 16px;

          .top-part {
            .features-list {
              margin-bottom: 0px;
            }
          }
          .bottom-part {
            grid-template-columns: minmax(0, 1fr);
            justify-items: end;
            padding: 0px;
            border-top: none;

            .price-wrp {
              margin-bottom: 12px;
              text-align: right;
            }
            .btn-wrp {
              .btn {
                width: auto;
                min-width: 130px;
              }
            }
          }
        }
        &.selected {
          .sitting-item-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
            align-items: start;
            column-gap: 16px;

            .bottom-part {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: space-between;
              justify-items: end;
              padding: 0px;
              height: 100%;
              border-top: none;

              .status-wrp {
                order: 1;
              }
              .edit-wrp {
                order: 2;
              }
            }
          }
        }
      }
    }
  }
}
#cart_baggage_form {
  #baggage-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 16px;

    .baggage-items-wrp {
      display: grid;
      row-gap: 16px;

      .baggage-item-wrp {
        display: grid;
        padding: 16px;
        row-gap: 16px;
        border: 1px solid #E3E8EF;
        border-radius: 8px;

        .top-wrp {
          display: grid;
          grid-template-columns: minmax(0, 64px) minmax(0, 1fr);
          column-gap: 16px;

          .image-wrp {
            padding: 8px 12px;

            svg {
              max-width: 100%;
              height: auto;
            }
          }
        }
        .bottom-wrp {
          .price-action-wrp {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .price-wrp {
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;

              span {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #707B8B;
              }
            }
            .btn-wrp {
              .btn {
                min-width: 173px;
              }
            }
            .edit-wrp {
              a {
                display: flex;
                color: #434A54;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .modal[id^="register-baggage-"] {
      .modal-content {
        .modal-body {
          > .top-wrp {
            display: grid;
            grid-template-columns: minmax(0, 86px) minmax(0, 1fr);
            column-gap: 16px;
            margin-bottom: 16px;
            padding-top: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid #EEF2F6;

            .image-wrp {
              padding: 8px 12px;

              svg {
                max-width: 100%;
                height: auto;
              }
            }
          }

          .baggage-select-list {
            display: grid;
            row-gap: 12px;

            .passenger-baggage {
              padding: 12px;
              background-color: #F5F7FA;
              border: 1px solid #E3E8EF;
              border-radius: 8px;
              transition: all .3s;

              label {
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                row-gap: 12px;

                .top-wrp {
                  display: grid;
                  grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
                  align-items: center;
                  column-gap: 12px;

                  .baggage-info {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr);

                    .baggage {
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                    }
                    .price {
                      font-size: 12px;
                      line-height: 18px;
                      color: #707B8B;
                    }
                  }
                  .checkbox-input {
                    .checkbox-control {
                      display: grid;
                      justify-content: center;
                      align-items: center;
                      width: 24px;
                      height: 24px;
                      background-color: #fff;
                      border: 1px solid #CED6E0;
                      border-radius: 4px;
                      transition: all .3s;
                    }
                    input {
                      opacity: 0;
                      position: absolute;

                      &:checked + .checkbox-control {
                        background-color: #058BD8;
                        border-color: #058BD8;

                        svg {
                          transform: scale(1);
                        }
                      }
                    }
                  }
                }
              }
              &.active {
                background-color: #EEF2F6;
                border-color: #058BD8;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #baggage-container {
      .baggage-items-wrp {
        .baggage-item-wrp {
          grid-template-columns: minmax(0, 1fr) auto;
          column-gap: 24px;

          .top-wrp {
            grid-template-columns: minmax(0, 80px) minmax(0, 1fr);
            column-gap: 24px;

            .image-wrp {
              padding: 8px 16px;
            }
          }
          .bottom-wrp {
            .price-action-wrp {
              flex-direction: column;
              align-items: end;

              .status-wrp {
                font-weight: 500;
              }
              .edit-wrp {
                order: 2;
              }
              .price-wrp {
                margin-bottom: 12px;
              }
              .btn-wrp {
                .btn {
                  min-width: 130px;
                }
              }
              &.wt-space {
                height: 100%;
              }
            }
          }
        }
      }
      .modal[id^="register-baggage-"] {
        .modal-content {
          .modal-body {
            > .top-wrp {
              grid-template-columns: minmax(0, 80px) minmax(0, 1fr);
              column-gap: 24px;
              align-items: center;
            }
            .options-top-block {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .baggage-select-list {
              grid-template-columns: repeat(2, minmax(0, 1fr));
              column-gap: 16px;
            }
          }
        }
      }
    }
  }
}
#cart_confirm {
  #confirm-passengers-container {
    display: grid;
    grid-template-areas: "title"
                         "info"
                         "edit";
    grid-template-columns: minmax(0, 1fr);

    .block-title {
      grid-area: title;
    }
    .edit-wrp {
      grid-area: edit;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      justify-items: end;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #EEF2F6;

      .edit-link {
        display: flex;
        color: #434A54;
        text-decoration: none;
      }
    }
    .trip-passengers-info {
      grid-area: info;
    }
  }
  #trip_cancel_insurance-container {
    .tripCancel-list-wrp {
      .tripCancel-item-wrp {
        display: grid;
        padding: 14px;
        border: 1px solid #E3E8EF;
        border-radius: 8px;

        .top-part {
          .trip-insurance-table {
            --last-line-radius: 0px 0px 8px 8px;

            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            align-content: center;
            row-gap: 1px;
            column-gap: 1px;
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 18px;

            .feature-name {
              padding: 12px 12px;
              box-shadow: 0 0 0 1px #E3E8EF;

              &.first {
                border-radius: 8px 0px 0px 0px;
              }
              &.last {
                border-radius: var(--last-line-radius);
              }
            }
            .feature-data {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              row-gap: 1px;
              column-gap: 1px;
              white-space: nowrap;
              text-align: center;

              > div {
                display: grid;
                align-items: center;
                justify-content: center;
                padding: 12px 6px;
                box-shadow: 0 0 0 1px #E3E8EF;

                &.vip {
                  position: relative;
                  z-index: 1;
                  margin-top: -1px;
                  background-color: #f5f7fa;
                  box-shadow:
                          0px -1px 0px 0px #E3E8EF, /* top - THE RED SHADOW */
                          0px  1px  0px  0px #E3E8EF, /* bottom */
                          1px  0px  0px  0px  #058BD8, /* right */
                          -1px  0px  0px  0px  #058BD8;
                  transform: translateY(1px);
                }
              }
              &.heading {
                font-weight: 500;

                > div {
                  padding: 15px 12px;
                  border-radius: 8px 8px 0px 0px;

                  &.vip {
                    box-shadow:
                            0px -1px 0px 0px #058BD8, /* top - THE RED SHADOW */
                            0px  1px  0px  0px #E3E8EF, /* bottom */
                            1px  0px  0px  0px  #058BD8, /* right */
                            -1px  0px  0px  0px  #058BD8;
                  }
                }
              }
              &.last {
                > div {
                  border-radius: var(--last-line-radius);

                  &.vip {
                    box-shadow:
                            0px -1px 0px 0px #E3E8EF, /* top - THE RED SHADOW */
                            0px  1px  0px  0px #058BD8, /* bottom */
                            1px  0px  0px  0px  #058BD8, /* right */
                            -1px  0px  0px  0px  #058BD8;
                  }
                }
              }
            }
          }
        }
        .middle-part {
          display: grid;
          row-gap: 0px;
          padding-top: 16px;
          font-size: 12px;
          line-height: 18px;
          color: #058BD8;
          border-top: 1px solid #E3E8EF;

          p {
            margin-bottom: 4px;
          }
          .general-info {}
          .bta-info {
            display: flex;
            justify-content: end;
            align-items: center;
            color: #535D6C;
          }
        }
        .bottom-part {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;
          column-gap: 16px;
          margin-top: 12px;
          padding-top: 18px;
          border-top: 1px solid #E3E8EF;

          .price-wrp {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;

            span {
              font-weight: 400;
              font-size: 14px;
              color: #707B8B;
            }
          }
          .btn-wrp {
            .btn {
              min-width: 173px;
            }
          }
          .edit-wrp {
            a {
              text-decoration: none;
              color: #434A54;
              font-size: 14px;
              line-height: 20px;
            }
          }
          .status-wrp {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    #trip-cancel-insurance-modal {
      .passengers-insurance-list-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        align-items: start;
        row-gap: 12px;

        .passenger-insurance {
          padding: 12px;
          background-color: #F5F7FA;
          border: 1px solid #E3E8EF;
          border-radius: 8px;
          transition: all .3s;

          label {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 12px;

            .top-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
              align-items: center;
              column-gap: 12px;

              .insurance-info {
                display: grid;
                grid-template-columns: minmax(0, 1fr);

                .passenger {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                }
                .price {
                  font-size: 12px;
                  line-height: 18px;
                  color: #707B8B;
                }
              }
              .checkbox-input {
                .checkbox-control {
                  display: grid;
                  justify-content: center;
                  align-items: center;
                  width: 24px;
                  height: 24px;
                  background-color: #fff;
                  border: 1px solid #CED6E0;
                  border-radius: 4px;
                  transition: all .3s;
                }
                input {
                  opacity: 0;
                  position: absolute;

                  &:checked + .checkbox-control {
                    background-color: #058BD8;
                    border-color: #058BD8;

                    svg {
                      transform: scale(1);
                    }
                  }
                }
              }
            }
          }
          &.active {
            background-color: #EEF2F6;
            border-color: #058BD8;
          }
        }
      }
    }
    #trip-cancel-insurance-popup-modal {
      .modal-body {
        .general-info {
          font-size: 12px;
          line-height: 18px;
          color: #058BD8;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  #payment-summary-container {
    .leasing-container {
      padding: 16px;
      border: 1px solid #E3E8EF;
      border-radius: 8px;

      > .top-wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEF2F6;

        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        .price {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .leasing-list-wrp {
        .leasing-list {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 10px;

          .leasing-item {
            input[type="radio"] {
              display: none;

              &:checked {
                & + label {
                  background-color: #EEF2F6;
                  border-color: #058BD8;

                  .checkmark {
                    border-color: #058BD8;

                    &:after {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            label {
              display: grid;
              grid-template-columns: minmax(0, 24px) minmax(0, 1fr);
              column-gap: 16px;
              margin: 0px;
              padding: 12px;
              background-color: #F5F7FA;
              border: 1px solid #E3E8EF;
              border-radius: 8px;
              transition: all .3s;

              .checkmark {
                position: relative;
                display: block;
                width: 24px;
                height: 24px;
                background-color: #ffffff;
                border: 1px solid #E3E8EF;
                border-radius: 12px;
                transition: all .3s;

                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  display: block;
                  width: 12px;
                  height: 12px;
                  background-color: #058BD8;
                  border-radius: 6px;
                  transform: translate(-50%, -50%);
                  transition: all .3s;
                  opacity: 0;
                }
              }
              .info {
                display: grid;
                grid-template-columns: minmax(0, 1fr);

                .name {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                }
                .percentage {
                  font-size: 12px;
                  line-height: 18px;
                  color: #707B8B;
                }
              }
            }
          }
        }
        #sums-info-block {
          margin-top: 16px;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 8px;
          padding: 12px;
          background-color: #FFECF2;

          .top-wrp {
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
          }
          p {
            margin: 0px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
    .gift-voucher-container {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #EEF2F6;

      .coupon-form {
        .expand_gift_voucher {
          padding: 11px 0px;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
        }
        .gift_voucher_field {
          padding: 5px 0px 17px 0px;
        }
        .used-gift-voucher {
          list-style: none;
          margin: 0px 0px 11px 0px;
          padding: 0px;
          font-size: 14px;
          line-height: 19px;
          color: #4F8A10;

          li {
            svg {
              margin-top: -4px;
              margin-right: 8px;
            }
            span {
              font-weight: 500;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          padding: 0px 0px;
        }
      }
    }
    .order-pay-container {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #EEF2F6;
    }
  }
  .confirm-bottom-wrp {
    .form-check-label {
      align-items: start;
    }
  }

  @include media-breakpoint-up(lg) {
    #confirm-passengers-container {
      grid-template-areas: "title edit"
                           "info info";
      grid-template-columns: minmax(0, 1fr) auto;
      column-gap: 16px;
      row-gap: 24px;

      .edit-wrp {
        margin: 0px;
        padding: 0px;
        text-align: right;
        border: none;
      }
      .trip-passengers-info {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: start;
        column-gap: 24px;
        row-gap: 30px;
      }
    }
    #trip_cancel_insurance-container {
      .tripCancel-list-wrp {
        .tripCancel-item-wrp {
          grid-template-areas: "table pricing"
                               "info info";

          .top-part {
            grid-area: table;

            .trip-insurance-table {
              margin-bottom: 21px;
            }
          }
          .middle-part {
            grid-area: info;
            grid-template-columns: minmax(0, 1fr) auto;
            column-gap: 20px;
            padding-top: 20px;
          }
          .bottom-part {
            grid-area: pricing;
            grid-template-columns: minmax(0, 1fr);
            justify-items: end;
            align-content: start;
            row-gap: 12px;
            margin-top: 0px;
            padding-top: 0px;
            text-align: end;
            border: none;

            .btn-wrp {
              .btn {
                min-width: 130px;
              }
            }
            &.mode-edit {
              align-content: normal;
              padding-bottom: 16px;
              height: 100%;

              .edit-wrp {
                order: 2;
                align-self: end;
              }
              .status-wrp {
                order: 1;
                align-self: start;
              }
            }
          }
        }
      }
      #trip-cancel-insurance-modal {
        .passengers-insurance-list-wrp {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 16px;
        }
      }
    }
    #payment-summary-container {
      .leasing-container {
        > .top-wrp {
          margin-bottom: 22px;
          padding-bottom: 20px;
        }
        .leasing-list-wrp {
          .leasing-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 16px;
            row-gap: 12px;

            .leasing-item {
              label {
                padding: 16px;
              }
            }
          }
        }
      }
      .gift-voucher-container {
        margin-top: 24px;
        padding-top: 24px;

        .coupon-form {
          .fields-group-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            column-gap: 24px;

            .btn {
              min-width: 152px;
            }
          }
        }
      }
      .order-pay-container {
        margin-top: 24px;
        padding-top: 24px;
      }
    }
    .confirm-bottom-wrp {
      .form-check-label {
        grid-template-columns: minmax(0, 24px) minmax(0, 1fr);
        column-gap: 12px;

        .checkbox-input {
          .checkbox-control {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

#order_payment_block {
  .payment-option {
    .payment_list {
      .payment_type {
        margin-top: 16px;
        margin-bottom: 0px;
        padding: 0px;
        padding-top: calc(96 / 156 * 100%);
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
        overflow: hidden;

        &:not(.cms-logo) {
          img {
            top: 50%;
            left: 50%;
            width: 80%;
            transform: translate(-50%, -50%);
          }
        }
        &:hover, &.active {
          border: 1px solid map.get($airguru-colors,"blue") !important;
          box-shadow: 0px 0px 0px 1px map.get($airguru-colors,"blue");
        }
      }
    }
  }
}

#paymentMethods {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;

  .payment-option {
    display: inherit;
    grid-template-columns: inherit;
    row-gap: inherit;

    .payment-item {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto; //minmax(0, 95px);
      align-items: center;
      column-gap: 20px;
      padding: 14px 12px 16px 50px;
      background: #F5F7FA;
      border: 1px solid #E3E8EF;
      border-radius: 8px;
      cursor: pointer;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
      img {
        justify-self: end;
        height: 24px;
        width: auto;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        display: block;
        width: 24px;
        height: 24px;
        background-color: #ffffff;
        border: 1px solid #E3E8EF;
        border-radius: 12px;
        transform: translateY(-50%);
        transition: all .3s;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 16px;
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: map.get($theme-colors, 'primary');
        transform: translateY(-50%);
        transition: all .3s;
        opacity: 0;
      }
      &.active {
        border-color: map.get($theme-colors, 'primary');

        &:before {
          border-color: map.get($theme-colors, 'primary');
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .payment-option {
      .payment-item {
        padding: 18px 16px 20px 55px;

        .title {
          font-size: 16px;
          line-height: 24px;
        }
        &:before {
          left: 16px;
        }
        &:after {
          left: 22px;
        }
      }
    }
  }
}

.form-check-label {
  display: grid;
  grid-template-columns: minmax(0, 20px) minmax(0, 1fr);
  align-items: center;
  column-gap: 8px;
}
.rules-checkbox {
  font-size: 14px;
  line-height: 21px;
  
  .checkbox-input {
    .checkbox-control {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      border: 1px solid #CED6E0;

      svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
      }
    }
    input {
      opacity: 0;
      position: absolute;

      &:checked + .checkbox-control {
        background-color: #058BD8;
        border-color: #058BD8;

        svg {
          transform: scale(1);
        }
      }
    }
  }
}

#cart_additional {
  .insurance_button {
    border: 1px solid map.get($airguru-colors,'blue');
    background: transparent;
    color: map.get($airguru-colors,'blue') !important;

    &:hover { background: map.get($airguru-colors,'blue_01'); }
    &:focus { outline: none; }
    &.active {
      background: map.get($airguru-colors,'blue');
      color: map.get($airguru-colors,'white') !important;
    }
  }
  .cart-additionals-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin: 0px -10px;

    .item {
      display: grid;
      grid-template-areas: "image title"
                               "footer footer";
      grid-template-columns: 95px auto;
      column-gap: 30px;
      row-gap: 16px;
      padding: 32px 26px;
      background: #ffffff;

      > svg {
        grid-area: image;
        justify-self: self-end;
        //max-width: 70px;
        width: 100%;
        height: auto;
      }
      .title-wrp {
        grid-area: title;
        padding-top: 5px;
      }
      .price-action-wrp {
        grid-area: footer;
        display: grid;
        grid-template-columns: 95px auto;
        column-gap: 30px;
        align-items: center;

        .price-wrp {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-left: 14px;
        }
        .btn {
          justify-self: flex-start;
          min-width: 153px;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .cart-additionals-list {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 16px;
      margin: 0px;

      .item {
        grid-template-rows: 1fr auto;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .cart-additionals-list {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 32px;
      row-gap: 32px;
      margin: 0px;

      .item {
        grid-template-columns: 98px auto;
        row-gap: 14px;
        min-height: 264px;
        padding: 32px;

        .price-action-wrp {
          grid-template-columns: 98px auto;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

#cart_insurance_2 {
  .insurance-checkbox {
    .checkbox-control {
      @extend .border-light-gray;
      width: 24px;
      height: 24px;
      svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
      }
    }

    .checkbox-input {
      input {
        opacity: 0;
        position: absolute;
        &:checked + .checkbox-control {
          @extend .border-red;
          background: map.get($airguru-colors,'red');
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }

  .insurance_button {
    border: 1px solid map.get($airguru-colors,'red');
    background: transparent;
    color: map.get($airguru-colors,'red') !important;
    &:hover{
      background: #FF53530D;
    }
    &:focus{
      outline: none;
    }
    &.active {
      background: map.get($airguru-colors,'red');
      color: map.get($airguru-colors,'white') !important;
    }
  }
  .insurance_passengers {
    display:none;
    &.active {
      display:block;
    }
    input, select {
      height: 48px;
      @extend .mt-3;
    }
  }
}

#cart_covid, #cart_custom {
  .insurance-checkbox {
    .checkbox-control {
      @extend .border-light-gray;
      width: 24px;
      height: 24px;
      svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
      }
    }

    .checkbox-input {
      input {
        opacity: 0;
        position: absolute;
        &:checked + .checkbox-control {
          @extend .border-green;
          background: map.get($airguru-colors,'green');
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }

  .insurance_button {
    border: 1px solid map.get($airguru-colors,'green');
    background: transparent;
    color: map.get($airguru-colors,'green') !important;
    &:hover{
      background: #FF53530D;
    }
    &:focus{
      outline: none;
    }
    &.active {
      background: map.get($airguru-colors,'green');
      color: map.get($airguru-colors,'white') !important;
    }
  }
  .insurance_passengers {
    display:none;
    &.active {
      display:block;
    }
    input, select {
      height: 48px;
      @extend .mt-3;
    }
  }
}

.layout_bta_policy {
  @include media-breakpoint-up(md) {
    background-color: map.get($airguru-colors,'lightest-gray');
  }
}
#orders_bta_policy {
  .bta-policy-card {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 36px 25px;
    max-width: 736px;
    min-height: 345px;
    width: 100%;
    text-align: center;
    background-color: #ffffff;

    .inner-wrp {
      margin-left: auto;
      margin-right: auto;
      max-width: 310px;

      .page_title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 30px;
        line-height: 32px;
      }
      form {
        p {
          margin-bottom: 14px;
          font-size: 14px;
          line-height: 24px;
          color: #AAB2BD;
        }
        .form-group {
          margin-bottom: 25px;

          input.form-control {
            height: 48px;
            border-color: #CCD1D9;

            &::placeholder {
              text-align: center;
              color: #AAB2BD;
            }
          }
        }
        .btn {
          font-weight: 400;
          text-transform: uppercase;
        }
        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
      .policies-list {
        p {
          font-size: 16px;
          line-height: 24px;
          color: #AAB2BD;
        }
        a {
          font-weight: 500;
          color: #AAB2BD;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
        ul {
          list-style: none;
          margin: 0px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin: 80px 0px;

    .bta-policy-card {
      padding: 36px 35px;
    }
  }
}

.gf-coupon {
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #4F8A10;
  opacity: 1;
}

#cart_parking .form-control {
  height: 48px;
  font-size:16px;
  padding: 14px 16px;
}


.desktop-parking-btn {
  width:160px;
  height:36px;
  line-height:36px;
  text-align: center;
  border:1px solid #00C1FC;
  background: #ffffff;
  color: #00C1FC;
  border-radius: 8px;
  font-size: 16px;
  text-transform: uppercase;
  display:flex;
  align-items:center;
  justify-content:center;
  &.cheapest {
    border:1px solid #4F8A10;
    color: #4F8A10;
    &:hover{
      color:#fff;
      background: #4F8A10;
      text-decoration: none;
      svg {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg) brightness(200%) contrast(101%);
      }
    }
  }
  &:hover{
    color:#fff;
    background: #00C1FC;
    text-decoration: none;
    svg {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg) brightness(200%) contrast(101%);
    }
  }
}

.mobile-parking-btn {
  border:1px solid #00C1FC;
  background: #ffffff;
  border-radius: 8px;
  padding: 6px 42px 6px 8px;
  min-width:145px;
  white-space: nowrap;
  width: 100%;
  position: relative;
  &.cheapest {
    border: 1px solid #4F8A10;
    color: #4F8A10;
    .parking-arrow {
      background:#4F8A10;
    }
  }
  .parking-arrow {
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    width:33px;
    display:flex;
    background:#00C1FC;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}


.simple-order-view {
  .container-fluid {
    max-width: 752px;
  }
  .success-content-wrp {
    .block-title {
      margin-top: 0px;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #434A54;
    }

    .top-part-wrp {
      margin-bottom: 16px;
      padding: 16px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;

      .order-confirmation-container {
        margin-bottom: 16px;
        padding: 24px;
        text-align: center;
        color: #434A54;
        background-color: #DDF3FB;
        border-radius: 8px;

        .block-title {
          margin-bottom: 8px;
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
        }
        .desc {
          font-size: 16px;
          line-height: 24px;
          color: #535D6C;

          a {
            color: #058BD8;
            text-decoration: underline;
          }
        }
      }
      .pp-voucher-upsell-container {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;
        margin-bottom: 16px;
        padding: 16px;
        border: 1px solid #E3E8EF;
        border-radius: 8px;

        .pp-content-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 16px;

          .texts-wrp {
            .title {
              margin-bottom: 8px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
            .checkmarks-list {
              margin-bottom: 0px;
            }
          }
        }
      }
      .price-alert {
        margin-bottom: 16px;
      }
      .order-details-wrp {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEF2F6;

        .passengers-elements-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 16px;

          .passenger-el-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 8px;

            .passenger-title {
              font-size: 16px;
              line-height: 24px;
            }
            .passenger-data {
              list-style: none;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              row-gap: 8px;
              margin: 0px;
              padding: 0px;
            }
          }
        }
      }
      .fligths-data-wrp {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEF2F6;

        .flight-direction-wrp {
          .direction-title {
            display: block;
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
          }
          .flight-data {
            .change-time-wrp {
              position: relative;
              display: block;
              margin: 10px 0px;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              background-color: #ffffff;

              span {
                position: relative;
                z-index: 2;
                display: block;
                padding: 2px 0px;
                background-color: #ffffff;
              }
              &:before {
                content: "";
                position: absolute;
                z-index: 1;
                top: -12px;
                left: 50%;
                display: block;
                width: 6px;
                height: calc(100% + 24px);
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 5'><text y='0.4em' fill='gray'>·</text></svg>");;
                background-repeat: repeat-y;
                background-position: 0px 4px;
                background-size: 6px;
                transform: translateX(-50%);
              }
            }
            .single-flight-wrp {
              position: relative;
              z-index: 1;
              padding: 12px;
              border: 1px solid #E3E8EF;
              border-radius: 8px;

              .airports {
                margin-bottom: 10px;
              }
              .flight-times-wrp {
                display: grid;
                row-gap: 5px;

                .flight-time {
                  display: grid;
                  grid-template-columns: minmax(0, 1fr) minmax(0, 160px);
                  column-gap: 16px;
                  align-items: center;

                  .name {
                    text-align: left;
                  }
                  .value {
                    text-align: right;
                  }
                }
              }
            }
          }
          .flight-duration {
            display: grid;
            grid-auto-flow: column;
            row-gap: 16px;
            justify-content: space-between;
            margin-top: 8px;
            font-size: 12px;
            line-height: 18px;
            color: #707B8B;

            .name {}
            .value {}
          }
          &:not(:last-child) {
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #EEF2F6;
          }
        }
      }
      .offer-details-wrp {
        .offer-item-wrp {
          display: grid;
          grid-template-columns: 64px minmax(0, 1fr);
          column-gap: 16px;
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid #EEF2F6;

          .photo-wrp {
            img {
              border-radius: 8px;
            }
          }
          .texts-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 8px;

            .destination-info {
              display: grid;
              grid-template-columns: minmax(0, 1fr);

              .location-title {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #434A54;
              }
              .hotel-title {
                font-size: 14px;
                line-height: 21px;
                color: #707B8B;
              }
              .hotel-stars {
                margin-top: 8px;
              }
            }
            .hotel-link-wrp {
              a {
                color: #434A54;
                text-decoration: none;
                border-bottom: 1px solid #434A54;

                svg {
                  margin-left: 5px;
                }
                &:hover {
                  border-bottom: none;
                }
              }
            }
          }
        }
        .travel-details-wrp {
          list-style: none;
          display: grid;
          grid-auto-flow: row;
          row-gap: 12px;
          margin: 0px;
          padding: 0px;

          li {
            display: flex;
            justify-content: space-between;

            .name {
              color: #707B8B;
              text-align: left;
            }
            .value {
              text-align: right;
            }
          }
        }
      }
    }
    .bottom-part-wrp {
      margin-bottom: 16px;
      padding: 16px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;

      .prices-wrp {
        display: grid;
        row-gap: 12px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEF2F6;

        .price-line {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 21px;

          .name {
            margin-right: 10px;
            color: #707B8B;
          }
          .value {
            text-align: right;
            color: #434A54;
          }
        }
      }
      .total-wrp {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;

        .name {
          margin-right: 10px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .success-content-wrp {
      .block-title {
        margin-bottom: 24px;
      }
      .top-part-wrp {
        margin-bottom: 24px;
        padding: 24px;

        .order-confirmation-container {
          margin-bottom: 24px;
          padding: 40px;

          .icon-wrp {
            display: block;
            margin-bottom: 16px;
          }
          .block-title {
            margin-bottom: 12px;
          }
        }
        .pp-voucher-upsell-container {
          grid-template-columns: 80px minmax(0, 1fr);
          column-gap: 24px;

          > svg {
            width: 80px;
            height: 80px;
          }
          .pp-content-wrp {
            grid-template-columns: minmax(0, 1fr) minmax(130px, auto);
            column-gap: 20px;

            .texts-wrp {
              .title {
                margin-bottom: 12px;
              }
              .checkmarks-list {
                row-gap: 9px;
              }
            }
          }
        }
        .price-alert {
          margin-bottom: 24px;
        }
        .order-details-wrp {
          margin-bottom: 24px;
          padding-bottom: 24px;

          .passengers-elements-wrp {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: start;
            column-gap: 24px;
          }
        }
        .fligths-data-wrp {
          margin-bottom: 24px;
          padding-bottom: 24px;

          .flight-direction-wrp {
            &:not(:last-child) {
              margin-bottom: 16px;
              padding-bottom: 16px;
            }
          }
        }
        .offer-details-wrp {
          .offer-item-wrp {
            grid-template-columns: 80px minmax(0, 1fr);
            margin-bottom: 24px;
            padding-bottom: 24px;

            .texts-wrp {
              grid-template-columns: minmax(0, 1fr) auto;
              column-gap: 24px;
              align-items: center;
            }
          }
        }
      }
      .bottom-part-wrp {
        padding: 24px;
      }
    }
  }
}

.reservation-details-wrp {
  background-color: red;

  .reservation-top-wrp {

  }
}


.custom-upsell-container {
  .sitting_together_passengers {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 12px;

    .sitting-together-item {
      padding: 12px;
      background-color: #F5F7FA;
      border: 1px solid #E3E8EF;
      border-radius: 8px;
      transition: all .3s;

      label {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 12px;

        .top-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 24px);
          align-items: center;
          column-gap: 12px;

          .sitter-info {
            display: grid;
            grid-template-columns: minmax(0, 1fr);

            .passenger {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
            }
            .price {
              font-size: 12px;
              line-height: 18px;
              color: #707B8B;
            }
          }
          .checkbox-input {
            .checkbox-control {
              display: grid;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              background-color: #fff;
              border: 1px solid #CED6E0;
              border-radius: 4px;
              transition: all .3s;
            }
            input {
              opacity: 0;
              position: absolute;

              &:checked + .checkbox-control {
                background-color: #058BD8;
                border-color: #058BD8;

                svg {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
      &.active {
        background-color: #EEF2F6;
        border-color: #058BD8;
      }
    }
  }
}