@use "sass:map";

#banners_small {
  background: transparent;

  #banners-small-slider {
    max-width: 100%;

    .item {
      .banner_img {
        padding-top: 66.66666%; // 1200x500
        // sm+
        @media (min-width: map.get($grid-breakpoints, "sm")) {
          padding-top: 40%;
        }

        .banner_picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .title_wrp {
        @include full_absolute_block();

        .vcenter {
          display: block;
          height: 100%;
          padding-left: 26px;
          padding-right: 26px;
        }

        .banner_content {
          margin: 26px 11px;
          vertical-align: middle;
          width: 100%;
          h1 {
            white-space: normal;
          }
        }

        .title {
          @extend .f-44;
          @extend .fw-bold;
          @extend .color-white;
          line-height: 1.1 !important;
        }

        .button {
          @extend .position-absolute;
          @extend .d-block;
          @extend .p-3;
          @extend .rounded;
          @extend .box-shadow;
          @extend .bg-pink;
          @extend .f-16;
          @extend .fw-500;
          @extend .color-white;
          @extend .text-center;

          bottom: 26px;
          left: 26px;
          min-width:214px;
        }

        .desc {
          // all desktop (md, lg)
          @media (min-width: map.get($grid-breakpoints, "md")) {
            font-size: 1vw;
          }
          margin: 20px 0;
        }

        .btn {
          text-transform: uppercase;
        }

        .title, .desc, .btn {
          margin: 10px 0;
        }
      }
    }

    &:not(.owl-container) {
      .carousel-inner-x {
        width: 1000vw;

        .item {
          width: 100vw;
          float: left;
        }
      }
    }

    .carousel-inner-x {
      @extend .p-0;
    }
  }
}