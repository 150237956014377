@use "sass:map";

@mixin menu-horizontal() {
  @include clearfix();
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    float: left;
    white-space: nowrap;
    margin-left: 20px;
    line-height: 2em;

    &:first-child {
      margin-left: 0;
    }

    > a {
      @include link-style-menu();
      display: block;
    }
  }
}

@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin fontello-icon($content) {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  //speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  line-height: inherit;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  content: $content;
}

@mixin full_absolute_block() {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin link-default() {
  cursor: pointer;
  //color: $gray-600;
  //text-decoration: underline;
  &:hover {
    //color: theme-color();
    //text-decoration: none;
  }
}

@mixin link-primary() {
  cursor: pointer;
  color: theme-color();
  &:hover {
    color: theme-color();
    text-decoration: underline;
  }
}

@mixin link-quiet() {
  cursor: pointer;
  color: $gray-600;
  text-decoration: none;
  &:hover {
    color: $gray-800;
  }
}

@mixin link-style-menu() {
  color: $gray-700;
  text-decoration: none;
  &:hover {
    color: $gray-900;
  }
}

@mixin link-style-mobile-menu() {
  @include mobile-menu-item();
}

@mixin list-collapse() {
  > .title {
    cursor: pointer;
    @include unselectable();
    position: relative;
    padding-right: 30px;

    &:after {
      @include fontello-icon($icon-down);
      position: absolute;
    }
  }
  .title + * {
    display: none;
  }
  &.open {
    > .title {
      &:after {
        @include fontello-icon($icon-up);
      }

      & + * {
        display: block;
      }
    }
  }
}

@mixin list-collapse-mobile() {
  @include list-collapse();
  .title, li > a:not(.keep-as-desktop) {
    @include mobile-menu-item();
  }
  > .title {
    padding-right: 40px;

    &:after {
      top: 10px;
      right: 10px;
      font-size: 18px;
      @include fontello-icon($icon-right);
    }
  }
  &.open {
    > .title {
      &:after {
        @include fontello-icon($icon-down);
      }
    }
  }

  ul {
    // išsiskleidžiantis meniu
    margin: 0;
    position: static;
    list-style: none;
    padding: 0;
    background: $gray-100;
    // numusam bootstrap stilius, jeigu naudojamas .dropdown-menu
    &.dropdown-menu {
      position: static;
      float: none;
      box-shadow: none;
      border: none;
    }

    // meniu punktai
    .title {
      color: $black;
    }

    .title, a {
      padding-left: 20px;
      border-bottom: 1px solid $gray-400;

      &:hover {
        background: $gray-100;
      }
    }

    // numusam list-default stiliu
    .list-default > li {
      color: #123456;
      padding-left: 0;

      &:before {
        display: none;
      }
    }

  }

  & & ul {
    // 2 lygio išsiskleidžiantis meniu
    background: $gray-100;

    // 2 lygio meniu punktai
    .title, a {
      padding-left: 30px;
      border-bottom: 1px solid $gray-500;

      &:hover {
        background: $gray-200;
      }
    }
  }

  & & & ul {
    // 2 lygio išsiskleidžiantis meniu
    background: $gray-200;

    // 2 lygio meniu punktai
    .title, a {
      padding-left: 45px;
      border-bottom: 1px solid $gray-500;

      &:hover {
        background: $gray-200;
      }
    }
  }
}

@mixin mobile-menu-item() {
  line-height: 20px;
  margin: 0;
  display: block;
  text-decoration: none;
  color: $gray-700;
  padding: 10px;
  border-bottom: 1px solid $gray-300;
  &:hover {
    background: $gray-100;
  }
  > h2, > h3, > h4, > h5 {
    margin: 0;
  }
  > .check {
    margin-top: 2px;
  }
  @media (max-width: map.get($grid-breakpoints, "lg")) {
    background-color: $white;
    &:hover {
      background-color: $white;
    }
  }
}

@mixin list-dropdown() {
  @include clearfix();
  position: relative;
  > .title {
    cursor: pointer;
    @include link-style-menu();
    position: relative;
    padding-right: 20px;
    @include unselectable();

    &:after {
      @include fontello-icon($icon-down);
      position: absolute;
    }
  }
  &.no-carret > .title {
    padding-right: 0;

    &:after {
      display: none;
    }
  }
  > .title + ul,
  > .title + div {
    position: absolute;
    top: auto;
    left: 0px;

    &[data-dropdown-align="right"] {
      left: auto;
      right: 0;
    }

    visibility: hidden;
    margin: 0;
    opacity: 0;
    display: block;
    transition: opacity 0.3s, visibility 0.3s;
  }
  > ul, .submenu_list > ul {
    // pagal bootstrap/dropdowns.less: .dropdown-menu
    z-index: $zindex-dropdown;
    float: left;
    padding: 5px 0;
    list-style: none; /*xxx*/
    //font-size: $font-size-base;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: $dropdown-bg;
    border: 1px solid $dropdown-border-color; // IE8 fallback
    border: 1px solid $dropdown-border-color;
    border-radius: $border-radius-base;
    @include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
    background-clip: padding-box;

    .divider {
      @include nav-divider($dropdown-divider-bg);
      margin: 5px 0;
    }
  }

  // pagal boostrap/dropdowns.less: .dropdown-menu > li > a
  & & > .title, & > ul > li > a, & .submenu_list > ul > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    &:hover {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }

  &.hover {
    > .title + * {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
  & & {
    > .title {
      padding-right: 35px;

      &:after {
        @include fontello-icon($icon-right);
        position: absolute;
        top: 0;
        right: 10px;
        padding: 3px 0;
      }
    }

    &.hover {
      > ul {
        left: 100%;
        top: -6px;
      }
    }
  }
}

@mixin li-checkbox() {
  position: relative;
  padding-left: 20px;
  margin: 0.5em 0;
  line-height: 1.2em;
  > span {
    cursor: pointer;
  }
  &.count0 {
    color: $gray-400 !important;
  }
  > span.check {
    position: absolute;
    width: 1em;
    height: 1em;
    margin-left: -20px;
    border: 1px solid $black;
  }
  &.active > span.check {
    background: $black;
    color: $white;

    &:before {
      @include fontello-icon($icon-check);
      margin: 0;
      vertical-align: top;
      line-height: 1em;
    }
  }
  //> span.check:before {
  //	.fontello-icon($icon-check-empty);
  //	position: absolute;
  //	left: 0;
  //	cursor: pointer;
  //}
  //&.active > span.check:before {
  //	.fontello-icon($icon-check);
  //}
  //&.childs-active > span.check:before {
  //	.fontello-icon($icon-check);
  //	color:$gray-600;
  //}
  .count {
    margin-left: 5px;
    font-size: 10px;
    color: $gray-600;
  }
}

@mixin border-radius($radius: 2px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-custom($topleft: 2px, $topright: 2px, $bottomright: 2px, $bottomleft: 2px) {
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
}

@mixin rotate($degrees: 90deg) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin placeholder_color($color) {
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: $color;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color;
  }
}

@mixin content-box() {
  background: $white;
  border: 1px solid $color-lines;
  padding: 30px 30px 15px 30px;
  @media (max-width: 320px) {
    padding: 20px 20px 10px 20px;
  }
  margin: 20px 0;
  @include clearfix();
  //padding: 15px;
  > *:first-child,
  > *:first-child > *:first-child,
  > *:first-child > *:first-child > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 15px;
  }
}

@mixin absolute-full-width() {
  top: 0;
  left: 0;
  width: 100%;
}

@mixin thin() {
  font-weight: 100;
}

@mixin light() {
  font-weight: 300;
}

@mixin normal() {
  font-weight: normal;
}

@mixin medium() {
  font-weight: 500;
}

@mixin semibold() {
  font-weight: 600;
}

@mixin bold() {
  font-weight: bold;
}

@mixin extrabold() {
  font-weight: 800;
}

@mixin black() {
  font-weight: 900;
}

@mixin nav-divider($color: $gray-200) {
  height: 1px;
  margin: (($line-height-base / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}