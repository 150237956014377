@use "sass:map";

.content_layout_news {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page_title {
    font-weight: 600;
    font-size: 26px;
    line-height: 29px;
    color: #434a54;
  }
  @include media-breakpoint-up(lg) {
    .page_title {
      font-size: 40px;
      line-height: 43px;
    }
  }
}

.news-navigation {
  background-color: #ffffff;
  border-radius: 8px;

  .inner-wrp {
    display: grid;
    grid-auto-flow: row;
    padding: 16px;

    .categories-wrp {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #EEF2F6;

      > label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }
      .categories-select-wrp {
        .categories-btn {
          position: relative;
          overflow: hidden;
          padding: 9px 44px 9px 39px;
          height: 40px;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          text-align: left;
          color: #434A54;
          white-space: nowrap;
          text-overflow: ellipsis;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_4907_903" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4907_903)"><path d="M12.0017 11.8654C12.4992 11.8654 12.9246 11.6882 13.2778 11.3339C13.631 10.9795 13.8076 10.5536 13.8076 10.056C13.8076 9.55839 13.6305 9.133 13.2761 8.7798C12.9218 8.4266 12.4958 8.25 11.9983 8.25C11.5007 8.25 11.0753 8.42717 10.7221 8.7815C10.3689 9.13583 10.1923 9.56179 10.1923 10.0594C10.1923 10.557 10.3694 10.9824 10.7238 11.3356C11.0781 11.6888 11.5041 11.8654 12.0017 11.8654ZM12 19.5135C13.9564 17.7622 15.4535 16.0824 16.4913 14.474C17.5291 12.8657 18.048 11.457 18.048 10.2481C18.048 8.42498 17.4689 6.92627 16.3105 5.7519C15.1522 4.57753 13.7153 3.99035 12 3.99035C10.2846 3.99035 8.84771 4.57753 7.68938 5.7519C6.53105 6.92627 5.95188 8.42498 5.95188 10.2481C5.95188 11.457 6.47079 12.8657 7.5086 14.474C8.54644 16.0824 10.0436 17.7622 12 19.5135ZM12 21.5096C9.4833 19.3288 7.59613 17.2993 6.33843 15.4211C5.08075 13.5429 4.4519 11.8186 4.4519 10.2481C4.4519 7.94038 5.19838 6.07213 6.69133 4.64328C8.18426 3.21443 9.9538 2.5 12 2.5C14.0461 2.5 15.8156 3.21443 17.3086 4.64328C18.8015 6.07213 19.548 7.94038 19.548 10.2481C19.548 11.8186 18.9192 13.5429 17.6615 15.4211C16.4038 17.2993 14.5166 19.3288 12 21.5096Z" fill="%23434A54"/></g></svg>');
          background-size: 20px;
          background-position: 11px center;
          background-color: #ffffff;
          background-repeat: no-repeat;
          border-radius: 8px;
          border: 1px solid #CED6E0;
          transition: all .3s;

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:after {
            content: "";
            position: absolute;
            top: 16px;
            right: 18px;
            display: block;
            width: 8px;
            height: 8px;
            border-top: none;
            border-right: none;
            border-left: 2px solid #434A54;
            border-bottom: 2px solid #434A54;
            transform-origin: center;
            transform: rotate(-45deg);
            transition: all .3s;
          }
          &:focus {
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgba(5, 139, 216, 0.25)
          }
        }
        .categories-select {
          @include slide_mob_dropdown_skeleton();

          .top-wrp {
            grid-template-rows: auto auto minmax(0, 1fr) auto;

            .search-wrp {
              margin: 24px 16px 0px 16px;

              input {
                margin: 0px;
                padding: 5px 10px 5px 39px;
                width: 100%;
                height: 40px;
                font-size: 14px;
                line-height: 150%;
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_4907_1685" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4907_1685)"><path d="M17.0193 18L11.174 12.1548C10.7087 12.539 10.1736 12.8396 9.56867 13.0568C8.96374 13.2739 8.33794 13.3825 7.69126 13.3825C6.10062 13.3825 4.75442 12.8318 3.65265 11.7303C2.55088 10.6288 2 9.28295 2 7.69273C2 6.10249 2.55074 4.75614 3.65223 3.65368C4.75372 2.55122 6.09958 2 7.68982 2C9.28004 2 10.6264 2.55088 11.7288 3.65265C12.8313 4.75442 13.3825 6.10062 13.3825 7.69126C13.3825 8.35584 13.271 8.99059 13.0479 9.59552C12.8247 10.2004 12.527 10.7266 12.1548 11.174L18 17.0193L17.0193 18ZM7.69126 11.9866C8.89038 11.9866 9.90604 11.5705 10.7383 10.7383C11.5705 9.90604 11.9866 8.89038 11.9866 7.69126C11.9866 6.49215 11.5705 5.47648 10.7383 4.64426C9.90604 3.81204 8.89038 3.39593 7.69126 3.39593C6.49215 3.39593 5.47648 3.81204 4.64426 4.64426C3.81206 5.47648 3.39595 6.49215 3.39595 7.69126C3.39595 8.89038 3.81206 9.90604 4.64426 10.7383C5.47648 11.5705 6.49215 11.9866 7.69126 11.9866Z" fill="%23707B8B"/></g></svg>');
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: 11px 9px;
                border: 1px solid map.get($theme-colors, 'primary');
                border-radius: 8px;
              }
              .selected_destinations {
                display: none;
              }
            }
            .scroll-part {
              margin: 0px 16px;
              padding-top: 16px;

              .card {
                overflow: initial;
                margin-bottom: 16px;
                border: none;
                border-radius: 0px;

                .card-header {
                  padding: 0px;
                  border: none;

                  span {
                    margin: 0px;

                    button {
                      position: relative;
                      padding-right: 40px;
                      min-height: 40px;
                      width: 100%;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 150%;
                      color: #ffffff;
                      text-align: left;
                      background-color: #55C4EA;

                      .tag-bubble {
                        position: absolute;
                        top: -16px;
                        left: 12px;
                        margin-left: 4px;
                        padding: 1px 8px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 150%;
                        border-radius: 8px;
                      }
                      &:hover, &:focus, &:active {
                        text-decoration: none;
                      }
                      &:after {
                        content: "";
                        position: absolute;
                        top: 16px;
                        right: 18px;
                        display: block;
                        width: 7px;
                        height: 7px;
                        border-left: 2px solid #fff;
                        border-bottom: 2px solid #fff;
                        transform: rotate(-45deg);
                        transform-origin: center;
                        transition: all .3s;
                      }
                      &.collapsed {
                        color: #434A54;
                        background-color: #F5F7FA;

                        &:after {
                          border-left: 2px solid #434A54;
                          border-bottom: 2px solid #434A54;
                          transform: rotate(-225deg);
                        }
                        &:hover {
                          background-color: #DDF3FB;
                        }
                      }
                    }
                  }
                }
                .card-body {
                  padding: 13px 2px;

                  &.single {
                    padding: 0px;
                  }
                }
              }
              .single-category {
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                .category-name {
                  position: relative;
                  padding-left: 28px;
                  font-weight: 400;
                  line-height: 14px;
                  line-height: 150%;
                  text-align: left;

                  .clabel {
                    padding-left: 5px;
                    font-weight: 500;
                    font-size: 12px;
                  }
                  .location {
                    display: block;
                    font-size: 12px;
                    line-height: 18px;
                    color: #576175;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    left: 0px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 1px solid map.get($airguru-colors, "gray-border");
                    border-radius: 4px;
                  }
                }
                .count {
                  margin-bottom: 0px;
                  padding-left: 15px;
                  padding-right: 7px;
                  line-height: 14px;
                  line-height: 150%;
                  white-space: nowrap;
                  color: map.get($theme-colors, 'primary');
                }
                &.wt-childs {
                  padding-right: 25px;
                }
                &.all {
                  margin-bottom: 12px;
                  padding-bottom: 12px;
                  border-bottom: 1px solid #EEF2F6;
                }
                &.active > span {
                  &:before {
                    border-color: map.get($airguru-colors, "blue");
                    background-color: map.get($airguru-colors, "blue");
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 8px;
                    height: 9px;
                    width: 5px;
                    border-right: 2px solid #ffffff;
                    border-bottom: 2px solid #ffffff;
                    transform: rotate(45deg);
                  }
                }
              }
              .category-group {
                position: relative;
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;

                .single-category {
                  margin-bottom: 12px;
                }
                &.list-collapse {
                  .list-open-trigger {
                    position: absolute;
                    top: -3px;
                    right: 4px;
                    padding: 10px;
                    border: none;

                    &:before {
                      content: "";
                      display: block;
                      width: 5px;
                      height: 5px;
                      border-left: 1px solid map.get($theme-colors, 'primary');
                      border-bottom: 1px solid map.get($theme-colors, 'primary');
                      transform: rotate(-45deg);
                      transform-origin: center;
                      transition: all .3s;
                    }
                    &:after {
                      display: none;
                    }
                    &:hover {
                      text-decoration: none;
                    }
                  }

                  &.open {
                    .list-open-trigger {
                      &:before {
                        transform: rotate(-225deg);
                      }
                    }
                  }
                }
              }
            }
          }
          &.active_search {
            .top-wrp {
              .results-wrp {
                .single-category {
                  padding-left: 0 !important;

                  .count {
                    display: none;
                  }
                }
                .category-group {
                  > .title {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
        .dropdown-bg {
          &.active {
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .blog-themes-wrp {
      > label {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 150%;
      }
      .themes-list {
        display: flex;
        flex-wrap: wrap;
        margin: -12px 0px 0px -12px;

        a {
          display: inline-block;
          margin: 12px 0px 0px 12px;
          padding: 9px 11px;
          font-size: 14px;
          line-height: 150%;
          color: #434A54;
          text-decoration: none;
          background-color: #ffffff;
          border: 1px solid #CED6E0;
          border-radius: 1000px;
          transition: all .3s;

          &.active {
            color: #ffffff;
            background-color: #55C4EA;
            border-color: #55C4EA;
          }
        }
      }
    }
  }
  .filters-clean-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-items: end;
    padding: 0px 16px 16px 16px;

    a {
      font-size: 14px;
      line-height: 20px;
      color: #FF457E;

      &:after {
        content: "✕";
        margin-left: 8px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: relative;

    .inner-wrp {
      .categories-wrp {
        .categories-select-wrp {
          .categories-select {
            @include popup_mobile_movement();
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin: 32px 0px;

    .inner-wrp {
      .categories-wrp {
        position: relative;
        margin: 0px;
        padding: 0px;
        border-bottom: 0px;

        .categories-select-wrp {
          position: relative;
          min-width: 330px;

          .categories-select {
            @include slide_mob_dropdown_skeleton_lg();
            width: 348px;

            .top-wrp {
              grid-template-rows: auto minmax(0, 1fr) auto;
              max-height: 436px;

              .search-wrp {
                margin: 16px 16px 0px 16px;
              }
              .buttons-wrp {
                //display: none;
              }
            }
          }
        }
        &:after {
          content: "";
          position: absolute;
          top: 0px;
          right: -24px;
          width: 1px;
          height: 100%;
          background-color: #EEF2F6;
        }
      }
      .blog-themes-wrp {
        > label { }
        .themes-list {
          a {}
        }
      }
      &.wt-categories {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        column-gap: 48px;
      }
    }
  }
}
#news_listing {
  margin-bottom: 56px;

  > h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }

  .pagination-wrp {
    @include round_pagination();
  }
  > .btn-wrp {
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;

    > h2 {
      margin-bottom: 30px;
      font-size: 24px;
      text-align: left;
    }
  }
}
.news_list {
  display: grid;
  row-gap: 30px;
  column-gap: 30px;
  margin: 0px;

  &:after {
    display: none;
  }
  .news_element {
    position: relative;
    overflow: hidden;
    float: none;
    margin: 0px;
    padding: 0px;
    height: initial;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border: none;

    a {
      display: flex;
      flex-direction: column;
      height: 100%;

      img {
        border-radius: 5px;
      }
      .placeholder {
        display: block;
        padding: 0px;
        width: 100%;
        padding-top: calc(160 / 330 * 100%);
        border-radius: 5px;
      }
      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

        .name {
          float: none;
          display: block;
          margin-bottom: 20px;
          padding: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #293039;
          transition: all .3s;
        }
        .date {
          position: initial;
          float: none;
          display: block;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .news_element {
      a {
        .placeholder {
          padding-top: calc(160 / 340 * 100%);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 44px;
    column-gap: 44px;

    .news_element {
      text-align: left;

      a {
        .content {
          padding: 29px 20px 22px 20px;

          .name {
            margin-bottom: 14px;
            font-size: 18px;
          }
        }
        &:hover {
          .content {
            .name {
              color: map.get($airguru-colors, "blue");
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

#news_detailed {
  padding-top: 27px;

  .news-header {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E3E8EF;

    .info {
      margin: 0px;

      .category {
        float: none;
        display: inline-block;
        margin: 0px 0px 12px 0px;
        padding: 4px 4px 3px 4px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
      }
    }
    .page_title {
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #293039;
    }
    .author-wrp {
      display: grid;
      grid-template-columns: 64px minmax(0, 1fr);
      align-items: start;
      column-gap: 12px;
      margin-top: 24px;

      .photo-wrp {
        img {
          border-radius: 8px;
        }
      }
      .info-wrp {
        display: grid;
        grid-auto-flow: row;
        row-gap: 4px;

        .name {
          font-size: 14px;
          line-height: 150%;
          font-weight: 500;
        }
        .other-info-wrp {
          font-size: 14px;
          line-height: 150%;
          color: #707B8B;

          .sep {
            display: inline-block;
            margin: 0px 3px;
          }
        }
      }
      &.no-photo {
        grid-template-columns: minmax(0, 1fr);
      }
    }
    > .date {
      display: block;
      margin: 10px 0px 0px 0px;
      font-size: 14px;
      color: #AAB2BD;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 44px;

    .news-header {
      margin-bottom: 32px;
      padding-bottom: 32px;

      .info {
        .category {
          padding: 5px 9px 4px 9px;
          font-size: 14px;
          line-height: 21px;
          border-radius: 8px;
        }
      }
      .page_title {
        font-size: 36px;
        line-height: 45px;
      }
      .author-wrp {
        grid-template-columns: 62px minmax(0, 1fr);
        margin-top: 32px;

        .info-wrp {
          .name {
            font-size: 20px;
          }
          .other-info-wrp {
            font-size: 16px;
          }
        }
        &.no-photo {
          grid-template-columns: minmax(0, 1fr);
        }
      }
      .date {}
    }
  }
}

.random-news-wrp {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 120px;
  border-top: 1px solid #CCD1D9;

  h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }
  .owl-carousel {
    padding: 0px;

    .owl-nav {
      top: calc(((100vw - 60px) * 0.50) / 2);
      transform: translateY(-50%);

      button {
        background-color: #ffffff !important;
        @include owl_carousel_btns();
      }
    }
    .owl-stage-outer {
      overflow: visible;

      .owl-stage {
        display: grid;
        grid-auto-flow: column;
      }
      .owl-item {
        .news_element {
          height: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 80px;

    h3 {
      margin-bottom: 30px;
      font-size: 24px;
      text-align: left;
    }
    .owl-carousel {
      .owl-nav {
        top: calc(((100vw - 60px) * 0.23) / 2);
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .owl-carousel {
      .owl-nav {
        top: calc(((100vw - 60px) * 0.15) / 2);
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .container-fluid {
      overflow: hidden;
      padding-bottom: 10px;
    }
    .owl-carousel {
      .owl-nav {
        top: 80px;
      }
      .owl-stage-outer {}
    }
  }
}

#latestNews {
  padding-top: 68px;
  padding-bottom: 40px;
  background-color: #ffffff;
  overflow: hidden;

  .btn.btn-lg {
    line-height: 30px;
  }
  .inner-wrp {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 42px;

      h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        text-transform: initial;
      }
    }
    .swiper {
      overflow: initial;
      margin-bottom: 40px;

      .news_list {
        row-gap: 0px;
        column-gap: 0px;
        .swiper-slide  {
          height: auto;

          .news_element {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 100px;

    .inner-wrp {
      .header {
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
        }
        .btn.btn-lg {
          min-width: 150px;
        }
      }
    }
  }
}