@use "sass:map";

#products_listing {
  padding-top: 40px;

  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
  #products_column {
    width: auto !important;

    .listing-header {
      text-align: left;

      h1 {
        margin: 0px 0px 16px 0px;
        font-size: 24px;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 65px;

    .container-fluid {
      padding-left: 16px;
      padding-right: 16px;
    }
    #products_column {
      .listing-header {
        h1 {
          margin: 0px 0px 24px 0px;
          font-size: 30px;
          font-weight: 700;
          line-height: 125%;
        }
      }
    }
  }
}
.category-top-desc, .category-bottom-desc {
  padding-top: 20px;
  margin-bottom: 40px;
}

.product_element {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 6px 0 rgba(13, 39, 80, 0.16);
  background-color: map.get($airguru-colors, "white");
  border-radius: 8px;

  > a {
    height: 100%;

    .img-wrapper {
      .top-count-wrapper {
        right: -50px;
        top: -50px;
        width: 100px;
        height: 100px;
        transform: rotateZ(45deg);
        padding-bottom: 7px;
      }

      .title-wrapper {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .date-info-wrapper {
        top: 76px !important;
      }

      .tags-wrapper {
        left: 16px;
        bottom: 16px;

        &.max-width-150 {
          max-width: 150px;
          right: 999px;
        }

        .single-tag {
          background-color: rgba(67, 74, 84, 0.7);

          img {
            width: 12px;
          }
        }
      }

      picture {
        img {
          transition: transform 0.5s ease;
          min-height: 300px;
        }
      }
    }

    .info-wrapper {
      flex-grow: 1;

      .hotel-title {
        .small-tag {
          display: flex;
          align-items: center;
          margin-top: 1px;
          margin-right: 10px;

          img {
            max-height: 16px;
            width: auto;
          }
        }

        svg {
          height: 16px;
        }
      }
      .tripadvisor-ratings {
        button {
          text-decoration: underline;
          background: transparent;
          border: none;
          outline: none;

          &:hover, &:focus {
            text-decoration: none;
          }
        }
      }
      .features-wrapper {
        min-height: 45px;
        text-align: left;

        .single-feature {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      .actions-wrapper {
        .like-act.active {
          svg path {
            fill: #ff457e;
          }
        }
      }
    }
    &:hover {
      text-decoration: none;

      .img-wrapper img {
        transform: scale(1.1);
      }
    }
  }
  .product_simple_element_wrp & {
    .custom-tag {
      top: initial;
      bottom: 4px;
      right: 4px;
      display: flex;
      align-items: end;
      justify-content: end;
      width: 56px;
      height: 56px;
    }
    > a {
      display: flex;
      flex-direction: column;
      padding: 12px;
      color: #434A54;
      background-color: #ffffff;
      border-radius: 8px;

      .img-wrapper {
        margin-bottom: 12px;
        aspect-ratio: 229 / 156;

        picture {
          img {
            min-height: initial;
            border-radius: 8px;
            transform: scale(1) !important;
          }
        }
      }
      .info-wrapper {
        padding-bottom: 12px;
        border-bottom: 1px solid #EEF2F6;

        .location-title-wrp {
          margin-bottom: 2px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #707B8B;
        }
        .hotel-title-wrp {
          .hotel-title {
            margin-top: 0px;
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #434A54;
          }
        }
      }
      .price-wrapper {
        display: flex;
        align-items: end;
        padding: 12px 0px 0px 0px;
        white-space: nowrap;

        .old-price {
          margin-right: 5px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #FF457E;
        }
        .price {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;

          .indicator {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #707B8B;
          }
        }
      }
    }
  }
}

.product_listing {
  padding-top: 0;
  @include media-breakpoint-down(lg) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .product_element_wrapper {
    &:nth-child(3n+1) {
      .img-wrapper {
        .title-wrapper {
          background-color: map.get($airguru-colors, "pink");
        }
      }
    }

    &:nth-child(3n+2) {
      .img-wrapper {
        .title-wrapper {
          background-color: map.get($airguru-colors, "blue");
        }
      }
    }

    &:nth-child(3n+3) {
      .img-wrapper {
        .title-wrapper {
          background-color: map.get($airguru-colors, "orange");
        }
      }
    }
  }
}

.product-detailed {
  padding-bottom: 20px;
  color: #434A54;

  .product-header {
    .product-title {
      h1 {}
      .hotel-stars {
        column-gap: 4px;
        margin-bottom: 4px;

        svg {
          width: 16px;
        }
      }
    }
  }
  .owl-container {
    max-width: $container-fluid-width;
    margin-left: auto;
    margin-right: auto;

    .owl-carousel {
      margin-bottom: 15px !important;
      padding: 0px !important;

      .owl-nav {
        top: calc(((100vw - 60px) * 0.70) / 2);
        transform: translateY(-50%);

        button {
          @include owl_carousel_btns();
          width: 40px;
          height: 40px;
          background-color: rgba(255, 255, 255, 0.20);
          border-radius: 8px;

          span {
            &:before {
              color: #ffffff;
            }
          }
          &.owl-next, &.owl-prev {
            &:hover {
              background-color: rgba(255, 255, 255, 0.60) !important;
            }
          }
          &.owl-prev {
            margin-left: 10px;
          }
          &.owl-next {
            margin-right: 10px;
          }
        }
      }
      .owl-dots {
        position: initial;
        margin-top: 15px;

        .owl-dot {
          span {
            width: 9px;
            height: 9px;
            background: #C7DFF5;
          }
          &.active {
            span {
              background: map.get($airguru-colors, 'orange');
            }
          }
        }
      }
    }
  }
  .photos-carousel, .owl-container {
    a {
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .main-gallery {
    #productGalleryNav {
      top: calc(50%);
      z-index: 5;

      button {
        @include owl_carousel_btns();
        margin: 0px !important;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.20);
        border-radius: 8px;
        transform: translateY(-50%);

        span {
          &:before {
            color: #ffffff;
          }
        }
        &.owl-next, &.owl-prev {
          &:hover {
            background-color: rgba(255, 255, 255, 0.60) !important;
          }
        }
        &.owl-prev {
          left: 10px;
        }
        &.owl-next {
          right: 10px;
        }
      }

      //.owl-prev, .owl-next {
      //  @include owl_carousel_btns();
      //  margin: 0px !important;
      //  z-index: 2;
      //}
      //.owl-prev {
      //  left: 0px;
      //}
      //.owl-next {
      //  right: 0px;
      //}
    }
    .gallery-btn {
      position: absolute;
      right: 17px;
      bottom: 30px;
      padding: 8px 12px 9px 12px;
      min-height: 40px;
      font-size: 16px;
      line-height: 23px;
      text-decoration: none;
      color: map.get($airguru-colors, 'black');
      background-color: #ffffff;
      border-radius: 5px;
    }
    .tags {
      top: initial;
      bottom: 0px;
      transform: translateY(0px);
    }
  }
  .product-summary {
    margin-bottom: 40px;

    .texts-wrp {
      .hotel-summary {
        margin-bottom: 16px;

        .hotel-name {
          display: flex;
          align-items: center;
          margin-top: 0px;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;

          .small-tag {
            display: flex;
            align-items: center;
            margin-top: -1px;
            margin-right: 10px;

            img {
              max-height: 16px;
              width: auto;
            }
          }
        }
        .hotel-stars {
          column-gap: 4px;
        }
      }
      .product-features {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        column-gap: 20px;
        row-gap: 9px;
        align-items: start;
        margin-bottom: 25px;

        .single-feature {
          position: relative;
          padding-left: 28px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            width: 20px;
            height: 20px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7029 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7029 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7164 6.18045C14.9609 5.04987 13.8872 4.16868 12.631 3.64833C11.3747 3.12798 9.99238 2.99183 8.65876 3.2571C7.32514 3.52237 6.10013 4.17716 5.13864 5.13864C4.17716 6.10013 3.52238 7.32513 3.2571 8.65875C2.99183 9.99237 3.12798 11.3747 3.64833 12.6309C4.16868 13.8872 5.04987 14.9609 6.18046 15.7164C7.31105 16.4718 8.64026 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z" fill="%23058BD8"/></svg>');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      .quick-buttons-wrp {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 15px;
        margin-bottom: 32px;

        .btn {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: 20px auto 24px;
          align-items: center;
          column-gap: 4px;
          padding: 11px 17px;
          min-height: 40px;
          text-align: left;
          border: 1px solid map.get($airguru-colors, "gray-border");
          border-radius: 8px;

          .chevron {
            justify-self: end;
            margin-right: 4px;
          }
        }
      }
    }
    .likes-consultant-wrapper {
      margin-bottom: 32px;
      padding: 20px 16px 16px 16px;
      color: map.get($airguru-colors, 'blue');
      background-color: #FFFFFF;
      border-radius: 8px;

      .consultant-wrp {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr) 61px;

        .text-wrp {
          .name {
            margin-bottom: 8px;
            font-size: 20px;
            line-height: 150%;
          }
          .duties {
            font-size: 16px;
            line-height: 23px;
          }
        }
        .photo-wrp {
          position: relative;

          .tiny-logo {
            position: absolute;
            bottom: 0px;
            left: -7px;
            width: 21px;
            height: 21px;
          }
          .consultant-img {
            border-radius: 50%;
          }
        }
        &.separate {
          margin-bottom: 20px;
          padding-bottom: 16px;
          border-bottom: 1px solid #E3E8EF;
        }
      }
      .product-likes-wrp {
        span {
          margin-bottom: 19px;
          font-size: 20px;
          line-height: 150%;
        }
        ul {
          display: grid;
          row-gap: 8px;
          margin-left: 24px;
          font-size: 16px;
          line-height: 150%;

          li {
            &::marker {
              content: "• ";
              font-size: 16px;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .content-description {
      font-size: 16px;
      line-height: 150%;
      color: #535D6C;

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  #offers-listing-filter {
    background-color: #ffffff;
    border-radius: 8px;

    .offers-title {
      margin-bottom: 19px;
      padding: 20px 16px 8px 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
    }
    .offers-filters-wrp {
      padding: 0px 16px 24px 16px;

      #offer-search {
        grid-auto-flow: row;
        row-gap: 16px;
        margin-bottom: 24px;

        label {
          margin-bottom: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
        }
        #passengers-filter-wrp, #departure-filter-wrp2 {
          .top-wrp {
            .header-title {
              font-weight: 600;
              font-size: 20px;
              line-height: 150%;
              color: #000;
            }
          }
        }
        #passengers-filter-wrp {
          .fields-wrp {
            .dropdown {
              #passengers {
                display: grid;
                grid-auto-flow: column;
                grid-template-columns: 24px auto 24px;
                align-items: center;
                column-gap: 12px;
                padding: 12px 15px;
                height: 48px;
                font-size: 14px;
                line-height: 23px;
                border-radius: 8px;
                cursor: pointer;

                &:after {
                  display: none;
                }
                .chevron {
                  justify-self: center;
                }
              }
            }
            .dropdown-menu {
              .top-wrp {
                @include passangers_popup_content();
                .buttons-wrp {
                  padding: 16px 16px 40px 16px;

                  .btn {
                    width: 100%;
                  }
                }
              }
              &.show {
                padding: 0px;
                min-width: 347px;
              }
            }
          }
        }
      }
      .loader-wrp {
        #loader {
          .text-wrp {
            display: grid;
            grid-template-columns: minmax(0, 20px) minmax(0, 1fr);
            column-gap: 12px;
            margin-bottom: 13px;
          }
          .progress {
            height: 6px;

            .progress-bar {
              border-radius: 3px;
            }
          }
        }
      }
    }
    .offers-listing-wrp {
      margin: 0px 16px;
      padding: 24px 0px 16px 0px;
      border-top: 1px solid #EEF2F6;

      .top-wrp {
        display: grid;
        grid-auto-flow: row;
        margin-bottom: 8px;

        .block-title {
          margin-bottom: $headings-margin-bottom;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          a {
            font-weight: 600;
            color: #ffffff;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
        .special-offers-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 5px;
          padding: 12px 20px;
          min-height: 50px;
          font-size: 14px;
          line-height: 23px;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 5px;

          svg {
            margin-right: 6px;
          }
        }
        .pansions-wrp-mob, .pansions-wrp-desk {
          .block-title {
            display: block;
            margin-bottom: 12px;
          }
          .pansions-items-wrp {
            display: flex;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;

            .pansion-item {
              margin: 0px 2px 8px 6px;

              label {
                margin-bottom: 0px;
                padding: 0px;

                .fake-label {
                  display: block;
                  padding: 8px 12px 9px 11px;
                  font-size: 14px;
                  line-height: 21px;
                  color: #434A54;
                  background-color: #ffffff;
                  border: 1px solid #CED6E0;
                  border-radius: 5000px;
                  transition: all .3s;
                }
                input[type="checkbox"]:checked + .fake-label {
                  color: #ffffff;
                  background-color: #55C4EA;
                  border-color: #55C4EA;
                }
              }
            }
          }
        }
        &.not-found {}
      }
      .offers-elements-wrp {
        display: grid;
        grid-auto-flow: row;
        row-gap: 16px;

        #offers-wrp-container {
          display: grid;
          grid-auto-flow: row;
          row-gap: 16px;
        }

        .single-booking-form {
          display: flex;
          align-items: center;
          border: 1px solid #CED6E0;
          border-radius: 8px;
          overflow: hidden;

          .left-side {
            position: relative;
            display: flex;
            align-self: stretch;
            justify-content: space-between;
            flex-direction: column;
            flex: 1 0 0;
            padding: 15px 15px 7px 15px;
            background: map.get($airguru-colors, "white");

            &:after {
              content: "";
              position: absolute;
              bottom: 0px;
              left: 15px;
              right: 15px;
              height: 1px;
              background-color: #EEF2F6;
            }
            .offer-duration {
              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: center;
              margin-bottom: 13px;

              > span {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 150%;
              }
              .offer-duration-duration {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 0px;

                > span {
                  display: none;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                  margin-bottom: 4px;
                }
                .duration-spacer {
                  display: flex;
                  flex-direction: row;
                  padding: 0px 8px;
                  width: 100%;

                  span {
                    display: flex;
                    flex-grow: 1;
                    height: 22px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(50% - 2px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 1px))
                  }
                  svg {
                    margin-left: 8px;
                    margin-right: 6px;
                    flex-grow: 0;
                  }
                }
              }
            }
            .flight-times {
              margin-bottom: 10px;

              .flight-time-wrp {
                grid-template-columns: minmax(0, 154px) minmax(0, 1fr);
                margin-bottom: 10px;
              }
              .btn-logo-wrp {
                display: flex;
                align-items: center;

                .flight-data-btn {
                  cursor: pointer;

                  span[data-toggle="tooltip"] {
                    display: inline-block;
                    margin-left: 5px;
                  }
                  &.with-logo {
                    margin-right: 8px;
                    padding-right: 8px;
                    border-right: 1px solid #EEF2F6;
                  }
                }
                .operator-logo {
                  display: grid;

                  object {
                    height: 20px;
                  }
                }
              }
            }
            .offer-info {
              display: flex;
              justify-content: space-between;
              align-items: start;
              flex-direction: column;
              margin-bottom: 13px;
              padding: 12px;
              width: 100%;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              background-color: #F5F7FA;
              border-radius: 6px;

              .info-left {
                display: flex;
                flex-direction: column;
                align-items: start;
                row-gap: 5px;
                margin-bottom: 15px;

                .info-nights, .info-pansion, .info-room {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 24px;

                  span {
                    margin-left: 8px;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }
              .info-right {
                display: flex;
                flex-direction: row;
                font-size: 12px;
                line-height: 16px;

                .info-operator {
                  margin-right: 8px;
                  color: map.get($theme-colors, 'primary');
                }
                .info-included, .info-terms {
                  text-decoration: underline;
                }
                .info-included {
                  margin-right: 8px;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                }
                .info-terms {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 8px;

                  span {
                    margin-left: 8px;
                  }
                }
              }
            }
            .offer-tags {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;

              .offer-tag {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                margin-right: 8px;
                margin-bottom: 10px;
                padding: 3px 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                color: map.get($airguru-colors, "white");
                border-radius: 6px;

                &.cheapest {
                  background: map.get($airguru-colors, "pink");
                }
                &.top {
                  background: #5ECC2E;
                }
                &.leasing {
                  background: #50AEE4;
                }
                &.expired {
                  color: #9EA7B5;
                  background: #EEF2F6;
                }
                &.last-minute {
                  background: #5ECC2E;
                }
              }
            }
          }
          .right-side {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-self: stretch;
            align-items: flex-start;
            gap: 1px;
            padding: 15px;
            background: map.get($airguru-colors, "white");

            .price-holder {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end;

              .old-price {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                text-decoration: line-through;
                color: #FF457E;
              }
              .price {
                margin: 0px 4px;
                color: #434A54;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;

                small {
                  color: #707B8B;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 21px;
                }
              }
              svg {
                margin-bottom: 6px;
              }
            }
            .total-price-holder {
              font-size: 14px;
              line-height: 21px;
              color: #707B8B;

              .price {
                display: inline-block;
                margin-left: 3px;
                font-weight: 500;
                color: #3F4A5A;
              }
            }
            .leasing-holder {
              display: flex;
              flex-direction: row;
              margin-top: 4px;
              color: #9EA7B5;
              font-weight: 400;
              font-size: 12px;
              line-height: 21px;

              .lease-price {
                margin-left: 8px;
                color: #434A54;
              }
            }
            .actions-wrp {
              display: grid;
              grid-template-columns: minmax(48px, 48px) minmax(0, 1fr);
              column-gap: 8px;
              margin-top: 10px;
              width: 100%;

              .price-alert-btn {
                display: grid;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background-color: #ffffff;
                border: 1px solid #CED6E0;
                border-radius: 8px;
                transition: all .3s;

                &:hover {
                  border-color: #55C4EA;
                }
              }
              .order-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                min-width: auto;
              }
            }
          }
          .valid-offer {
            &.expired-offer {
              .left-side {
                .offer-duration {
                  color: #9EA7B5;

                  .duration-spacer {
                    svg {
                      path {
                        fill: #9EA7B5;
                      }
                    }
                  }
                }
                .flight-times {
                  color: #9EA7B5;
                }
                .offer-info {
                  color: #9EA7B5;

                  svg {
                    path {
                      fill: #9EA7B5;
                    }
                  }
                }
              }
              .right-side {
                .price-holder {
                  .price {
                    color: #9EA7B5;
                  }
                }
                .total-price-holder {
                  color: #9EA7B5;

                  .price {
                    color: #9EA7B5;
                  }
                }
                .leasing-holder {
                  color: #9EA7B5;

                  .lease-price {
                    color: #9EA7B5;
                  }
                  svg {
                    path {
                      fill: #9EA7B5;
                    }
                  }
                }
                .order-btn {
                  background-color: #EEF2F6;
                  border-color: #EEF2F6;
                  color: #9EA7B5;

                  svg {
                    path {
                      fill: #9EA7B5;
                    }
                  }
                }
              }
            }
          }
          .loading-offer {
            position: relative;
            overflow: hidden;
            display: grid;
            grid-template-areas: "loader";
            min-height: 434px;

            .left-side {
              align-items: center;
              justify-content: end;
              min-height: 180px;
              background-color: #EEF2F6;

              .plane-loader {
                margin-bottom: 20px;
              }
            }
            .right-side {
              align-items: center;
              justify-content: flex-start;
              min-height: 123px;
              background-color: #EEF2F6;

              .loader-texts {
                width: 100%;
                text-align: center;

                ul {
                  display: flex;
                  list-style: none;
                  margin: 0px;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 150%;
                  color: map.get($theme-colors, 'primary');
                  animation: reservation-loader 12s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                  transition: all .3s;

                  li {
                    flex-shrink: 0;
                    width: 100%;
                  }
                }
              }
            }

            &.has-advance {
              grid-template-areas: "loader"
                                 "pay_msg";
            }
          }
          .initial-loading-offer {
            .fake-block {
              width: 100%;
              border-radius: 6px;
              animation: shimmer 2.5s linear infinite;
              background: linear-gradient(to right, #EEF2F6 0%, #EEF2F6 40%, rgba(255, 255, 255, 0.2) 50%, #EEF2F6 60%);
              background-size: 200% 100%;

              &.block-1 {
                margin-bottom: 12px;
                height: 98px;
              }
              &.block-2 {
                margin-bottom: 10px;
                height: 119px;
              }
              &.block-3 {
                margin-bottom: 12px;
                height: 82px;
              }
              &.block-4 {
                height: 48px;
              }
            }
          }
        }

        .terms-modal, .change-modal, .included-modal {
          .modal-dialog {
            max-width: 494px;

            .modal-content {
              border: 1px solid #CED6E0;
              border-radius: 8px;

              .modal-header {
                position: relative;
                display: block;
                margin: 0px 24px;
                padding: 24px 0px;

                .modal-title {
                  margin: 0px;
                  padding-right: 45px;
                  font-size: 20px;
                  line-height: 145%;
                }
                .close {
                  position: absolute;
                  top: 38px;
                  right: 18px;
                  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
                  background-repeat: no-repeat;
                  background-position: center;
                  opacity: 1;
                }
              }
              .modal-body {
                padding: 24px;
              }
            }
          }
        }
        .terms-modal {
          ul {
            margin: 0px;

            li {
              padding-left: 28px;
              position: relative;
              list-style: none;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
                width: 20px;
                height: 20px;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7029 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7029 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7164 6.18045C14.9609 5.04987 13.8872 4.16868 12.631 3.64833C11.3747 3.12798 9.99238 2.99183 8.65876 3.2571C7.32514 3.52237 6.10013 4.17716 5.13864 5.13864C4.17716 6.10013 3.52238 7.32513 3.2571 8.65875C2.99183 9.99237 3.12798 11.3747 3.64833 12.6309C4.16868 13.8872 5.04987 14.9609 6.18046 15.7164C7.31105 16.4718 8.64026 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z" fill="%23058BD8"/></svg>');
                background-repeat: no-repeat;
                background-size: cover;
              }
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }
        }
        .change-modal {
          .modal-dialog {
            .modal-content {
              .modal-body {
                .desc {
                  display: block;
                  margin-bottom: 24px;
                  font-size: 16px;
                  line-height: 24px;
                }
                .offer-info {
                  .block-title {
                    display: block;
                    margin-bottom: 8px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                  }
                  ul {
                    list-style: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0px;
                    padding: 0px;
                    font-size: 14px;
                    line-height: 150%;

                    li {
                      position: relative;
                      margin: 0px 10px 10px 0px;

                      &:not(:first-child) {
                        margin: 0px 10px 10px 10px;

                        &:before {
                          content: ".";
                          position: absolute;
                          left: -12px;
                          top: -4px;
                        }
                      }
                    }
                  }
                  .price-wrp {
                    margin-top: -3px;

                    > span {
                      display: block;
                      margin-bottom: 4px;
                      font-size: 12px;
                      line-height: 150%;
                      color: #707B8B;
                    }
                    > div {
                      .price-holder {
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 125%;
                        color: #707B8B;
                      }
                    }
                  }

                  &.old {
                    margin-bottom: 16px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #EEF2F6;
                  }
                  &.new {
                    margin-bottom: 24px;
                    padding: 16px;
                    background-color: #CCEDF9;
                    border-radius: 8px;

                    .price-wrp {
                      > div {
                        .price-holder {
                          color: map.get($airguru-colors, "pink");
                        }
                      }
                    }
                  }
                }
                .btns-wrp {
                  display: grid;
                  grid-template-columns: repeat(2, minmax(0, 1fr));
                  column-gap: 16px;

                  .btn {
                    min-width: auto;
                  }
                }
              }
            }
          }
        }

        #paging-txt {
          margin-bottom: -12px;
          font-size: 14px;
          line-height: 150%;
          color: rgba(255, 255, 255, 0.60);
          text-align: center;
        }
        .more-offers-btn {
          width: 100%;
        }
      }
    }

    &.expired {
      .offers-filters-wrp {
        position: relative;
        min-height: 160px;
        border-radius: 5px;

        .expired-wrp {
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          display: grid;
          align-items: center;
          justify-content: center;
          padding: 30px 38px 38px 38px;
          background: rgba(225, 235, 244, 0.95);
          border-right: 5px;

          .inner-wrp {
            display: grid;
            align-items: center;
            justify-content: center;
            text-align: center;

            span {
              margin-bottom: 15px;
              font-weight: 600;
              font-size: 20px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  .tags {
    z-index: 4;

    .tag-icon {
      display: flex;
      width: 12px;
      @extend .mr-2;
    }
  }
  .content-wrapper {
    margin-bottom: 32px;

    .content-title {
      margin-top: 10px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
    }
    .content-description {
      font-size: 16px;
      line-height: 150%;
      color: #535D6C;

      p {
        margin-bottom: 23px;
      }
      .readmore-wrapper {
        @include media-breakpoint-down(lg) {
          max-height:141px;
          overflow: hidden;
        }
      }
      .readmore-btn, .readless-btn {
        display:flex;
      }
    }

    .content-tabs {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 12px;

      .single-tab {
        .tab-header {
          padding: 12px 20px;
          min-height: 48px;
        }
        .tab-content {
          display: none;
          line-height: 1.71 !important;
        }

        &.active {
          .tab-header {
            @extend .border-blue;

            .expand-btn svg {
              transform: rotateZ(180deg);
            }
          }
        }
      }
    }
    .need-help {
      margin-top: 16px;
      padding: 20px 15px;
      background-color: rgba(5,139,216,.1019607843);
      border: 1px solid map.get($theme-colors, 'primary');
      border-radius: 8px;

      a {
        text-decoration: none;
        color: map.get($theme-colors, 'primary');

        span {
          text-decoration: underline;
        }
      }
    }
  }

  #hotel-description {
    .hotel-description-header {
      margin-top: 10px;
      margin-bottom: 26px;

      .hotel-description-header-top {
        .content-title {
          margin-top: 0px;
          margin-bottom: 5px;
          margin-right: 13px;
        }
        .hotel-stars {
          column-gap: 4px;

          svg {
            width: 16px;
          }
        }
      }
      .hotel-description-header-bottom {
        .google-link-wrp {
          a {
            text-decoration: none;
          }
        }
        .tripadvisor-ratings {
          .tripadvisor-points-wrp {

          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  #economic-offers, #related-offers {
    padding: 16px 16px 36px 16px;
    background-color: #FFECF2;
    color: #434A54;
    border-radius: 12px;
    overflow: hidden;

    .content-title {
      display: grid;
      grid-template-columns: minmax(0, 24px) minmax(0, 1fr);
      align-items: start;
      column-gap: 12px;
      margin-top: 1px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;

      svg {
        justify-self: center;
        margin-top: 6px;
      }
    }
    .desc {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
    }
    .elements-title {
      display: block;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .swiper-economic, .swiper-related {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          height: auto;

          .product_simple_element_wrp {
            height: 100%;
          }
        }
      }
      .swiper-scrollbar {
        bottom: -20px;

        .swiper-scrollbar-drag {
          background: #00000033;
        }
      }
    }
  }
  #related-offers {
    background-color: #ffffff;

    .content-title {

      grid-template-columns: minmax(0, 1fr);
    }
  }
  .description-wrapper {
    .description {
      font-size: 14px;
      line-height: 150%;

      ul {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 8px;
        list-style: none;
        margin-left: 0;
        margin-bottom: 24px;

        li {
          position: relative;
          padding-left: 30px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            width: 20px;
            height: 20px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7029 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7029 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7164 6.18045C14.9609 5.04987 13.8872 4.16868 12.631 3.64833C11.3747 3.12798 9.99238 2.99183 8.65876 3.2571C7.32514 3.52237 6.10013 4.17716 5.13864 5.13864C4.17716 6.10013 3.52238 7.32513 3.2571 8.65875C2.99183 9.99237 3.12798 11.3747 3.64833 12.6309C4.16868 13.8872 5.04987 14.9609 6.18046 15.7164C7.31105 16.4718 8.64026 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z" fill="%23058BD8"/></svg>');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
  .main-travel-terms {
    padding: 35px 32px;
    border: 1px solid map.get($airguru-colors, "gray-border");
  }
  .clouds {
    background: url('/public/images/frontend/clouds.svg') repeat-x left top map.get($airguru-colors, "blue");
    height: 120px;
  }
  .product-fixed-footer {
    z-index: 2;

    .footer-content {
      .price-info {
        .old-price {
          text-decoration: line-through;
        }
      }
    }
  }

  // DOWN
  @include media-breakpoint-down(xs2) {
    #offers-listing-filter {
      .offers-listing-wrp {
        .offers-elements-wrp {
          .terms-modal, .change-modal, .included-modal {
            .modal-dialog {
              position: fixed;
              align-items: end;
              margin: auto;
              width: 100%;
              max-width: 100%;
              height: auto;
              -webkit-transform: translate3d(0%, 0, 0);
              -ms-transform: translate3d(0%, 0, 0);
              -o-transform: translate3d(0%, 0, 0);
              transform: translate3d(0%, 0, 0);

              .modal-content {
                max-height: 100vh;
                border-radius: 24px 24px 0px 0px;

                .modal-header {
                  margin: 0px;
                  padding: 43px 16px 15px 16px;
                  text-align: center;

                  .modal-title {
                    padding-right: 0px;
                  }
                  .close {
                    top: 30px;
                    right: 27px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    top: 15px;
                    left: 50%;
                    width: 60px;
                    height: 3px;
                    background: #EBEBEB;
                    transform: translateX(-50%);
                  }
                }
                .modal-body {
                  padding: 24px 16px;
                  height: auto;
                  overflow-y: auto;
                }
              }
            }
            &.fade {
              .modal-dialog {
                bottom: -100%;
                -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
                transition: opacity 0.3s linear, bottom 0.3s ease-out;
              }
              &.show {
                .modal-dialog {
                  bottom: 0px;
                }
              }
            }
          }
          .change-modal {
            .modal-dialog {
              .modal-content {
                .modal-body {
                  .desc {
                    margin-bottom: 16px;
                  }
                  .btns-wrp {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    row-gap: 12px;

                    .btn-lblue-outline {
                      order: 2;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .photos-carousel {
      margin-bottom: 19px;

      > a {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    #offers-listing-filter {
      .offers-filters-wrp {
        #offer-search {
          #departure-filter-wrp2, #passengers-filter-wrp {
            .fields-wrp {
              #passengers-filter, #departure-filter {
                position: initial;

                .dropdown-menu {
                  position: fixed !important;
                  top: 100% !important;
                  bottom: 0px;
                  display: block;
                  width: 100%;
                  background: none;
                  transform: initial !important;
                  transition: all .3s;

                  .top-wrp {
                    position: absolute;
                    bottom: 0px;
                    display: grid;
                    grid-auto-flow: row;
                    grid-template-rows: auto auto minmax(0, 1fr) auto;
                    padding: 0px !important;
                    max-height: 100%;
                    width: 100%;
                    background: #ffffff;
                    border-radius: 24px 24px 0px 0px;

                    .dropdown-header {
                      margin: 0px;
                      padding: 43px 16px 15px 16px;
                      text-align: center;
                      border-bottom: 1px solid #EEF2F6;

                      .header-title {
                        padding-right: 0px;
                        color: #000000;
                      }
                      .dropdown-close {
                        position: absolute;
                        top: 20px;
                        right: 27px;
                        width: 24px;
                        height: 24px;
                        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_3727_15521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3727_15521)"><path d="M12 13.0538L6.92689 18.1269C6.78844 18.2654 6.6144 18.3362 6.40479 18.3394C6.19519 18.3426 6.01795 18.2718 5.87309 18.1269C5.7282 17.982 5.65576 17.8064 5.65576 17.6C5.65576 17.3936 5.7282 17.218 5.87309 17.0731L10.9462 12L5.87309 6.92689C5.73462 6.78844 5.66379 6.6144 5.66059 6.40479C5.65737 6.19519 5.7282 6.01795 5.87309 5.87309C6.01795 5.7282 6.19359 5.65576 6.39999 5.65576C6.60639 5.65576 6.78202 5.7282 6.92689 5.87309L12 10.9462L17.0731 5.87309C17.2115 5.73462 17.3856 5.66379 17.5952 5.66059C17.8048 5.65737 17.982 5.7282 18.1269 5.87309C18.2718 6.01795 18.3442 6.19359 18.3442 6.39999C18.3442 6.60639 18.2718 6.78202 18.1269 6.92689L13.0538 12L18.1269 17.0731C18.2654 17.2115 18.3362 17.3856 18.3394 17.5952C18.3426 17.8048 18.2718 17.982 18.1269 18.1269C17.982 18.2718 17.8064 18.3442 17.6 18.3442C17.3936 18.3442 17.218 18.2718 17.0731 18.1269L12 13.0538Z" fill="%231D2430"/></g></svg>');
                        background-repeat: no-repeat;
                        background-position: center;
                        border: none;
                        opacity: 1;
                      }
                      &:before {
                        content: "";
                        position: absolute;
                        top: 15px;
                        left: 50%;
                        width: 60px;
                        height: 3px;
                        background: #EBEBEB;
                        transform: translateX(-50%);
                      }
                    }
                  }
                  &.show {
                    top: 0px !important;
                    height: initial !important;

                    & + .dropdown-bg {
                      position: fixed;
                      top: 0px;
                      left: 0px;
                      right: 0px;
                      bottom: 0px;
                      z-index: 301;
                      background: #000000;
                      opacity: 0.3;
                    }
                  }
                }
              }
            }
          }
          #departure-filter-wrp2 {
            .fields-wrp {
              #departure-filter {
                .dropdown-menu {
                  .top-wrp {
                    .departure-picker-wrp {
                      overflow: scroll;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up(xs2) { // 375px and up
    .product-summary {
      .texts-wrp {
        .product-features {
          //grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .quick-buttons-wrp {
          //grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .owl-container {
      .owl-carousel {
        .owl-nav {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .main-gallery {
      .owl-container {
        .owl-carousel {
          .owl-nav {
            top: calc(((100vw - 60px) * 0.49) / 2);
            transform: translateY(-50%);
          }
        }
      }
      .tags {
        //top: calc(((100vw - 60px) * 0.49) - 20px);
      }
    }
    #offers-listing-filter {
      .offers-filters-wrp {
        #offer-search {
          #passengers-filter-wrp {
            .fields-wrp {
              .dropdown-menu {
                .top-wrp {
                  .buttons-wrp {
                    padding: 0px 16px 16px 16px;
                  }
                }
              }
            }
          }
          #departure-filter-wrp2 {
            .fields-wrp {
              #departure-filter {
                .dropdown-menu {
                  &.show {
                    left: 0px !important;
                    right: initial;
                    min-width: 800px;
                    transform: initial !important;

                    .top-wrp {

                      .buttons-wrp {
                        grid-auto-flow: column;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        column-gap: 16px;
                        margin: 0px 24px;
                        padding: 24px 0px;

                        > button {
                          order: 2;
                        }
                      }
                    }
                    &.top {
                      top: initial !important;
                      bottom: calc(100% + 12px) !important;
                    }
                    &.bottom {
                      top: calc(100% + 12px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .offers-listing-wrp {
        .top-wrp {
          .pansions-wrp-mob, .pansions-wrp-desk {
            .pansions-items-wrp {
              .pansion-item {
                label {
                  input[type="checkbox"]:not(:checked) + .fake-label:hover {
                    //background-color: rgba(255, 255, 255, 0.30);
                    border-color: #55C4EA;
                  }
                }
              }
            }
          }
          .pansions-wrp-desk {
            #pansions-items-desk-wrp {
              display: grid;
              grid-template-columns: repeat(1, minmax(0, 1fr));
              align-items: start;
              margin: 0px;

              .pansion-group {
                margin-bottom: 20px;

                .pansion-group-items {
                  display: flex;
                  flex-wrap: wrap;
                  margin-left: -6px;
                  margin-right: -6px;
                }
              }
              &.has-pansion, &.has-nights {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                column-gap: 40px;

                .pansion-group.pansion {
                  position: relative;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: -20px;
                    height: 100%;
                    width: 1px;
                    background-color: #EEF2F6;
                  }
                }
              }
            }
          }
        }
        .offers-elements-wrp {
          .more-offers-btn {
            width: initial;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 100px;

    .main-gallery {
      margin-bottom: 40px;

      .photos-carousel:not(.owl-carousel) {
        display: grid;
        grid-template-areas: "photo_1 photo_2"
                             "photo_1 photo_3";
        grid-template-columns: minmax(0, 8fr) minmax(0, 3.84fr);
        column-gap: 32px;
        row-gap: 16px;

        a {
          img {
            //border-radius: 5px;
          }
          &:nth-child(1) {
            grid-area: photo_1;
          }
          &:nth-child(2) {
            grid-area: photo_2;
          }
          &:nth-child(3) {
            grid-area: photo_3;
          }
          &:nth-child(n+4) {
            display: none;
          }
        }
      }
      .tags {
        top: initial;
        bottom: 0px;
        transform: translateY(0px);
      }
    }
    .product-summary {
      display: grid;
      grid-template-areas: "text side"
                           "description side";
      grid-template-columns: minmax(0, 8fr) minmax(0, 3.84fr);
      column-gap: 32px;
      margin-bottom: 40px;

      .texts-wrp {
        grid-area: text;

        .hotel-summary {
          margin-bottom: 12px;

          .hotel-name {
            margin-right: 0px;
          }
        }
        .product-features {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          margin-bottom: 32px;
        }
        .quick-buttons-wrp {
          grid-template-columns: repeat(4, minmax(0, 1fr));
          column-gap: 16px;
        }
      }
      .likes-consultant-wrapper {
        grid-area: side;
        margin-bottom: 0px;
        padding: 24px;

        .consultant-wrp {
          grid-auto-columns: minmax(0, 1fr) 68px;

          .photo-wrp {
            .tiny-logo {
              bottom: -3px;
              left: 1px;
            }
          }
          &.separate {
            margin-bottom: 24px;
            padding-bottom: 27px;
          }
        }
        .product-likes-wrp {
          span {
            margin-bottom: 14px;
          }
          ul {
            row-gap: 6px;
            margin-left: 22px;
            font-size: 14px;

            li {
              &::marker {
                content: "•  ";
              }
            }
          }
        }
      }
      .content-description {
        grid-area: description;
      }
    }
    #offers-listing-filter {
      .offers-title {
        margin-bottom: 20px;
        padding: 24px 24px 12px 24px;
        font-size: 24px;
        line-height: 30px;
      }
      .offers-filters-wrp {
        padding: 0px 24px 24px 24px;

        #offer-search {
          grid-auto-flow: column;
          column-gap: 20px;
          margin-bottom: 26px;

          #departure-filter-wrp2, #passengers-filter-wrp {
            .fields-wrp {
              > .dropdown {
                .form-control.dropdown-toggle {
                  padding: 7px 10px !important;
                  height: 40px !important;
                  min-width: 220px;

                  svg {
                    width: 20px;
                  }
                }
              }
            }
          }
          #passengers-filter-wrp {}
          #durations-filter-wrp {
            .durations-select-wrp {
              grid-template-columns: repeat(4, minmax(0, 1fr));
            }
          }
        }
        #loader-wrp {
          #loader {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .offers-listing-wrp {
        margin: 0px 24px;
        padding: 24px 0px 40px 0px;

        .top-wrp {
          display: block;

          .special-offers-btn {
            padding: 12px 24px;
          }
          .pansions-wrp {
            display: grid;
            grid-template-columns: auto minmax(0, 1fr);
            column-gap: 20px;
            align-items: start;

            .block-title {
              margin-top: 10px;
              white-space: nowrap;
            }
            .pansions-items-wrp {
              justify-content: end;
              display: flex;
              flex-wrap: wrap;
              column-gap: 0px;
              margin-left: -8px;
              margin-right: -8px;

              .pansion-item {
                margin: 0px 8px 16px 4px;

                label {
                  .fake-label {
                    padding: 9px 12px 8px 11px;
                  }
                }
              }
            }
          }
          &.not-found {
            margin-bottom: 10px;
            padding-top: 30px;

            span {
              margin: 0px;
              max-width: 640px;
              font-weight: 500;
            }
            .btn {
              min-width: 238px;
            }
          }
        }
        .offers-elements-wrp {
          .single-booking-form {
            .left-side {
              display: grid;
              grid-template-columns: minmax(0, 370px) minmax(0, 1fr);
              grid-template-rows: auto auto minmax(0, 1fr) auto;
              grid-template-areas: "dates tags"
                                   "flight_times tags"
                                   "spacer tags"
                                   "info info";
              padding: 23px 25px 23px 23px;

              .offer-duration {
                grid-area: dates;
                align-items: center;

                > span {
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 30px;
                }
                .offer-duration-duration {
                  > span {
                    display: block;
                  }
                  .duration-spacer {
                    padding: 0px 24px;
                  }
                }
              }
              .flight-times {
                grid-area: flight_times;

                .flight-time-wrp {
                  grid-template-columns: minmax(0, 206px) minmax(0, 1fr);
                }
              }
              .spacer {
                grid-area: spacer;
                flex-grow: 1;
              }
              .offer-info {
                grid-area: info;
                display: grid;
                grid-template-columns: minmax(0, 1fr) auto;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 0px;
                
                .info-left {
                  display: grid;
                  grid-auto-flow: column;
                  grid-template-columns: repeat(2, 0fr) minmax(0, 1fr);
                  justify-content: start;
                  margin-bottom: 0px;

                  .info-nights, .info-pansion, .info-room {
                    justify-content: start;
                    margin-right: 18px;

                    svg {
                      flex-shrink: 0;
                    }
                    span {
                      white-space: nowrap;
                    }
                  }
                  .info-room {
                    span {
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
                .info-right {
                  .info-included, .info-terms {
                    margin-left: 8px;
                    margin-right: 0px;

                    span {
                      white-space: nowrap;
                    }
                  }
                }
              }
              .offer-tags {
                grid-area: tags;
                align-content: start;
                justify-content: end;

                .offer-tag {
                  margin-right: 0px;
                  margin-left: 12px;
                }
              }
              &:after {
                top: 26px;
                bottom: 26px;
                left: initial;
                right: 0px;
                height: auto;
                width: 1px;
              }
            }
            .right-side {
              align-items: flex-end;
              justify-content: space-between;
              padding: 23px 23px 23px 24px;

              .price-holder {
                margin-bottom: 3px;

                .old-price {
                  display: inline-block;
                  margin-right: 5px;
                  font-size: 16px;
                  line-height: 29px;
                }
                .price {
                  margin: 0px 4px 0px 8px;
                  font-size: 30px;
                  line-height: 38px;

                  small {
                    margin-left: 3px;
                  }
                }
              }
              .actions-wrp {
                margin-top: 14px;

                .price-alert-btn {}
                .order-btn {
                  min-width: initial;
                  width: 100%;
                }
              }
            }
            .valid-offer {
              display: grid;
              grid-template-columns: minmax(0, 1fr) 292px;
            }
            .loading-offer {
              display: grid;
              grid-template-columns: 1fr;
              min-height: 198px;

              .left-side {
                grid-template-columns: minmax(0, 1fr);
                grid-template-rows: minmax(0, 1fr);
                grid-template-areas: none;
                justify-items: center;
                align-items: end;
                padding: 10px 20px 5px 20px;
                min-height: initial;
                border-radius: 8px 8px 0px 0px;

                .plane-loader {
                  margin-bottom: 8px;
                }
              }
              .right-side {
                padding: 0px 20px 25px 20px;
                min-height: initial;
                border-radius: 0px 0px 8px 8px;

                .loader-texts {
                  ul {
                    font-size: 20px;
                    line-height: 30px;
                  }
                }
              }
            }
            .initial-loading-offer {
              display: grid;
              grid-template-columns: auto minmax(0, 292px);

              .left-side {
                display: block;
              }
              .fake-block {
                &.block-1 {
                  margin-bottom: 48px;
                  height: 62px;
                }
                &.block-2 {
                  margin-bottom: 0px;
                  height: 42px;
                }
                &.block-3 {
                  height: 63px;
                }
                &.block-4 {}
              }
            }
          }
          #paging-txt {
            margin-top: 16px;
          }
          .more-offers-btn {
            min-width: initial;
          }
        }
      }
      &.expired {
        .offers-filters-wrp {
          .expired-wrp {
            .inner-wrp {
              span { margin-bottom: $headings-margin-bottom; }
            }
          }
        }
      }
    }
    .content-wrapper {
      .content-tabs {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 32px;
        row-gap: 20px;

        .single-tab {
          .tab-header {
            padding: 12px 18px 12px 15px;
            min-height: 56px;

            .expand-btn {
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
    #hotel-description {
      .hotel-description-header {
        margin-top: 0px;
        margin-bottom: 17px;

        .hotel-description-header-top {
          margin-bottom: 8px;

          .content-title {
            margin-bottom: 0px;
          }
        }
      }
      //.content-description {
      //  max-width: 728px;
      //}
    }
    #economic-offers {
      padding: 24px;

      .content-title {
        margin-top: -2px;
        margin-bottom: 14px;
      }
    }
  }
}

.booking-wrapper {
  .booking-header {
    .booking-form {
      div:not(.date-span-selector) {
        > label {
          @extend .f-12;
          @extend .fw-500;
          @extend .color-white;
          @extend .text-uppercase;
          padding-top: 30px;
        }
      }

      .form-control {
        @extend .rounded;
        @extend .border-0;
        @extend .bg-white;

        height: 48px;
        padding: 10px 15px;
      }

      .passengers-wrapper {
        &.active {
          .form-control-wrapper {
            svg {
              transform: rotateZ(180deg);
            }
          }
        }

        .passengers-header {
          .form-control-wrapper {
            .expand-btn {
              position: absolute;
              right: 20px;
              top: 50%;
              margin-top: -10px;
            }
          }
        }

        .people-selectors {
          display: none;

          .people-selector {
            display: flex;
            margin-top: 17px;

            > span {
              @extend .flex-grow-1;
              @extend .f-16;
              @extend .color-white;
            }

            .selector {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 110px;

              .minus, .plus {
                width: 24px;
                height: 24px;
                display: flex;
                text-align: center;
                justify-content: center;
                border-radius: 12px;
                background: map.get($airguru-colors, "white");
                color: map.get($airguru-colors, "blue");
                font-size: 26px;
                line-height: 24px;

                &.inactive {
                  background-color: rgba(255, 255, 255, 0.3);
                  color: map.get($airguru-colors, "white");
                }
              }

              .number {
                @extend .flex-grow-1;
                @extend .f-20;
                @extend .fw-bold;
                @extend .color-white;
                @extend .text-center;
              }
            }
          }

          .age-wrapper {
            .age-input {
              margin-top: 15px;
            }
          }
        }
      }

      .departure-wrapper {
        .form-control-wrapper {
          svg {
            position: absolute;
            left: 16px;
            top: 50%;
            margin-top: -8px;
          }

          input {
            padding-left: 46px;
            padding-right: 16px;
          }
        }

        .date-span-selector {
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          @extend .mt-2;

          label {
            @extend .d-flex;
            @extend .align-items-center;
            @extend .f-16;
            @extend .color-white;
          }

          input {
            height: 0;
            width: 0;

            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkmark {
            display: block;
            position: relative;
            height: 24px;
            width: 24px;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            margin-right: 16px;

            &:after {
              content: "";
              position: absolute;
              display: none;
              top: 4px;
              left: 4px;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: map.get($airguru-colors, "white");
            }
          }

          input:checked ~ .checkmark {
            border: solid 2px map.get($airguru-colors, "white");

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .booking-content {
    .booking-page {
      display: none;

      &.active {
        display: block;
      }
    }

    .pager {
      .total-counter {
        right: 16px;
      }

      .pager-btn, .pager-number {
        width: 40px;
        height: 40px;
      }

      .pager-btn {
        @extend .rounded;
        @extend .bg-light-gray;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;

        svg {
          #fill-2 {
            fill: map.get($airguru-colors, 'black') !important;
          }
        }

        &.disabled {
          svg {
            #fill-2 {
              fill: map.get($airguru-colors, 'gray') !important;
            }
          }
        }

        &.pager-prev {
          svg {
            transform: rotateZ(-90deg);
          }
        }

        &.pager-next {
          svg {
            transform: rotateZ(90deg);
          }
        }
      }
    }

    .booking-card {
      border: solid 2px map.get($airguru-colors, "white");

      .inner-title {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 16px;
        color: map.get($airguru-colors, "black");
      }
      .card-top {
        padding-top: 12px;

        .departure-wrp {
          margin-bottom: 16px;
        }
        .duration-wrp {
          .nights-el {
            display: inline-block;
            padding: 8px;
            font-size: 12px;
            line-height: 16px;
            background-color: #656D78;
            border-radius: 4px;

            svg {
              margin-right: 6px;
            }
          }
        }
        .room-wrp {
          margin-bottom: 12px;

          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .pansion-wrp {

        }
      }
      .card-bottom {
        .price-wrp {
          .old-price {
            margin-top: -7px;
            margin-bottom: 3px;
          }
        }
        .offer-order-btn {
          padding: 13px 16px;
          text-transform: uppercase;
        }
      }
      &.cheapest {
        border: solid 2px map.get($airguru-colors, "red");

        .cheapest-title {
          margin-left: -$spacer;
          margin-right: -$spacer;

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 30px;
            font-size: 12px;
            text-transform: uppercase;
          }
        }
        &.border-blue {
          border: solid 2px map.get($airguru-colors, "blue") !important;
        }
      }

      .separator {
        margin: 19px 0px 15px 0px;
        border-bottom: solid 1px map.get($airguru-colors, "light-gray");
      }
    }
    .need-help {
      margin-top: 16px;
      padding: 20px 15px;
      background-color: #058BD81A;
      border: 1px solid map.get($airguru-colors, "blue");
      border-radius: 8px;

      a {
        text-decoration: none;
        color: map.get($airguru-colors, "blue");

        span {
          text-decoration: underline;
        }
        &:hover span {
          text-decoration: none;
        }
      }
    }
  }

  .booking-footer {
    .footer-content {
      line-height: 1.71 !important;
    }
  }

  #cheapest-offer-wrp {
    .booking-content-header {
      > span {
        svg {
          margin-left: 10px;
          margin-top: -5px;
        }
      }
    }
  }
  #cheap-offers-wrp {
    .booking-content-header {
      > span {
        svg {
          margin-left: 10px;
          margin-top: -5px;
        }
      }
    }
    .cheap-offers-desc {
      margin-top: 18px;
      font-size: 14px;
      line-height: 24px;
    }
    #cheap-offers-search {
      .inputs-wrp {
        display: flex;
        flex-direction: row;
        gap: 16px;

        > div {
          width: calc(50% - 8px);
        }
        .bootstrap-select {
          > .dropdown-toggle {
            padding: 0.575rem 0.95rem;
            height: 40px;
            font-size: 16px;
            line-height: 21px;
            background-color: #ffffff;
            border: 1px solid map.get($airguru-colors, "gray-border");
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.product-row {
  .booking-wrapper {
    @extend .box-shadow;
  }
}

.similar-products-wrapper {
  @include media-breakpoint-up(lg) {
    background-color: map.get($airguru-colors, 'lightest-gray');
    padding-top: 65px;
    padding-bottom: 100px;
  }
}

.old-price {
  text-decoration: line-through;
}

#airport-select {
  height:40px;
  width:120px;
  &:focus {
    box-shadow: none !important;
    text-shadow: none !important;
    border-color:#ced4da;
    -webkit-box-shadow: none !important;
  }
}